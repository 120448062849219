export const addCompanyDefaultFormValues = {
	createCompanyStep: {
		companyName: '',
	},
	createBusinessUnitStep: {
		'businessUnit-0': '',
	},
	createPlantStep: {
		plantName: '',
		businessUnit: '',
		street: '',
		city: '',
		state: '',
		stateCode: '',
		zipCode: '',
	},

	metadata: {
		isSaveClickedAtLeastOnce: false,
	},
};
