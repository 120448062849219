/**
 *  Button.jsx
 *  Common Button Component
 */

import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { BUTTON_TYPE } from '../../constants';
import * as S from './style';

function Button({
	buttonType = BUTTON_TYPE.PRIMARY,
	text,
	size,
	color,
	variant,
	onClick,
	disable,
	children,
	...other
}) {
	switch (buttonType) {
		case BUTTON_TYPE.SECONDARY:
			return (
				<S.SecondaryButton
					variant={variant || 'contained'}
					size={size || 'medium'}
					color={color || 'primary'}
					onClick={onClick}
					disabled={disable || false}
					{...other}
				>
					{children || <Typography variant='subtitle1'>{text}</Typography>}
				</S.SecondaryButton>
			);

		case BUTTON_TYPE.SECONDARY_NARROW:
			return (
				<S.SecondaryNarrowButton
					variant={variant || 'contained'}
					size={size || 'small'}
					color={color || 'primary'}
					onClick={onClick}
					disabled={disable || false}
					{...other}
				>
					{children || <Typography variant='subtitle1'>{text}</Typography>}
				</S.SecondaryNarrowButton>
			);
		case BUTTON_TYPE.PRIMARY:
		default:
			return (
				<S.SaveButton
					variant={variant || 'contained'}
					size={size || 'medium'}
					color={color || 'primary'}
					onClick={onClick}
					disabled={disable || false}
					{...other}
				>
					{children || <Typography variant='subtitle1'>{text}</Typography>}
				</S.SaveButton>
			);
	}
}

Button.propTypes = {
	buttonType: PropTypes.string,
	text: PropTypes.string.isRequired,
	size: PropTypes.string,
	color: PropTypes.string,
	variant: PropTypes.string,
	disable: PropTypes.bool,
	onClick: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Button;
