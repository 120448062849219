import { styled } from '@mui/material';
import { Variants } from './constants';

export const ListItemRoot = styled('div')(
	({ theme, width, height, isActive, variant, onClick }) => {
		const isOnClickExist = !!onClick;
		const isVariantNavigation = variant === Variants.NAVIGATION;
		const isVariantAdd = variant === Variants.ADD;
		return {
			display: 'flex',
			minHeight: height || '5rem',
			width: width || '18.75rem',
			padding: '1.09rem 1.56rem',
			border: `1px solid ${theme.palette.border.duneGrey}`,
			fontFamily: 'Open Sans',
			fontSize: isVariantNavigation ? '0.87rem' : 'unset',
			fontWeight: isVariantAdd ? '700' : 'unset',
			backgroundColor: isActive
				? theme.palette.background.blackerRangoonGreen
				: theme.palette.background.elevationGrey,
			borderLeft: isActive
				? `0.56rem solid ${theme.palette.background.saveGreen}`
				: `1px solid ${theme.palette.background.duneGrey}`,
			transition: 'all 0.225s ease-out',
			MozTransition: 'all 0.225s ease-out',
			WebkitTransition: 'all 0.225s ease-out',
			cursor: isOnClickExist ? 'pointer' : 'unset',

			'&:hover': {
				filter: 'brightness(125%)',
				transition: 'all 0.225s ease-out',
				boxShadow: '0 2px 6px rgba(0,0,0,0.16)',
			},
		};
	}
);

export const FlexContainer = styled('div')(({}) => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
}));

export const TextContainer = styled('div')(({ subtitle }) => {
	const isSubtitleExist = !!subtitle;
	return {
		display: 'flex',
		alignItems: isSubtitleExist ? 'unset' : 'center',
		flexDirection: isSubtitleExist ? 'column' : 'unset',
		justifyContent: isSubtitleExist ? 'space-between' : 'unset',
	};
});

export const TitleView = styled('span')(({ subtitle }) => {
	const isSubtitleExist = !!subtitle;
	return {
		color: 'placeHolder',
		marginBottom: isSubtitleExist ? '0.62rem' : 'unset',
	};
});

export const SubtitleView = styled('span')(({ theme }) => ({
	color: theme.customColors.nobelGrey,
	fontWeight: '400',
}));
