import React, { useState } from 'react';
import ErrorIcon from '../../assets/img/smallErrorIcon.svg';
import dropdownArrow from '../../assets/img/dropdownArrow.svg';
import * as S from './style';

/**
 * @author Dima Borodin
 *
 * @param options required - An array of strings/numbers
 * @param currentValue required - The current value to display i.e. watch(fieldName)
 * @param onChange required - the onChange function i.e. setValue('fieldName', value);
 *
 * @returns A controlled single Select component
 */

const Select = ({
	options,
	currentValue,
	onChange,
	label,
	error = '',
	onClose,
	onOpen,
	errorTextWidth,
	isRequired,
	dataTestId,
	...rest
}) => {
	const isErrorState = !!error;
	const [isOpen, setIsOpen] = useState(false);

	const handleChange = (e) => {
		if (e.target?.value) {
			onChange(e.target.value);
		}
	};

	const handleClose = () => {
		setIsOpen(false);
		onClose && onClose();
	};

	const handleOpen = () => {
		setIsOpen(true);
		onOpen && onOpen();
	};

	return (
		<S.ContainerView>
			{label && (
				<S.LabelView aria-label={label} id={`single-select--${dataTestId}`}>
					{label}
					{isRequired && <S.RequiredStar>*</S.RequiredStar>}
				</S.LabelView>
			)}

			<S.SelectView
				aria-labelledby={`single-select--${dataTestId}`}
				data-testid={`single-select--${dataTestId}`}
				variant='standard'
				open={isOpen}
				{...{ error }}
				onClose={handleClose}
				onOpen={handleOpen}
				onChange={handleChange}
				value={currentValue}
				label={label}
				IconComponent={(props) => <img {...props} src={dropdownArrow} />}
				MenuProps={{
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'center',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
					getContentAnchorEl: null,
				}}
				autoWidth
				{...rest}
			>
				{options.map((option) => {
					return (
						<S.StyledMenuItem value={option} key={option}>
							{option}
						</S.StyledMenuItem>
					);
				})}
			</S.SelectView>

			{isErrorState && (
				<S.ErrorTextContainer {...{ errorTextWidth }} aria-label='error-message'>
					<S.InputErrorIcon src={ErrorIcon} />
					<span>{error}</span>
				</S.ErrorTextContainer>
			)}
		</S.ContainerView>
	);
};

export default Select;
