import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';

import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import UploadFiles from './components/UploadFiles';
import LoadingIndicator from '../../../../components/LoadingIndicator';

import { getStatesList, queryConstants } from '../../../../utils/apiHelper';
import { Texts } from '../../constants';
import * as S from './style';

const CreatePlantStep = () => {
	const {
		watch,
		register,
		formState: { errors, dirtyFields },
		setValue,
		trigger,
	} = useFormContext();

	const [stateList, setStateList] = useState([]);
	const [fullStateData, setFullStateData] = useState([]);

	const { isFetching: isGetStatesListFetching, data: states } = useQuery(
		[queryConstants.GET_STATES_LIST],
		async () => {
			const result = await getStatesList();
			return result.states;
		},
		{
			refetchOnWindowFocus: false,
			onSuccess: (res) => {
				const listOfStateNames = res?.map(({ stateName }) => stateName);
				setStateList(listOfStateNames);
				setFullStateData(res);
			},
		}
	);
	const isSaveClickedAtLeastOnce = watch('metadata.isSaveClickedAtLeastOnce');
	const stepErrors = errors?.createPlantStep;
	const stepDirty = dirtyFields?.createPlantStep;

	const createPlantError = stepErrors?.plantName?.message;
	const streetError = stepErrors?.street?.message;
	const cityError = stepErrors?.city?.message;
	const zipCodeError = stepErrors?.zipCode?.message;
	const uploadFileError = stepErrors?.file?.message;
	const businessUnitError = stepErrors?.businessUnit?.message;
	const stateError = stepErrors?.state?.message;

	const createPlantDirty = stepDirty?.plantName;
	const streetDirty = stepDirty?.street;
	const cityDirty = stepDirty?.city;
	const zipCodeDirty = stepDirty?.zipCode;
	const businessUnitDirty = stepDirty?.businessUnit;
	const stateDirty = stepDirty?.state;

	const businessUnitSelectItems = Object.values(watch('createBusinessUnitStep'));

	const handleBusinessUnitChange = (selectedValue) => {
		setValue('createPlantStep.businessUnit', selectedValue, { shouldDirty: true });
		trigger('createPlantStep.businessUnit');
	};

	const handleStateChange = (selectedValue) => {
		setValue('createPlantStep.state', selectedValue, { shouldDirty: true });
		trigger('createPlantStep.state');

		const stateCode = fullStateData.find(
			({ stateName }) => stateName === selectedValue
		)?.stateCode;

		setValue('createPlantStep.stateCode', stateCode);
		trigger('createPlantStep.stateCode');
	};

	return isGetStatesListFetching ? (
		<LoadingIndicator />
	) : (
		<section>
			<S.FlexContainerPlantName>
				<S.InputContainerPlantName>
					<Input
						name='createPlantStep.plantName'
						label={Texts.ENTER_PLANT_NAME}
						placeholder={Texts.ENTER_PLANT_NAME}
						error={isSaveClickedAtLeastOnce || createPlantDirty ? createPlantError : ''}
						{...{ register }}
					/>
				</S.InputContainerPlantName>
				<S.InputContainerPlantName>
					<Select
						label={Texts.ENTER_BUSINESS_UNIT}
						placeholder={Texts.SELECT_BUSINESS_UNIT}
						options={businessUnitSelectItems}
						error={isSaveClickedAtLeastOnce || businessUnitDirty ? businessUnitError : ''}
						currentValue={watch('createPlantStep.businessUnit')}
						onChange={handleBusinessUnitChange}
					/>
				</S.InputContainerPlantName>
			</S.FlexContainerPlantName>
			<S.FlexContainerStreet>
				<S.InputContainerPlantName>
					<Input
						name='createPlantStep.street'
						placeholder={Texts.ENTER_STREET}
						label={Texts.STREET}
						error={isSaveClickedAtLeastOnce || streetDirty ? streetError : ''}
						width='31.875rem'
						{...{ register }}
					/>
				</S.InputContainerPlantName>
			</S.FlexContainerStreet>
			<S.FlexContainerCity>
				<S.InputContainerPlantName>
					<Input
						name='createPlantStep.city'
						placeholder={Texts.ENTER_CITY}
						label={Texts.CITY}
						error={isSaveClickedAtLeastOnce || cityDirty ? cityError : ''}
						{...{ register }}
					/>
				</S.InputContainerPlantName>
				<S.InputContainerPlantName>
					<Select
						label={Texts.STATE}
						placeholder={Texts.SELECT_STATE}
						options={stateList}
						error={isSaveClickedAtLeastOnce || stateDirty ? stateError : ''}
						currentValue={watch('createPlantStep.state')}
						onChange={handleStateChange}
					/>
				</S.InputContainerPlantName>
				<S.InputContainerPlantName>
					<Input
						name='createPlantStep.zipCode'
						placeholder={Texts.ENTER_ZIP_CODE}
						label={Texts.ZIP_CODE}
						error={isSaveClickedAtLeastOnce || zipCodeDirty ? zipCodeError : ''}
						{...{ register }}
					/>
				</S.InputContainerPlantName>
			</S.FlexContainerCity>
			<div>
				<UploadFiles
					name='createPlantStep.file'
					register={register}
					error={uploadFileError}
					watch={watch}
					setValue={setValue}
				/>
			</div>
		</section>
	);
};

export default CreatePlantStep;
