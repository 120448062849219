import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

import errorIcon from '../../assets/img/dangerIcon.svg';
import warningIcon from '../../assets/img/warningIcon.svg';
import successIcon from '../../assets/img/successIcon.svg';
import confirmIcon from '../../assets/img/confirmIcon.svg';

import { COMPONENT_ID } from '../../utils/componentId';

// Different notification icons
const iconTypes = {
	error: <img src={errorIcon} alt='' />,
	warning: <img src={warningIcon} alt='' />,
	success: <img src={successIcon} alt='' />,
	confirm: <img src={confirmIcon} alt='' />,
};

// Custom Stylings

// NOTE : Other has been removed from props

/**
 *
 * This is a wrapper notification modal over
 * default material ui modal
 * @param {*
 * 	open -> checks whether notification model is open or not
 * 	children -> loads the code inside Notification Modal
 * 	onClose -> function to be called on closure of the Modal
 * 	onBackDropClick -> function to be called on clicking of the outside of the modal
 * 	disableEscapeKeyDown -> boolean value that toggles whether modal to be closed on pressing escape key or not
 * } param0
 * @returns the notification modal
 */
function NotificationModal({
	open,
	children,
	paperWidth = '36em',
	onClose = () => {
		/**/
	},
	paperBorderRadius = '0.25em',
	onBackDropClick = () => {
		/**  */
	},
	disableEscapeKeyDown = true,
}) {
	return (
		<S.ViewModal
			open={open}
			disableEscapeKeyDown={disableEscapeKeyDown}
			onClose={onClose}
			onBackDropClick={onBackDropClick}
		>
			<S.GridModal
				container
				data-testid={COMPONENT_ID.MODAL_BODY}
				{...{ paperWidth, paperBorderRadius }}
			>
				{children}
			</S.GridModal>
		</S.ViewModal>
	);
}

NotificationModal.propTypes = {
	children: PropTypes.node,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	backDropClick: PropTypes.bool,
	paperWidth: PropTypes.string,
	paperBorderRadius: PropTypes.string,
	disableEscapeKeyDown: PropTypes.bool,
};

export default NotificationModal;
