import {
	Drawer,
	styled,
	Divider,
	alpha,
	ToggleButtonGroup,
	Tabs,
	Button,
} from '@mui/material';
import { SwitchColors } from '../../theme';

export const InitialModalExploreButton = styled('div')(({ theme }) => ({
	position: 'fixed',
	left: '5rem',
	top: ' 4.45rem',
	zIndex: 2,
	width: '2.75rem',
	height: '3.1rem',
	background: SwitchColors.blackGrey,
	borderRadius: '0 .5rem .5rem 0',
	border: `.125rem solid ${alpha(SwitchColors.blackGrey, 1)}`,
	boxShadow: `0 .25rem .5rem ${theme.customColors.black}`,
}));

export const StyledDrawer = styled(Drawer)({
	'.MuiDrawer-paper': {
		backgroundColor: 'rgb(0,0,0, 0.7)',
		width: 'auto',
		minWidth: '67rem',
		left: '5.3rem',
		top: '4rem',
		zIndex: 1,
	},
	position: 'static',
});

export const StyledToggleButton = styled(ToggleButtonGroup)(({ theme }) => ({
	background: theme.customColors.CBMTabSelectedBlack,
	border: '.0625rem solid #1f2227', //not in theme
	borderRadius: '.25rem 0 0 .25rem',
	fontSize: '.75rem',
	'& .MuiToggleButton-sizeSmall': {
		padding: '0.25rem 1rem',

		color: theme.palette.text.lightWhiteGrey,
	},
	'& .Mui-selected': {
		backgroundColor: `${theme.customColors.CBMTabWhite} !important`,
		color: `${theme.customColors.CBMTabSelectedBlack} !important`,
		fontWeight: 'Bold',
	},
	'& .MuiToggleButton-root': {
		border: `.0625rem solid ${theme.palette.background.wrapperGrey} !important`, //not in theme
	},
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
	'& .MuiTab-root': {
		padding: '.25rem',
		fontSize: '1rem',
		color: ` ${theme.palette.text.primary} !important`,
		width: '12rem',
		height: '2rem',
		border: 'none',
		backgroundColor: 'transparent',
		fontWeight: 'Bold',
		minHeight: 'unset',
	},
	'& .Mui-selected': {
		border: `.0625rem solid  ${theme.palette.text.primary} !important`,
		borderRadius: '.125rem !important',
	},
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
	borderColor: alpha(theme.palette.divider, 0.3),
}));

export const StyledCollapseButton = styled(Button)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontSize: '.875rem',
	textTransform: 'none',
	fontWeight: 400,
	lineHeight: `1.2rem`,
	padding: '0.5rem .687rem',
}));
