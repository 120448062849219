import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton } from '@mui/material';
import CTextField from '../../../../components/TextField';
import WhiteDivider from '../../../../components/WhiteDivider/WhiteDivider';
import MESSAGE_STRINGS from '../../../../constants/en-us';
import PlusIcon from '../../../../assets/img/PlusInCircle.svg';
import { DUPLICATE_ERROR_MASSAGE } from '../../../../constants';
import InlineErrorMessage from '../../../../components/InlineErrorMessage';
import * as S from './AddEditBusinessUnits.style';

// This component will contain the individual business unit components
const CreateBusinessUnits = ({
	idx,
	data,
	isLast,
	addBusinessUnitHandler,
	editBusinessUnitHandler,
	isError,
}) => {
	const [businessUnit, setBusinessUnit] = useState(data.businessUnitName);
	const [businessUnits, setBusinessUnits] = useState(['']);

	// function to handle add new business unit.
	const handleAddBusinessUnit = () => {
		addBusinessUnitHandler();
	};

	// function to handle business unit input update.
	const handleEditBusinessUnits = (e) => {
		setBusinessUnits(e.target.value);
		editBusinessUnitHandler(e.target.value, idx);
	};

	const handleAddOneBusinessUnit = () => {
		setBusinessUnits((prevBusinessUnits) => [...prevBusinessUnits, '']);
	};

	useEffect(() => {
		setBusinessUnit(data.businessUnitName);
	}, [data]);
	return (
		<>
			<S.AddEditBUContainer container key={`businessUnit__${idx}`}>
				<Grid item xs={10}>
					<Box width='15em' p='0.75em 0 0.75em 1.3125em'>
						<CTextField
							maxLen={50}
							error={data?.errorIndex}
							value={data?.businessUnitName || ''}
							onChange={(event) => handleEditBusinessUnits(event)}
							placeholder={
								MESSAGE_STRINGS[
									'InitialCompanySetup.businessUnit.placeholder.businessunit'
								]
							}
						/>
					</Box>

					{data?.errorIndex && <InlineErrorMessage message={DUPLICATE_ERROR_MASSAGE} />}
				</Grid>
				{isLast && (
					<Grid item xs={2}>
						<Box display='flex' justifyContent='flex-end' height='3.5em' marginY='auto'>
							<Box sx={{padding: '0 1rem 0.875rem'}}>
								<WhiteDivider orientation='vertical' light/>
							</Box>
							<IconButton
								size='medium'
								onClick={handleAddBusinessUnit}
								disabled={isError}
							>
								<img src={PlusIcon} alt='PlusIcon' />
							</IconButton>
						</Box>
					</Grid>
				)}
			</S.AddEditBUContainer>
		</>
	);
};

CreateBusinessUnits.propTypes = {
	idx: PropTypes.number, // index of the business unit
	data: PropTypes.object, // each row of business unit
	isLast: PropTypes.bool, // last or not flag
	isError: PropTypes.bool,
	addBusinessUnitHandler: PropTypes.func, // function to handle add business unit
	editBusinessUnitHandler: PropTypes.func, // function to handle update business unit
};

export default CreateBusinessUnits;
