import React from 'react';
import { useFormContext } from 'react-hook-form';

import ListItem from '../../../../../../components/ListItem';
import SearchField from '../../../../../../components/SearchField';
import { ITEM_HEIGHT, ITEM_WIDTH } from './constants';

import * as S from './style';
import MESSAGE_STRINGS from '../../../../../../constants/en-us';

const Sidebar = ({ allPlants }) => {
	const { setValue, watch, reset } = useFormContext();
	const currentlySelectedPlantId = watch('metadata.selectedPlant');
	const isNoPlantSelected = currentlySelectedPlantId === '';

	const handleAddPlant = () => {
		reset();
		setValue('metadata.isUpdateMode', false);
	};

	const handleClickPlant = (plantId) => {
		setValue('metadata.selectedPlant', plantId);
		setValue('metadata.isUpdateMode', true);
	};

	const handleSearchOnChange = (_, searchValue) => {
		const selectedPlantId = allPlants?.find(
			({ plantName }) => plantName === searchValue
		)?.plantId;
		setValue('metadata.selectedPlant', selectedPlantId);
		setValue('metadata.isUpdateMode', true);
	};
	return (
		<S.SideBarContainer {...{ isNoPlantSelected }}>
			<S.SideBarSearchField>
				<SearchField
					value={
						allPlants.find(({ plantId }) => currentlySelectedPlantId === plantId)
							?.plantName
					}
					options={allPlants.map(({ plantName }) => plantName)}
					onChange={handleSearchOnChange}
					placeHolder={MESSAGE_STRINGS['AddEditPlantModal.autoComplete.placeholder']}
				/>
			</S.SideBarSearchField>

			<ListItem
				variant='add'
				onClick={handleAddPlant}
				title='Create New Plant'
				isActive={isNoPlantSelected}
				height={ITEM_HEIGHT}
				width={ITEM_WIDTH}
			/>

			<S.SideBarScrollable>
				{allPlants?.map(({ plantName, plantId }) => {
					return (
						<ListItem
							key={plantId}
							variant='navigation'
							title={plantName}
							height={ITEM_HEIGHT}
							isActive={plantId === currentlySelectedPlantId}
							width={ITEM_WIDTH}
							onClick={() => handleClickPlant(plantId)}
						/>
					);
				})}
			</S.SideBarScrollable>
		</S.SideBarContainer>
	);
};

export default Sidebar;
