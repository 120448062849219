import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { theme } from '@smf/ui-util-app';
import App from './containers/App';
import ReactQueryProvider from './context/react-query.provider';
import { AppProvider } from './context/appContext';
import { ToastProvider } from './context/toastContext';

function GlobalApp() {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<ReactQueryProvider>
					<AppProvider>
						<ToastProvider>
							<App />
						</ToastProvider>
					</AppProvider>
				</ReactQueryProvider>
			</ThemeProvider>
		</StyledEngineProvider>

	);
}

export default GlobalApp;
