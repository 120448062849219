import { Dialog, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material';

export const DialogRootView = styled(Dialog, {
	shouldForwardProp: (prop) => prop !== 'propsClasses',
})(({ propsClasses }) => ({
	...(propsClasses && {
		maxWidth: 'unset',
	}),
	'& .MuiPaper-root-MuiDialog-paper': {
		maxWidth: 'unset',
	},
}));

export const ComponentContainer = styled('div', {
	shouldForwardProp: (prop) =>
		prop !== 'propsClasses',
})(({ theme, propsClasses }) => ({
	...(propsClasses && {
		padding: '1.5rem 1.8125rem',
		fontFamily: 'Open Sans',
		borderRadius: '8px',
		backgroundColor: theme.palette.background.elevationGrey,
	}),
	padding: '1.5rem 1.8125rem',
	fontFamily: 'Open Sans',
	borderRadius: '0.5rem',
	backgroundColor: theme.palette.background.elevationGrey,
}));

export const HeaderContent = styled('div', {
	shouldForwardProp: (prop) => prop !== 'propsClasses',
})(({ propsClasses }) => ({
	...(propsClasses && {
		paddingBottom: '1.0625rem',
	}),
	paddingBottom: '1.0625rem',
}));

export const DialogTitleView = styled(DialogTitle, {
	shouldForwardProp: (prop) => prop !== 'propsClasses',
})(({ theme, propsClasses }) => ({
	...(propsClasses && {
		padding: 0,
		paddingBottom: '1rem',
		fontSize: '1.125rem',
		color: theme.palette.text.primary,
	}),
	padding: 0,
	paddingBottom: '1rem',
	fontSize: '1.125rem',
	color: theme.palette.text.primary,
}));

export const DialogContentView = styled(DialogContent, {
	shouldForwardProp: (prop) => prop !== 'propsClasses',
})(({ theme, propsClasses }) => ({
	...(propsClasses && {
		background: theme.palette.background.blackerRangoonGreen,
		padding: '1.25rem 1.5rem',
	}),
	background: theme.palette.background.blackerRangoonGreen,
	padding: '0',
	width: '69.125rem',
    height: '36.875rem',
    overflow: 'hidden'
}));

export const DialogActionView = styled(DialogActions, {
	shouldForwardProp: (prop) => prop !== 'propsClasses',
})(({ propsClasses }) => ({
	...(propsClasses && {
		padding: 0,
		paddingTop: '1.125rem',

		'&>$button': {
			marginRight: 'auto',
		},
	}),
	padding: 0,
	paddingTop: '1.125rem',

	'&>$button': {
		marginRight: 'auto',
	},
}));
