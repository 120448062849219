import * as yup from 'yup';
import {
	MAXIMUM_FIELD_LENGTH,
	MINIMUM_FIELD_LENGTH,
	ZIP_CODE_FIELD_EXACT_LENGTH,
	Errors,
	MAXIMUM_FILE_SIZE,
} from '../../../constants';
import { isStringNumericOnly } from '../../../utils';
import { ACCEPTED_UPLOAD_FILE_TYPES } from '../../CreatePlantStep/components/UploadFiles/constants';

const testFileSize = (fileList) => {
	const file = fileList?.[0];
	const isFileExist = fileList?.length > 0;

	if (!isFileExist) {
		// Allow a form without uploading a file
		return true;
	}

	if (file) {
		return file.size <= MAXIMUM_FILE_SIZE;
	}

	return false;
};

const testFileType = (formValues) => (fileList) => {
	const file = fileList?.[0];
	const isFileExist = fileList?.length > 0;
	const isUpdateMode = formValues.metadata.isUpdateMode;
	const isUserClickOnRemoveOnce = formValues.metadata.plantImage.isUserClickOnRemoveOnce;

	if (!isFileExist) {
		// Allow a form without uploading a file
		return true;
	}

	if (file) {
		// From base64 string
		let [, fileType] = file.type.split('/');

		// From synthetic file
		if (isUpdateMode && !isUserClickOnRemoveOnce) {
			fileType = file.type;
		}

		return ACCEPTED_UPLOAD_FILE_TYPES.includes(fileType);
	}
	return false;
};

const textFieldValidationSchema = yup
	.string()
	.required(Errors.REQUIRED)
	.min(MINIMUM_FIELD_LENGTH, Errors.MIN_LENGTH)
	.max(MAXIMUM_FIELD_LENGTH, Errors.MAX_LENGTH);

const zipCodeValidationSchema = yup
	.string()
	.required(Errors.REQUIRED)
	.min(ZIP_CODE_FIELD_EXACT_LENGTH, Errors.ZIP_CODE)
	.max(ZIP_CODE_FIELD_EXACT_LENGTH, Errors.ZIP_CODE)
	.matches(isStringNumericOnly, Errors.ZIP_CODE);

const stateCodeValidation = yup
	.string()
	.required(Errors.REQUIRED)
	.min(MINIMUM_FIELD_LENGTH, Errors.MIN_LENGTH);

export const buildEditPlantsDialogValidationSchema = yup.lazy((formValues) =>
	yup
		.object({
			plant: yup.object({
				plantName: textFieldValidationSchema,
				businessUnitName: textFieldValidationSchema,
				street: textFieldValidationSchema,
				city: textFieldValidationSchema,
				stateCode: stateCodeValidation,
				zipCode: zipCodeValidationSchema,
				file: yup
					.mixed()
					.test('check-for-file-type', Errors.FILE_TYPE, testFileType(formValues))
					.test('check-for-file-size', Errors.FILE_SIZE, testFileSize),
			}),
		})
		.required()
);
