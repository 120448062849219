import { styled } from '@mui/material';

export const EditPlantContainer = styled('section')(({}) => ({
	padding: '1.44rem 1.31rem 2rem 1.31rem',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
}));

export const EditPlantTitle = styled('h2')(({}) => ({
	marginBottom: '1.56rem',
	fontSize: '1.125rem',
	fontWeight: 'bold',
}));

export const FlexContainerPlantName = styled('div')(({}) => ({
	display: 'flex',
	paddingBottom: '2rem',
}));

export const EditPlantInputContainer = styled('span')(({}) => ({
	marginRight: '6.875rem',
}));

export const FlexContainerStreet = styled('div')(({}) => ({
	display: 'flex',
	paddingBottom: '1.3125rem',
}));

export const FlexContainerCity = styled('div')(({ theme }) => ({
	display: 'flex',
	paddingBottom: '3.25rem',
}));
