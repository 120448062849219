import React from 'react';

import Sidebar from '../Sidebar';
import EditPlantForm from '../EditPlantForm';
import * as S from './style';

const EditPlantsContainer = ({
	allBusinessUnits,
	statesList,
	allPlants,
	isCreatePlantMutationLoading,
	isUpdatePlantMutationLoading,
	onClose,
}) => {
	return (
		<S.EditContainer>
			<Sidebar allPlants={allPlants} sx={{ overflowY: 'scroll' }} />
			<EditPlantForm
				allBusinessUnits={allBusinessUnits}
				statesList={statesList}
				isCreatePlantMutationLoading={isCreatePlantMutationLoading}
				isUpdatePlantMutationLoading={isUpdatePlantMutationLoading}
				onClose={onClose}
			/>
		</S.EditContainer>
	);
};

export default EditPlantsContainer;
