import { Button as MuiButton, styled } from '@mui/material';

export const SaveButton = styled(MuiButton)(({ theme }) => ({
	margin: theme.spacing(0.5),
	color: theme.palette.text.secondary,
	border: `0.0625rem solid ${theme.palette.background.saveGreen}`,
	boxSizing: 'border-box',
	borderRadius: '1.25rem',
	minWidth: '7.5rem',
	background: theme.palette.background.saveGreen,
	'&:hover': { color: theme.palette.text.primary, background: 'inherit' },
	'&:disabled': {
		color: theme.palette.text.lightYellowishGray,
		background: theme.palette.background.darkGrey,
		border: `0.0625rem solid ${theme.customColors.lightRaven}`,
	},
	textTransform: 'none',
}));

export const SecondaryButton = styled(MuiButton)(({ theme }) => ({
	margin: theme.spacing(0.5),
	color: theme.palette.text.primary,
	border: `0.0625em solid ${theme.palette.text.primary}`,
	boxSizing: 'border-box',
	borderRadius: '1.25rem',
	minWidth: '7.5rem',
	background: 'inherit',
	'&:hover': {
		color: theme.palette.text.secondary,
		background: theme.palette.text.primary,
	},
	'&:disabled': {
		color: theme.palette.text.lightYellowishGray,
		background: theme.palette.background.darkGrey,
		border: `0.0625rem solid ${theme.palette.border.lightRaven}`,
	},
	textTransform: 'none',
}));

export const SecondaryNarrowButton = styled(MuiButton)(({ theme }) => ({
	margin: theme.spacing(0.5),
	color: theme.palette.text.primary,
	border: `0.0625em solid ${theme.palette.text.primary}`,
	boxSizing: 'border-box',
	borderRadius: '1.25rem',
	// minWidth: '7.5rem',
	background: 'inherit',
		width: '90px !important',
		height: '24px !important',
	'&:hover': {
		color: theme.palette.text.secondary,
		background: theme.palette.text.primary,
	},
	'&:disabled': {
		color: theme.palette.text.lightYellowishGray,
		background: theme.palette.background.darkGrey,
		border: `0.0625rem solid ${theme.palette.border.lightRaven}`,
	},
	textTransform: 'none',
}));
