import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import NotificationModal from '../../../../components/NotificationModal';
import CloseIcon from '../../../../assets/img/closeIcon.svg';
import MESSAGE_STRINGS from '../../../../constants/en-us';
import CButton from '../../../../components/Button';
import { BUTTON_TYPE, TOAST_REDUCER_CONSTANTS } from '../../../../constants';
import AddEditBusinessUnits from '../AddEditBusinessUnits/AddEditBusinessUnits';
import {
	addUpdateBusinessUnit,
	getAllBusinessUnitList,
} from '../../../../utils/apiHelper';
import { useMutation, useQuery } from 'react-query';
import { useToastContext } from '../../../../context/toastContext';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { useRxjsState } from '../../../../utils/hooks/useRxjsState';
import * as S from './BusinessUnitList.style';

// This component is loaded is loaded on click of Add/Edit Business Unit
const BusinessUnitsList = ({ open = false, handleClose, refetchAllBusinessUnits }) => {
	const [businessData, setBusinessData] = useState([]);
	const [isError, setIsError] = useState(false);
	const { toastDispatch } = useToastContext();
	const { rxjsState } = useRxjsState();

	const { isFetching } = useQuery(
		['allBUList'],
		async () => {
			const allBUListResponse = await getAllBusinessUnitList();
			setBusinessData(allBUListResponse);
		},
		{
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	// function to add new business unit.
	const addBusinessUnitHandler = () => {
		setBusinessData((previousData) => [...previousData, { businessUnitName: '' }]);
	};

	// function to edit business units.
	const editBusinessUnitHandler = (editedData, idx) => {
		let newBusinessUnit = [...businessData];
		if (newBusinessUnit.find((item) => item.businessUnitName === editedData)) {
			// ? set a state to display error
			setIsError(true);
			newBusinessUnit[idx].errorIndex = true;
		} else {
			setIsError(false);
			newBusinessUnit[idx].errorIndex = false;
		}
		newBusinessUnit[idx].businessUnitName = editedData;
		newBusinessUnit[idx].businessUnit = editedData;
		setBusinessData(newBusinessUnit);
	};

	const {
		isLoading: addUpdateBusinessUnitLoading,
		isError: addUpdateBusinessUnitError,
		mutate: addUpdateBusinessUnitMutate,
	} = useMutation(
		(buData) => {
			const formattedBusinessUnit = buData.map((data) => {
				return {
					businessUnitName: data.businessUnit,
					...(data.businessUnitId && { businessUnitId: data.businessUnitId }),
				};
			});
			return addUpdateBusinessUnit({
				companyId: rxjsState.companyData?.companyId,
				businessUnits: [...formattedBusinessUnit],
			});
		},
		{
			onSuccess: (response) => {
				if (response) {
					setBusinessData(
						businessData.map((bu) => {
							if (bu.businessUnit) {
								let name = bu.businessUnit;
								delete bu.businessUnit;
								return { ...bu, businessUnitName: name };
							}
							return bu;
						})
					);
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
						payload: {
							message: MESSAGE_STRINGS['Toast.message.SUCCESS'],
						},
					});

					refetchAllBusinessUnits();
				}
			},
			onError: (error) => {
				if (error.response && error.response.data && error.response.data.message) {
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
						payload: {
							message: MESSAGE_STRINGS['Toast.message.ERROR'],
						},
					});
				}
			},
			refetchOnWindowFocus: false,
			retry: false,
		}
	);

	// function to handle save.
	const saveHandler = async () => {
		let addNewBusinessUnit = businessData.filter((bu) => bu.businessUnit);
		if (addNewBusinessUnit.length === 0) {
			toastDispatch({
				type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				payload: {
					message: MESSAGE_STRINGS['NotificationConfiguration.requiredFields'],
				},
			});
		} else {
			addUpdateBusinessUnitMutate(addNewBusinessUnit);
		}
	};

	// function to clean unsaved changes & close the drawer.
	const closeHandler = () => {
		handleClose();
	};

	return (
		<NotificationModal open={open} paperWidth='74rem'>
			<Box
				paddingX='0.75rem'
				paddingY='1rem'
				display='flex'
				flex={1}
				flexDirection='column'
			>
				<Box
					position='relative'
					width={0}
					height={0}
					left='calc(100% - 0.75rem - 32px)'
					top='calc(0% - 1rem)'
				>
					<IconButton onClick={closeHandler} size='large'>
						<img src={CloseIcon} alt='' />
					</IconButton>
				</Box>
				<Box marginBottom='2rem'>
					<S.BusinessUnitHeading>
						{MESSAGE_STRINGS['AddEditBU.createBUForm.formHeader.addEdit']}
					</S.BusinessUnitHeading>
				</Box>

				<Box
					bgcolor='background.blackerRangoonGreen'
					p='1rem'
					height='30rem'
					overflow='auto'
				>
					{isFetching || addUpdateBusinessUnitLoading ? (
						<LoadingIndicator />
					) : (
						<>
							{businessData.map((businessUnit, idx) => (
								<AddEditBusinessUnits
									idx={idx}
									data={businessUnit}
									isLast={idx === businessData.length - 1}
									addBusinessUnitHandler={addBusinessUnitHandler}
									editBusinessUnitHandler={editBusinessUnitHandler}
									isError={isError}
									key={idx}
								/>
							))}
						</>
					)}
				</Box>

				<Box
					display='flex'
					flex={1}
					justifyContent='flex-end'
					marginTop='2rem'
					alignItems='flex-end'
				>
					<CButton
						text={MESSAGE_STRINGS['CommonWords.Actions.Cancel']}
						type={BUTTON_TYPE.SECONDARY}
						onClick={closeHandler}
					/>

					<CButton
						text={MESSAGE_STRINGS['CommonWords.Actions.Save']}
						type={BUTTON_TYPE.PRIMARY}
						onClick={saveHandler}
						disable={isError}
					/>
				</Box>
			</Box>
		</NotificationModal>
	);
};

BusinessUnitsList.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
};

export default BusinessUnitsList;
