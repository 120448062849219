import React from 'react';

import Input from '../../../../components/Input';

import { useFormContext } from 'react-hook-form';
import { Texts } from '../../constants';

const CreateCompanyStep = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext();

	const stepErrors = errors?.createCompanyStep;

	return (
		<section>
			<Input
				name='createCompanyStep.companyName'
				register={register}
				error={stepErrors?.companyName?.message}
				label={Texts.ENTER_COMPANY_NAME}
				isRequired
			/>
		</section>
	);
};

export default CreateCompanyStep;
