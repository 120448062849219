import { Autocomplete, MenuItem, styled, TextField } from '@mui/material';

export const CharcoalTextField = styled(TextField)(({ theme }) => ({
	backgroundColor: theme.palette.background.darkCharcolGrey,
	borderRadius: 4,
	padding: '.5rem 0.875rem',
	'& .startAdornment': {
		marginRight: '1.125rem',
	},
	'& input::placeholder': {
		fontSize: '0.87rem',
	},
}));

export const StyledMenuItem = styled('li')(({ theme }) => ({
	'&.Mui-focused': {
		backgroundColor: theme.palette.selected.darkGrayishBlue,
	},
	'& .optionString': {
		width: '100%',
		borderBottom: `1px solid ${theme.palette.border.balticSea}`,
		padding: '0.5rem 0.875rem',
		'&:hover': {
			backgroundColor: theme.palette.selected.darkGrayishBlue,
		}
	},
	color: 'grey',
	'& .highlightString': {
		color: 'white',
	},
}));

export const AutoCompleteView = styled(Autocomplete)(({ inputValue }) => ({
	paddingBottom: 0,
	position: 'relative',
	'& .MuiAutocomplete-clearIndicator': {
		visibility: inputValue.length > 0 ? 'visible' : 'hidden',
		display: inputValue.length > 0 ? 'inline-flex' : 'none',
	},
	'& .MuiAutocomplete-endAdornment': {
		position: 'relative'
	},
	'&.MuiAutocomplete-hasClearIcon': {
		'& .MuiAutocomplete-inputRoot': {
			paddingRight: 0,
		},
	},
	inputRoot: {},
}));
