import { React, useState, useEffect, memo } from 'react';
import { Slide, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { TOAST_TYPE } from '../../constants';
import ErrorIcon from '../../assets/img/dangerIconV2.svg';
import WarningIcon from '../../assets/img/warningIconV2.svg';
import SuccessIcon from '../../assets/img/successIconV2.svg';
import InfoIcon from '../../assets/img/infoIconV2.svg';
import MESSAGE_STRINGS from '../../constants/en-us';
import { CustomSnackbar } from './style';

const iconMap = {
	[TOAST_TYPE.ERROR]: ErrorIcon,
	[TOAST_TYPE.WARNING]: WarningIcon,
	[TOAST_TYPE.SUCCESS]: SuccessIcon,
	[TOAST_TYPE.INFO]: InfoIcon,
};

let timeout;

function ShowSnackBar({ toastType = TOAST_TYPE.ERROR, message = '' }) {
	const Icon = iconMap[toastType];

	return (
		<Box display='flex'>
			<Box
				display='flex'
				justify='center'
				alignItems='center'
				width='100%'
				marginY='1rem'
			>
				<Box marginLeft='1.75rem' marginRight='1.25rem'>
					<img src={Icon} alt='Icon' />
				</Box>
				<Box marginRight='1.25rem'>
					<Typography variant='subtitle1'>
						{message || MESSAGE_STRINGS[`Toast.message.${toastType}`]}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}

function Toast({ type, message }) {
	const [open, setOpen] = useState(false);
	const [toastType, setToastType] = useState('');

	useEffect(() => {
		if (timeout) clearTimeout(timeout);
		timeout = setTimeout(() => {
			setOpen(false);
		}, 4000);
		setOpen(true);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const SlideTransitionComponent = memo((props) => (
		<Slide {...props} direction="right" />
	  ));
	
	  SlideTransitionComponent.displayName = 'SlideTransition';

	return (
		<CustomSnackbar
			open={open}
			TransitionComponent={SlideTransitionComponent}
			message={<ShowSnackBar toastType={type} message={message} />}
			key={type}
			resultColor={type}
			anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
		/>
	);
}

ShowSnackBar.propTypes = {
	toastType: PropTypes.string,
	message: PropTypes.string,
};
Toast.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string,
};
export default Toast;