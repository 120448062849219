import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import * as S from './style';

export default function LoadingIndicator({ size }) {
	return (
		<>
			<S.BackDrop open>
				<S.DangerText>
					<CircularProgress color='inherit' size={size} />
				</S.DangerText>
			</S.BackDrop>
		</>
	);
}

LoadingIndicator.propTypes = {
	size: PropTypes.number,
};
