import { styled } from '@mui/material';

export const SuccessDialogContainer = styled('div')(({ theme }) => ({
	display: 'flex',
}));

export const SuccessDialogIcon = styled('img')(({ theme }) => ({
	height: '2rem',
	width: '2rem',
	marginRight: '1.5rem',
}));

export const SuccessDialogTitle = styled('div')(({ theme }) => ({
	fontSize: '1.361rem',
	color: theme.palette.text.primary,
	fontWeight: 'bold',
}));

export const SuccessDialogDescription = styled('div')(({ theme }) => ({
	fontSize: '0.875rem',
	color: theme.palette.text.primary,
	fontWeight: '400',
	marginTop: '1rem',
}));
