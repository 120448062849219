import React from 'react';

import IconButton from '../IconButton';
import { IconNames } from '../IconButton/IconNames';

import { DEFAULT_ICON_SIZE, Variants } from './constants';
import * as S from './style';

const ListItem = ({
	variant = 'add',
	isActive,
	onClick,
	title,
	subtitle,
	width,
	height,
	...rest
}) => {
	//const isOnClickExist: !!onClick;
	//const isVariantNavigation = variant === Variants.NAVIGATION;
	const isVariantAdd = variant === Variants.ADD;

	return (
		<S.ListItemRoot
			aria-readonly={false}
			role='list-item'
			onClick={onClick}
			width={width}
			height={height}
			{...{ isActive, variant, onClick }}
			{...rest}
		>
			<S.FlexContainer>
				<S.TextContainer {...{ subtitle }}>
					<S.TitleView {...{ subtitle }}>{title}</S.TitleView>
					{subtitle && <S.SubtitleView>{subtitle}</S.SubtitleView>}
				</S.TextContainer>

				{isVariantAdd && (
					<IconButton
						onClick={onClick}
						name={IconNames.PLUS}
						width={DEFAULT_ICON_SIZE}
						height={DEFAULT_ICON_SIZE}
						size='large'
					/>
				)}
			</S.FlexContainer>
		</S.ListItemRoot>
	);
};

export default ListItem;
