export const createRowsAndColumns = (type, old = false) => {
	if (type) {
		return {
			columns: [
				{
					id: {
						key: 'businessUnit',
						embellishment: null,
						width: '14.8125rem',
						correspondingChild: 'entityName',
					},
					label: { name: 'Business Unit', isSortReq: true },
				},
				{
					id: {
						key: 'plannedOEE',
						embellishment: 'colorBar',
						width: '7.875rem',
						correspondingChild: 'actualOEE',
					},
					label: { name: 'OEE', isSortReq: true, embellishment: 'bold' },
				},
				{
					id: {
						key: 'plannedAvailability',
						embellishment: null,
						width: '8.5rem',
						correspondingChild: 'actualAvailability',
					},
					label: { name: 'Availability', isSortReq: true },
				},
				{
					id: {
						key: 'performance',
						embellishment: null,
						width: '8.5rem',
						correspondingChild: 'actualPerformance',
					},
					label: { name: 'Performance', isSortReq: true },
				},
				{
					id: {
						key: 'quality',
						embellishment: null,
						width: '8.5rem',
						correspondingChild: 'actualQuality',
					},
					label: { name: 'Quality', isSortReq: true },
				},
				{
					id: { key: 'numberOfPlants', embellishment: 'plainText', width: '9.1rem' },
					label: { name: 'Number Of Plants', isSortReq: false },
				},
				{
					id: {
						key: '',
						embellishment: 'blank',
						width: '9rem',
						correspondingChild: 'entityName',
					},
					label: { name: '', isSortReq: false },
				},
			],

			childrenCols: [
				{
					id: {
						key: 'entityName',
						embellishment: old ? 'hyper' : 'bold',
						width: '14.8125rem',
					},
					label: { name: 'Plant' },
				},
				{
					id: { key: 'actualOEE', embellishment: 'colorBar', width: '7.875rem' },
					label: { name: 'OEE', embellishment: 'bold' },
				},
				{
					id: { key: 'actualAvailability', embellishment: null, width: '8.5rem' },
					label: { name: 'Availability' },
				},
				{
					id: { key: 'actualPerformance', embellishment: null, width: '8.5rem' },
					label: { name: 'Performance' },
				},
				{
					id: { key: 'actualQuality', embellishment: null, width: '8.7rem' },
					label: { name: 'Quality' },
				},
				{
					id: { key: '', embellishment: 'blank', width: '9.1rem' },
					label: { name: '' },
				},
				{
					id: { key: 'isConfigDone', embellishment: 'exploreButton', width: '9rem' },
					label: { name: '' },
				},
			],
		};
	} else {
		return {
			columns: [
				{
					id: {
						key: 'entityName',
						embellishment: old ? 'hyper' : 'bold',
						width: '11.625rem',
					},
					label: { name: 'Plant', isSortReq: true },
				},
				{
					id: { key: 'businessUnit', embellishment: null, width: '10.25rem' },
					label: { name: 'Business Unit', isSortReq: true },
				},
				{
					id: { key: 'actualOEE', embellishment: 'colorBar', width: '7.25rem' },
					label: { name: 'OEE', isSortReq: true, embellishment: 'bold' },
				},
				{
					id: { key: 'actualAvailability', embellishment: null, width: '8.7rem' },
					label: { name: 'Availability', isSortReq: true },
				},
				{
					id: { key: 'actualPerformance', embellishment: null, width: '8.7rem' },
					label: { name: 'Performance', isSortReq: true },
				},
				{
					id: { key: 'actualQuality', embellishment: null, width: '7.25rem' },
					label: { name: 'Quality', isSortReq: true },
				},
				{
					id: { key: 'isConfigDone', embellishment: 'exploreButton', width: '11.25rem' },
					label: { name: '', isSortReq: false },
				},
			],
		};
	}
};
