export default {
	h1: {
		fontWeight: 500,
		fontSize: '2.1875em',
		letterSpacing: '0.015em',
	},
	h2: {
		fontWeight: 500,
		fontSize: '1.8125em',
		letterSpacing: '0.015em',
	},
	h3: {
		fontWeight: 500,
		fontSize: '1.5em',
		letterSpacing: '0.00375em',
	},
	h4: {
		fontWeight: 500,
		fontSize: '1.25em',
		letterSpacing: '0.00375em',
	},
	h5: {
		fontWeight: 700,
		fontSize: '1em',
		letterSpacing: '0.00375em',
	},
	h6: {
		fontWeight: 500,
		fontSize: '1em',
		letterSpacing: '0.00375em',
		lineHeight: '1.361875em',
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: '1em',
		letterSpacing: '0.00375em',
		lineHeight: '1.5em',
	},
	subtitle2: {
		fontWeight: 400,
		fontSize: '0.875em',
		letterSpacing: '0.00375em',
		lineHeight: '1.5em',
	},
	caption: {
		fontWeight: 400,
		fontSize: '0.75em',
	},
	overline: {
		fontWeight: 500,
	},
	errorText: {
		fontWeight: 400,
		fontSize: '0.75em',
	},
	fontFamily: 'Open Sans',
};
