import React from 'react';
import Input from '../../../../components/Input';
import { useFormContext } from 'react-hook-form';
import * as S from './style';
import PlusIcon from '../../../../assets/img/PlusInCircle.svg';
import CloseIcon from '../../../../assets/img/closeIcon.svg';
import { Texts } from '../../constants';

const CreateBusinessUnitStep = () => {
	const {
		register,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();
	/* This component un-mounts when the user moves from this step. In order for them not to lose
       their progress if they decide to go back, this component mounts with the current form values */

	const formBusinessUnitValues = Object.values(watch('createBusinessUnitStep'));
	const stepError = errors?.createBusinessUnitStep;

	const handleAddItem = (i) => {
		const currentBusinessUnitValues = watch('createBusinessUnitStep');
		setValue('createBusinessUnitStep', {
			...currentBusinessUnitValues,
			[`businessUnit-${i + 1}`]: '',
		});
	};

	const handleRemoveItem = (i) => {
		const businessUnitsAfterRemoveElement = formBusinessUnitValues.filter(
			(item, j) => i !== j
		);

		setValue(
			'createBusinessUnitStep',
			businessUnitsAfterRemoveElement.reduce((acc, currentValue, i) => {
				acc[`businessUnit-${i}`] = currentValue;
				return acc;
			}, {})
		);
	};

	return (
		<section>
			{formBusinessUnitValues.map((_, i) => {
				const isLastItem = i === formBusinessUnitValues.length - 1;
				const isTheOnlyItem = formBusinessUnitValues.length === 1;

				const isDisplayRemoveButton = !isLastItem && !isTheOnlyItem;

				return (
					<S.BusinessUnitItemContainer
						key={`businessUnit-${i}`}
						aria-role='business-unit--inline'
					>
						<Input
							name={`createBusinessUnitStep.businessUnit-${i}`}
							placeholder={Texts.ENTER_VALUE}
							{...{ register }}
							error={stepError?.[`businessUnit-${i}`]?.message}
						/>
						{isLastItem && (
							<S.BusinessUnitIconButton
								src={PlusIcon}
								aria-role='button'
								aria-readonly='false'
								onClick={() => handleAddItem(i)}
							/>
						)}

						{isDisplayRemoveButton && (
							<S.BusinessUnitIconButton
								aria-role='button'
								aria-readonly='false'
								src={CloseIcon}
								onClick={() => handleRemoveItem(i)}
							/>
						)}
					</S.BusinessUnitItemContainer>
				);
			})}
		</section>
	);
};

export default CreateBusinessUnitStep;
