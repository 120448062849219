import { Icon, styled, Typography } from '@mui/material';

export const IconView = styled(Icon)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
}));

export const MessageView = styled(Typography)(({ theme, fontSize }) => ({
	color: theme.customColors.white,
	fontSize: fontSize,
}));
