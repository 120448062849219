import { Box } from '@mui/material';

import { WrappedFont, StyledButton } from '.';
import MESSAGE_STRINGS from '../../constants/en-us';
import { StyledToolTip } from '../Tooltip';
import { BUTTON_TYPE } from '../../constants';

export const handleSort = (
	orderBy,
	reqOrder = 'desc',
	rowsToBeSorted = [],
	defaultOrder = 'entityName'
) => {
	if (!orderBy) {
		return rowsToBeSorted;
	}
	if (reqOrder === 'asc') {
		return rowsToBeSorted.sort((a, b) => {
			return ascendingSort(a, b, orderBy, defaultOrder);
		});
	} else {
		return rowsToBeSorted.sort((a, b) => {
			return descendingSort(a, b, orderBy, defaultOrder);
		});
	}
};

function ascendingSort(a, b, orderBy, defaultOrder) {
	if (a[orderBy] === b[orderBy]) {
		return sameValuesSort(a, b, orderBy, defaultOrder);
	} else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
		return compare(a[orderBy], b[orderBy]);
	} else if (a[orderBy] === undefined || a[orderBy] === 'N/A' || a[orderBy] === null)
		return 1;
	else if (a[orderBy] > b[orderBy]) return 1;
	else return -1;
}

function descendingSort(a, b, orderBy, defaultOrder) {
	if (a[orderBy] === b[orderBy]) {
		return sameValuesSort(a, b, orderBy, defaultOrder);
	} else if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
		return compare(a[orderBy], b[orderBy]) * -1;
	} else if (a[orderBy] === undefined || a[orderBy] === 'N/A' || a[orderBy] === null)
		return 1;
	else if (a[orderBy] < b[orderBy]) return 1;
	else return -1;
}

function sameValuesSort(a, b, orderBy, defaultOrder) {
	if (
		(a[orderBy] === undefined && b[orderBy] === undefined) ||
		(a[orderBy] === null && b[orderBy] === null) ||
		(typeof a[defaultOrder] === 'string' && typeof b[defaultOrder] === 'string')
	) {
		return compare(a[defaultOrder], b[defaultOrder]);
	} else if (
		a[defaultOrder] === undefined ||
		a[defaultOrder] === 'N/A' ||
		a[defaultOrder] === null
	)
		return 1;
	else if (a[defaultOrder] > b[defaultOrder]) return 1;
	else if (a[defaultOrder] === b[defaultOrder]) return 0;
	else return -1;
}

function compare(A, B) {
	const a = A.toLowerCase();
	const b = B.toLowerCase();
	if (a > b) return 1;
	else if (a < b) return -1;
	else return 0;
}

export const getTableCellData = (
	columnId,
	cellValue,
	row,
	column,
	factoryConfigPermission,
	onPlantClick
) => {
	const { key, embellishment } = columnId;
	if (key === 'isConfigDone') {
		const isConfigDone = cellValue;
		const disableConfigButton = !isConfigDone && !factoryConfigPermission;
		return (
			<StyledToolTip
				placement='top-end'
				title={
					disableConfigButton &&
					MESSAGE_STRINGS['mapWrapper.plants.configure-button.incompleteFCG']
				}
			>
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<StyledButton
						data-testid='configure-explore-button'
						onClick={() => onPlantClick(row.factoryId)}
						buttonType={BUTTON_TYPE.SECONDARY_NARROW}
						text={isConfigDone ? 'Explore' : 'Configure'}
						size='small'
						disabled={disableConfigButton}
					></StyledButton>
				</Box>
			</StyledToolTip>
		);
	}
	return addEmbellishments(
		embellishment,
		row,
		column,
		factoryConfigPermission,
		onPlantClick
	);
};

export function addEmbellishments(
	embellishment,
	row,
	column,
	factoryConfigPermission = false,
	onPlantClick = () => {},
	styles = {}
) {
	let displayText = row[column?.id?.key];
	if (typeof displayText === 'number') displayText = `${Math.round(displayText)}%`;
	else if (!displayText) displayText = null;
	switch (embellishment) {
		case 'hyper':
			const enableLinkClick = factoryConfigPermission || row.isConfigDone;
			return (
				<WrappedFont
					onClick={() => enableLinkClick && onPlantClick(row.factoryId)}
					data-testid={'globe-plant-name'}
					sx={{
						fontWeight: 'bold',
						'&:hover': {
							textDecoration: enableLinkClick && 'underline',
						},
						cursor: enableLinkClick ? 'pointer' : 'default',
						maxWidth: '9.62rem',
						...styles,
					}}
				>
					{displayText}
				</WrappedFont>
			);
		case 'colorBar':
			if (displayText === 'N/A' || !displayText)
				return (
					<WrappedFont
						data-testid={'globe-OEE-value'}
						sx={{
							marginLeft: '.65rem',
							width: '5.25rem',
							fontWeight: 'bold',
							...styles,
						}}
					>
						{'N/A'}
					</WrappedFont>
				);
			else
				return (
					<Box
						sx={{
							height: '.8rem',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Box
							data-testid='globe-OEE-colorBar'
							sx={{
								minHeight: '.75rem',
								minWidth: '.375rem',
								backgroundColor: row?.colourCodeOEE,
								margin: '.25rem',
							}}
						/>
						<WrappedFont
							data-testid={'globe-OEE-value'}
							sx={{
								fontWeight: 'bold',
								maxWidth: '5.25rem',
								...styles,
							}}
						>
							{displayText}
						</WrappedFont>
					</Box>
				);
		case 'redText':
			return displayText ? (
				<Box>
					<Box
						sx={{
							border: '.0625rem solid red',
							borderRadius: '.4rem',
							backgroundColor: 'rgba(255, 0, 0, .1)',
							color: 'red',
							width: 'fit-content',
							padding: '0 .5rem',
						}}
					>
						<WrappedFont
							data-testid={'globe-LostEarnings-value'}
							sx={{ fontWeight: 700, maxWidth: '6.25rem', ...styles }}
						>{`$${row[column?.id?.key].toLocaleString(navigator.language, {
							minimumFractionDigits: 0,
						})}`}</WrappedFont>
					</Box>
				</Box>
			) : (
				<WrappedFont data-testid={'globe-LostEarnings-value'} sx={{ ...styles }}>
					-
				</WrappedFont>
			);
		case 'blank':
			return <Box sx={{ maxWidth: '6.5rem' }}></Box>;
		case 'plainText':
			return (
				<WrappedFont
					data-testid={`globe-${String(column?.label?.name).split(' ').join('')}-value`}
					sx={{ maxWidth: '6.5rem', ...styles }}
					align='center'
				>
					{row[column?.id?.key]}
				</WrappedFont>
			);
		case 'bold':
			return (
				<WrappedFont
					data-testid={'globe-plant-name'}
					sx={{
						fontWeight: 'bold',
						maxWidth: '9.62rem',
						...styles,
					}}
				>
					{displayText}
				</WrappedFont>
			);
		default:
			return (
				<WrappedFont
					data-testid={`globe-${String(column?.label?.name).split(' ').join('')}-value`}
					sx={{ maxWidth: '6.5rem', ...styles }}
				>
					{displayText || 'N/A'}
				</WrappedFont>
			);
	}
}
