import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { TOAST_REDUCER_CONSTANTS } from '../../../../constants';
import {
	createPlant,
	getAllPlants,
	getStatesList,
	queryConstants,
	updatePlant,
} from '../../../../utils/apiHelper';
import EditPlantsContainer from './components/EditPlantsContainer';
import { buildEditPlantsDialogValidationSchema } from './helpers/buildEditPlantsDialogValidationSchema';
import { editPlantsDialogDefaultFormValues } from './helpers/editPlantsDialogDefaultFormValues';
import { parseAddEditPlantFormToBackendFormat } from './helpers/parseAddEditPlantFormToBackendFormat';
import { useToastContext } from '../../../../context/toastContext';
import Dialog from '../../../../components/Dialog';

import { toBase64 } from '../../utils';

const EditPlantsDialog = ({
	isOpen,
	onClose,
	companyId,
	isGetAllBusinessUnitListFetching,
	allBusinessUnits,
}) => {
	const methods = useForm({
		defaultValues: editPlantsDialogDefaultFormValues,
		resolver: yupResolver(buildEditPlantsDialogValidationSchema),
		mode: 'all',
	});

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = methods;
	const { toastDispatch } = useToastContext();

	useEffect(() => {
		reset();
	}, [isOpen]);

	const {
		isFetching: isGetAllPlantsFetching,
		data: allPlants,
		refetch: refetchAllPlants,
		isRefetching: isGetAllPlantsRefetching,
	} = useQuery(
		[queryConstants.GET_ALL_PLANTS],
		async () => {
			const result = await getAllPlants();
			const processedResult = result.plants.map(({ factoryId, entityName }) => ({
				plantName: entityName,
				plantId: factoryId,
			}));

			return processedResult;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { isFetching: isGetStatesListFetching, data: statesList } = useQuery(
		[queryConstants.GET_STATES_LIST],
		async () => {
			const result = await getStatesList();
			return result.states;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { mutate: createPlantMutate, isLoading: isCreatePlantMutationLoading } =
		useMutation((data) => createPlant(data), {
			onSuccess: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
				});

				reset();
				refetchAllPlants();
				onClose();
			},
			onError: (error) => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message: error?.response?.data?.message,
					},
				});
			},
		});

	const { mutate: updatePlantMutate, isLoading: isUpdatePlantMutationLoading } =
		useMutation((data) => updatePlant(data.plantId, data), {
			onSuccess: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
				});

				reset();
				refetchAllPlants();
				onClose();
			},
			onError: (error) => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message: error?.response?.data?.message,
					},
				});
			},
		});

	const onSubmit = async (submitValues) => {
		const parsedValues = parseAddEditPlantFormToBackendFormat(submitValues, companyId);
		const file = submitValues.plant.file?.[0];

		// populateForm function reconstructs a synthetic file object for validation purposes
		const isFileType = !!file?.lastModified;

		if (file && isFileType) {
			try {
				const fullBase64String = await toBase64(file);
				const [, base64file] = fullBase64String.split(',');
				parsedValues.plantImage.bytesStream = base64file;
			} catch ({ message }) {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
					payload: {
						message,
					},
				});
			}
		}

		const isUpdateMode = submitValues.metadata.isUpdateMode;
		isUpdateMode ? updatePlantMutate(parsedValues) : createPlantMutate(parsedValues);
	};

	const isLoading =
		(isGetStatesListFetching ||
			isGetAllBusinessUnitListFetching ||
			isGetAllPlantsFetching ||
			!companyId) &&
		!isGetAllPlantsRefetching;

	return isLoading ? (
		<LoadingIndicator />
	) : (
		<Dialog
			isOpen={isOpen}
			content={
				<FormProvider {...methods}>
					<EditPlantsContainer
						statesList={statesList}
						allBusinessUnits={allBusinessUnits}
						allPlants={allPlants}
						isCreatePlantMutationLoading={isCreatePlantMutationLoading}
						isUpdatePlantMutationLoading={isUpdatePlantMutationLoading}
						onClose={onClose}
					/>
				</FormProvider>
			}
			formConfiguration={{ onSubmit: handleSubmit(onSubmit) }}
		/>
	);
};

export default EditPlantsDialog;
