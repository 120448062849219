import { useState } from 'react';
import SearchIcon from '../../assets/img/searchIcon.svg';
import ClearResult from '../../assets/img/clearResultText.svg';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Paper, Popper } from '@mui/material';
import * as S from './style';

const DefaultFilterOptions = createFilterOptions({
	matchFrom: 'start',
});

const CustomPopper = (props) => {
	const { anchorEl } = props;
	const width = anchorEl.offsetParent.offsetWidth;
	return <Popper {...props} sx={{ width }} />;
};

const CustomPaper = (props) => {
	return <Paper {...props} />;
};

const DefaultTextField = (params) => {
	const { placeHolder, ...customParams } = params;

	if (!customParams.InputProps.startAdornment) {
		customParams.InputProps.startAdornment = (
			<img src={SearchIcon} alt='' className='startAdornment' />
		);
	}

	return (
		<S.CharcoalTextField
			{...customParams}
			placeholder={placeHolder}
			variant='standard'
			size='small'
			InputProps={{
				...customParams.InputProps,
				disableUnderline: true,
				placeholder: placeHolder,
			}}
		/>
	);
};

const DefaultRenderOption = (props = {}, option = '', state) => {
	const isMatching = option.toLowerCase().startsWith(state.inputValue.toLowerCase());

	if (isMatching) {
		const inputLength = state.inputValue.length;
		const hightLightedString = option.slice(0, inputLength);
		const restOfString = option.slice(inputLength);
		return (
			<S.StyledMenuItem {...props}>
				<span className='optionString'>
					<span className='highlightString'>{hightLightedString}</span>
					<span className='normalString'>{restOfString}</span>
					<br />
				</span>
					
			</S.StyledMenuItem>
		);
	}

	return <S.StyledMenuItem {...props}><span className='optionString'>{option}</span></S.StyledMenuItem>;
};

const SearchField = ({
	value = '',
	options = [],
	onChange = () => {},
	placeHolder = '',
}) => {
	const [inputValue, setInputValue] = useState('');

	return (
		<S.AutoCompleteView
			value={value}
			popupIcon={null}
			forcePopupIcon={false}
			options={options}
			onChange={onChange}
			inputValue={inputValue}
			PaperComponent={CustomPaper}
			PopperComponent={CustomPopper}
			renderOption={DefaultRenderOption}
			filterOptions={DefaultFilterOptions}
			clearIcon={<img src={ClearResult} alt='' />}
			onInputChange={(e, newValue) => setInputValue(newValue)}
			renderInput={(params) => DefaultTextField({ placeHolder, ...params })}
		/>
	);
};

export default SearchField;
