import { businessUnit, company, entity, plants } from '@smf/ui-util-app';

export const queryConstants = {
	GET_GLOBE_DATA: 'globeData',
	GET_ALL_PLANTS: 'getAllPlants',
	GET_PLANT_DATA: 'getPlantData',
	GET_ALL_BUSINESS_UNIT_LIST: 'getAllBusinessUnitList',
	GET_STATES_LIST: 'getStatesList',
};

export async function getGlobeDrawerData(
	paramsBusinessUnit,
	paramsGlobal,
	isBusinessUnit
) {
	// ToDo: if BisinessUnit is true, URL: urlEndpoints.USER_ACCESSIBLE_ENTITIES, params: paramsBusinessUnit
	const result = isBusinessUnit
		? await entity.getEntities(paramsBusinessUnit)
		: await entity.getEntities(paramsGlobal);

	return result;
}

export async function addUpdateBusinessUnit(businessUnitList) {
	const result = await businessUnit.addUpdateBusinessUnit(businessUnitList);
	return result;
}

export async function getAllBusinessUnitList() {
	const result = await businessUnit.getAllBusinessUnitList();
	return result;
}

export async function getAllPlants() {
	const result = await plants.getAllPlants();
	return result;
}

export async function getPlantData(plantId) {
	const result = await plants.getPlantData(plantId);
	return result;
}

export async function getStatesList() {
	const result = await plants.getStatesList();
	return result;
}

export async function createPlant(data) {
	const result = await plants.createPlant(data);
	return result;
}

export async function updatePlant(plantId, data) {
	const result = await plants.updatePlant(plantId, data);
	return result;
}

export async function createCompany(payload, params = {}) {
	const result = await company.createCompany(payload, params);
	return result;
}

export async function getImage(companyId, plantId, fileName) {
	const result = await plants.getImage({
		companyId,
		plantId,
		fileName,
	});
	return result;
}
