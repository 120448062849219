import { ACCEPTED_UPLOAD_FILE_TYPES } from './components/CreatePlantStep/components/UploadFiles/constants';

export const Texts = Object.freeze({
	CREATE_COMPANY: 'Create Company',
	CREATE_BUSINESS_UNIT: 'Create Business Unit',
	CREATE_BUSINESS_UNITS: 'Create Business Units',
	ENTER_PLANT_NAME: 'Enter Plant Name',
	ENTER_BUSINESS_UNIT: 'Enter Business Unit',
	ENTER_COMPANY_NAME: 'Enter Company Name',
	ENTER_VALUE: 'Enter Value',
	SELECT_BUSINESS_UNIT: 'Select Business Unit',
	ENTER_ZIP_CODE: 'Enter Zip Code',
	ENTER_CITY: 'Enter City',
	ENTER_STREET: 'Enter Street',
	SELECT_STATE: 'Select State',
	ADD_ANOTHER_PLANT: 'Add Another Plant',
	OK: 'Ok',
	STREET: 'Street',
	CITY: 'City',
	STATE: 'State',
	ZIP_CODE: 'Zip Code',
	CREATE_PLANT: 'Create Plant',
	EDIT_PLANT: 'Edit Plant',
	NEXT: 'Next',
	PREVIOUS: 'Previous',
	SAVE: 'Save',
	CANCEL: 'Cancel',
	RESET: 'Reset',
});

export const FormSteps = Object.freeze({
	CREATE_COMPANY: 'Create Company',
	CREATE_BUSINESS_UNIT: 'Create Business Unit',
	CREATE_PLANT: 'Create Plant',
});

export const Groups = Object.freeze({
	GLOBAL_SYSTEM_ADMIN: 'GlobalSystemAdmin',
});

export const initialHeaderStepsData = [
	{
		label: Texts.CREATE_COMPANY,
		stepNumber: '1',
		isCompleted: false,
		isActive: true,
	},
	{
		label: Texts.CREATE_BUSINESS_UNIT,
		stepNumber: '2',
		isCompleted: false,
		isActive: false,
	},
	{ label: Texts.CREATE_PLANT, stepNumber: '3', isCompleted: false, isActive: false },
];

export const MINIMUM_FIELD_LENGTH = 2;
export const MAXIMUM_FIELD_LENGTH = 255;
export const ZIP_CODE_FIELD_EXACT_LENGTH = 5;
export const MAXIMUM_FILE_SIZE_LABEL = '4 MB';
export const MAXIMUM_FILE_SIZE = 4194304;

export const Errors = {
	MIN_LENGTH: `A minimum of ${MINIMUM_FIELD_LENGTH} characters is required`,
	MAX_LENGTH: `The maximum character limit is ${MAXIMUM_FIELD_LENGTH}`,
	ZIP_CODE: 'Please enter a valid zip code',
	FILE_SIZE: `File must be less than ${MAXIMUM_FILE_SIZE_LABEL} in size`,
	FILE_TYPE: `The file image must be in ${ACCEPTED_UPLOAD_FILE_TYPES} format`,
	BU_DUPLICATE: 'Business Unit already exists. Please enter a unique value',
	REQUIRED: 'Required field',
};

export const DRAWERTESTIDS = {
	HEADING: 'drawer-Heading',
	ADDBUTTON: 'drawer-Add-button',
	TOOLTIP: 'drawer-Tooltip',
	PLANTBUTTON: 'drawer-Plant-button',
	BUSINESSBUTTON: 'drawer-Business-button',
	PLANNEDBUTTON: 'drawer-PlannedToggle-button',
	MAXBUTTON: 'drawer-MaxToggle-button',
	COLLAPSEBUTTON: 'drawer-Collapse-button',
	EXPANDBUTTON: 'drawer-Expand-button',
};
