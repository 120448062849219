import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { globeDrawerToolTipColors } from '../../theme';

export const StyledToolTip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		color: globeDrawerToolTipColors.lightYellowishGray,
		backgroundColor: globeDrawerToolTipColors.eerieBlack,
		border: `0.0625rem solid ${globeDrawerToolTipColors.codGrey}`,
		padding: '.625rem .75rem',
		fontStyle: 'normal',
		fontWeight: '400',
		fontSize: '.75rem',
		lineHeight: '1rem',
		boxShadow: '.25rem .25rem .25rem rgba(0, 0, 0, 0.5)',
		borderRadius: '.25rem',
		maxWidth: '21.5rem',
	},
});
