// (file) => [fileType: string, byteStream: string]
export const toBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
};

// (number) => number
export const kilobytesToBytes = (kilobytes) => {
	const BYTES_IN_A_KILOBYTE = 1024;

	return kilobytes * BYTES_IN_A_KILOBYTE;
};

export const isStringNumericOnly = new RegExp('^[0-9]+$');
