import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Toast from '../components/Toast';
import { TOAST_REDUCER_CONSTANTS, TOAST_TYPE } from '../constants';
import MESSAGE_STRINGS from '../constants/en-us';

export const ToastContext = createContext();

export const useToastContext = () => useContext(ToastContext);

const initialValues = {
    key: 1,
    type: '',
    message: '',
    show: false,
};

function reducer(state, action) {
    switch (action.type) {
        case TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST: {
            return {
                ...state,
                key: state.key + 1,
                type: TOAST_TYPE.SUCCESS,
                message: action.payload
                    ? action.payload.message
                    : MESSAGE_STRINGS['Toast.message.SUCCESS'],
                show: true,
            };
        }
        case TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST: {
            return {
                ...state,
                key: state.key + 1,
                type: TOAST_TYPE.ERROR,
                message: action.payload && action.payload.message,
                show: true,
            };
        }
        case TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST: {
            return {
                ...state,
                key: state.key + 1,
                type: TOAST_TYPE.WARNING,
                message: action.payload && action.payload.message,
                show: true,
            };
        }
        case TOAST_REDUCER_CONSTANTS.SHOW_INFO_TOAST: {
            return {
                ...state,
                key: state.key + 1,
                type: TOAST_TYPE.INFO,
                message: action.payload && action.payload.message,
                show: true,
            };
        }
        case TOAST_REDUCER_CONSTANTS.HIDE_TOAST: {
            return {
                ...state,
                type: '',
                message: '',
                show: false,
            };
        }
        default:
            return state;
    }
}

export function ToastProvider({ children }) {
    const [toastState, toastDispatch] = useReducer(reducer, initialValues);

    useEffect(() => {
        let timer;
        if (toastState.show) {
            timer = setTimeout(
                () =>
                    toastDispatch({
                        type: TOAST_REDUCER_CONSTANTS.HIDE_TOAST,
                    }),
                3500
            );
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [toastState.show]);

    return (
        <ToastContext.Provider value={{ toastState, toastDispatch }}>
            {children}
            {toastState.show && toastState.key && (
                <Toast key={toastState.key} type={toastState.type} message={toastState.message} />
            )}
        </ToastContext.Provider>
    );
}

ToastProvider.propTypes = {
    children: PropTypes.node,
};