import { forwardRef, React } from 'react';
import PropTypes from 'prop-types';
import { MAX_INPUT_SIZE } from '../../constants';
import * as S from './style';

const TextField = forwardRef((props, ref) => {
	const {
		name = '',
		value,
		variant,
		error,
		helperText,
		type,
		onChange,
		maxLen = MAX_INPUT_SIZE,
		placeholder,
		customInputProps = {},
		fullWidth = true,
		disabledGrey = false,
		...other
	} = props;

	return (
		<S.TextFieldRoot
			name={name}
			value={value}
			{...{ disabledGrey }}
			error={error || false}
			helperText={helperText || ''}
			variant={variant || 'outlined'}
			type={type || 'text'}
			onChange={onChange}
			fullWidth={fullWidth}
			// eslint-disable-next-line react/jsx-no-duplicate-props
			inputProps={{
				maxLength: maxLen,
				form: {
					autocomplete: 'off',
				},
				...customInputProps,
			}}
			placeholder={placeholder}
			autoComplete='no'
			ref={ref}
			{...other}
		/>
	);
});

TextField.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	variant: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	type: PropTypes.string,
	maxLen: PropTypes.number,
	placeholder: PropTypes.string,
	customInputProps: PropTypes.shape({}),
	fullWidth: PropTypes.bool,
	disabledGrey: PropTypes.bool,
};

export default TextField;
