import { styled } from '@mui/material';

export const BusinessUnitItemContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	paddingBottom: '0.5rem',
	backgroundColor: theme.palette.background.blackGrey,
	border: `1px solid ${theme.customColors.targetLightGrey}`,
	padding: '0.75rem 1.3125rem',
	animation: '$slideIn 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',

	'&:not(:last-child)': {
		marginBottom: '0.5rem',
	},
	'@keyframes slideIn': {
		'0%': {
			opacity: 0,
			transform: 'translateY(16px)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(0)',
		},
	},
}));

export const BusinessUnitIconButton = styled('img')(({ theme }) => ({
	width: '1.5rem',
	cursor: 'pointer',
	transition: '0.2s transform ease-in-out',

	'&:active': {
		transform: 'scale(0.9)',
	},
	'@keyframes slideIn': {
		'0%': {
			opacity: 0,
			transform: 'translateY(16px)',
		},
		'100%': {
			opacity: 1,
			transform: 'translateY(0)',
		},
	},
}));
