import { useState, useEffect } from 'react';
import { FormSteps, initialHeaderStepsData, Texts } from '../constants';
import CreateCompanyStep from '../components/CreateCompanyStep';
import CreateBusinessUnitStep from '../components/CreateBusinessUnitStep';
import CreatePlantStep from '../components/CreatePlantStep';
import { setHeaderStep as setHeaderStepBuilder } from './utils';

export const useAddCompanyData = ({
	trigger,
	errors,
	dirtyFields,
	formValues,
	setValue,
}) => {
	const [headerStepsData, setHeaderStepsData] = useState(initialHeaderStepsData);
	const [isCreateStepNextButtonAvailable, setIsCreateStepNextButtonAvailable] =
		useState(false);
	const [
		isBusinessUnitStepNextButtonAvailable,
		setIsBusinessUnitStepNextButtonAvailable,
	] = useState(false);

	const isCompanyNameError = !!errors?.createCompanyStep?.companyName;
	const isCompanyNameDirty = !!dirtyFields?.createCompanyStep?.companyName;

	const isBusinessUnitError = !!errors?.createBusinessUnitStep;
	const isBusinessUnitDirty = !!dirtyFields?.createBusinessUnitStep;
	// Edge case where user enters 1 BU, then adds another one which is empty and attempts to next.
	// has to be done here with a manual check
	const isBusinessUnitsHaveEmptyValue = Object.values(
		formValues.createBusinessUnitStep
	).includes('');

	useEffect(() => {
		const isNextAvailable = !isCompanyNameError && isCompanyNameDirty;
		setIsCreateStepNextButtonAvailable(isNextAvailable);
	}, [trigger, isCompanyNameError, isCompanyNameDirty]);

	useEffect(() => {
		const isNextAvailable =
			!isBusinessUnitError && isBusinessUnitDirty && !isBusinessUnitsHaveEmptyValue;
		setIsBusinessUnitStepNextButtonAvailable(isNextAvailable);
	}, [trigger, isBusinessUnitError, isBusinessUnitDirty, isBusinessUnitsHaveEmptyValue]);

	const dialogPropsData = {
		createCompanyStep: {
			title: Texts.CREATE_COMPANY,
			content: <CreateCompanyStep />,
			primaryButtonLabel: Texts.NEXT,
			primaryButtonOnClick: () => {
				setDialogProps(dialogPropsData.createBusinessUnitStep);
				setHeaderStep(FormSteps.CREATE_BUSINESS_UNIT);
			},
			currentStep: FormSteps.CREATE_COMPANY,
		},
		createBusinessUnitStep: {
			title: Texts.CREATE_BUSINESS_UNITS,
			content: <CreateBusinessUnitStep />,
			primaryButtonLabel: Texts.NEXT,
			primaryButtonOnClick: () => {
				setDialogProps(dialogPropsData.createPlantStep);
				setHeaderStep(FormSteps.CREATE_PLANT);
			},
			secondaryButtonLabel: Texts.PREVIOUS,
			secondaryButtonOnClick: () => {
				setDialogProps(dialogPropsData.createCompanyStep);
				setHeaderStep(FormSteps.CREATE_COMPANY);
			},
			currentStep: FormSteps.CREATE_BUSINESS_UNIT,
		},
		createPlantStep: {
			title: Texts.CREATE_PLANT,
			content: <CreatePlantStep />,
			primaryButtonLabel: Texts.SAVE,
			primaryButtonOnClick: () => {
				const isSaveClickedAtLeastOnce = formValues.metadata.isSaveClickedAtLeastOnce;
				if (!isSaveClickedAtLeastOnce) {
					setValue('metadata.isSaveClickedAtLeastOnce', true);
				}
			},
			secondaryButtonLabel: Texts.PREVIOUS,
			primaryButtonProps: {
				type: 'submit',
			},
			secondaryButtonOnClick: () => {
				setDialogProps(dialogPropsData.createBusinessUnitStep);
				setHeaderStep(FormSteps.CREATE_BUSINESS_UNIT);
			},

			currentStep: FormSteps.CREATE_PLANT,
		},
	};

	const [dialogProps, setDialogProps] = useState(dialogPropsData.createCompanyStep);

	const setHeaderStep = setHeaderStepBuilder({
		dialogPropsData,
		setDialogProps,
		setHeaderStepsData,
	});

	return {
		headerStepsData,
		dialogProps,
		isCreateStepNextButtonAvailable,
		isBusinessUnitStepNextButtonAvailable,
	};
};
