import React, { useEffect, useState, useMemo } from 'react';
import { Typography, Grid, Divider, Box, Tab } from '@mui/material';
import {
	getRoleThresholdValue,
	globalstate$,
	RBAC_BUTTONS,
	RBACValidation,
} from '@smf/ui-util-app';
import { keyBy } from 'lodash';

import MESSAGE_STRINGS from '../../constants/en-us';
import BusinessUnitsList from './Businessunit/BusinessUnitList/BusinessUnitList';
import URL from '../../utils/urlEndpoints';
import { navigateToUrl } from 'single-spa';
import { StyledDrawer, StyledTabs, StyledDivider, StyledCollapseButton } from './style';
import GeneralButton from '../../components/Button';
import GlobeTableComponent from '../../components/GlobeTableComponent';
import { DRAWERTESTIDS } from '../GlobePage/constants';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { createRowsAndColumns } from './utils';

//ToDo : Component scaffolding
const InitialModalSetup = ({
	globeData,
	userStatus,
	setShowAddEditPlants,
	setIsDrawerOpen,
	isDrawerOpen,
	resetData,
	refetchAllBusinessUnits,
}) => {
	//newImplementation
	const [type, setType] = useState(false);
	const [expand, setExpand] = useState({});
	// State to show the Business Unit Modal List to add/edit Business Unit
	const [showAddEditBU, setShowAddEditBU] = useState(false);
	const [addEditPlantsAccess, setAddEditPlantsAccess] = useState(false);
	const [addEditBUAccess, setAddEditBUAccess] = useState(false);
	const [addFactoryConfig, setAddFactoryConfig] = useState(false);
	const { rxjsState } = useRxjsState();
	const { showOldUI, globeDrawerState } = rxjsState; //disabled old ui

	useEffect(() => {
		setAddEditBUAccess(RBACValidation('Button', RBAC_BUTTONS['ADD_EDIT_BU'], userStatus));
		setAddEditPlantsAccess(
			RBACValidation('Button', RBAC_BUTTONS['ADD_EDIT_PLANT'], userStatus)
		);
		setAddFactoryConfig(
			RBACValidation('Button', RBAC_BUTTONS['FACTORY_CONFIG'], userStatus)
		);
	}, [userStatus]);

	useEffect(() => {
		if (type) {
			const expand = {};
			for (let item of globeData?.businessData) {
				expand[item.businessUnitId] = false;
			}
			setExpand(expand);
		}
	}, [type]);

	const updatedBUData = useMemo(
		function () {
			const data = keyBy(globeData.data, 'factoryId');
			globeData.businessData.forEach((element) => {
				let flagOEE;
				if (element?.childEntities) {
					const updatedChildList = element?.childEntities.map((child) => {
						if (typeof child?.actualOEE === 'number') {
							flagOEE = child?.actualOEE;
						}
						const id = child.factoryId;
						if (data.hasOwnProperty(id)) {
							return data[id];
						} else return child;
					});
					element.childEntities = updatedChildList;
					if (flagOEE === undefined) {
						element.plannedOEE = undefined;
						element.plannedAvailability = undefined;
						element.performance = undefined;
						element.quality = undefined;
						element.oeeLossEarningsPlannedRunTime = undefined;
					}
				}
			});
			return globeData.businessData;
		},
		[globeData]
	);

	const { columns, childrenCols } = useMemo(
		() => createRowsAndColumns(type, showOldUI),
		[type, showOldUI]
	);

	//newImplementation
	const handleToggleSwitchChange = (func, value) => {
		func((prev) => {
			return prev == Boolean(Number(value)) ? prev : !prev;
		});
	};

	const handleExpandAndCollapse = (value) => {
		let expand = {};
		if (!globeData.businessData) return;

		if (Number(value) === 1) {
			for (let item of globeData.businessData) {
				expand[item.businessUnitId] = true;
			}
			setExpand(expand);
		} else {
			for (let item of globeData.businessData) {
				expand[item.businessUnitId] = false;
			}
			setExpand(expand);
		}
	};

	const onPlantClick = (factoryId) => {
		const plantData = globeData.data;
		// DO not change
		const clickedPlant = plantData.find((plant) => plant.factoryId === factoryId);
		const plantDetails = {
			plant_id: parseInt(clickedPlant.factoryId),
			plant_role: clickedPlant.role,
			plant: clickedPlant.entityName,
			business_unit: clickedPlant.businessUnit,
			isConfigDone: clickedPlant.isConfigDone,
			factory_id: clickedPlant.factoryId,
			businessUnitId: clickedPlant.businessUnitId,
		};
		navigateToPage(plantDetails);
	};

	function navigateToPage(clickedPlant) {
		function getRoute() {
			if (clickedPlant.isConfigDone && !showOldUI) {
				return `${URL.CONFIG}${URL.CONNECTIVITY_MDU}`;
			} else if (!clickedPlant.isConfigDone && !showOldUI) {
				return `${URL.CONFIG}${URL.GENERAL_CONFIG}`;
			} else if (!clickedPlant.isConfigDone && showOldUI) {
				return `${URL.CONFIG}${URL.CONNECTIVITY_MDU}`;
			} else {
				return `${URL.PERFORMANCE_MANAGEMENT}`;
			}
		}

		if (clickedPlant) {
			const nextRoute = getRoute();
			console.info('nextRoute', nextRoute);
			globalstate$.next({
				...globalstate$.value,
				plantId: clickedPlant.plant_id,
				plantName: clickedPlant.plant,
				factoryId: clickedPlant.factory_id,
				businessUnit: clickedPlant.business_unit,
				businessUnitId: clickedPlant.businessUnitId,
				plantRole: clickedPlant.plant_role || '',
				isFGCComplete: !!clickedPlant.isConfigDone,
				userStatus: getRoleThresholdValue(clickedPlant.plant_role),
			});
			navigateToUrl(nextRoute || '');
		}
	}

	const getExpandedState = useMemo(
		function () {
			const values = Object.values(expand);
			const state = {};
			state['expanded'] = values.every((element) => element === true);
			state['collapsed'] = values.every((element) => element === false);
			return state;
		},
		[expand]
	);

	const addEditBUCloseHandler = () => {
		setShowAddEditBU(false);
		setIsDrawerOpen(false);
		resetData();
	};

	const getInitSortState = () => {
		if (type) return globeDrawerState?.mapViewTableInitSortBU;
		else return globeDrawerState?.mapViewTableInitSort;
	};
	useEffect(() => {
		setIsDrawerOpen(true);
	}, []);

	return (
		<>
			<StyledDrawer elevation={0} open={isDrawerOpen} disableEnforceFocus>
				<Box m='.5rem 1rem'>
					<Grid container>
						<Grid item xs={10} p='1rem 0 1rem .25rem '>
							<Typography variant='h5' data-testid={DRAWERTESTIDS.HEADING}>
								{type
									? MESSAGE_STRINGS['MapWrapper.drawer.bu.subHeading']
									: MESSAGE_STRINGS['MapWrapper.drawer.plants.subHeading']}
							</Typography>
						</Grid>
						<Grid item xs={2} align='right' p='.25rem 0'>
							{((!type && addEditPlantsAccess) || (type && addEditBUAccess)) && (
								<GeneralButton
									data-testid={DRAWERTESTIDS.ADDBUTTON}
									onClick={() => {
										type ? setShowAddEditBU(true) : setShowAddEditPlants(true);
									}}
									sx={{ borderRadius: '1.25rem' }}
									text=''
								>
									<Typography sx={{ fontWeight: '600', fontSize: '.87rem' }}>
										{MESSAGE_STRINGS['MapWrapper.drawer.plants.addEditButton']}
									</Typography>
								</GeneralButton>
							)}
						</Grid>
						<StyledDivider sx={{ marginBottom: '1rem', width: '100%' }} />
						<Grid item xs={9} m='1rem 0 0 0'>
							<StyledTabs
								value={Number(type)}
								onClick={(e) => handleToggleSwitchChange(setType, e.target.dataset.value)}
								size='small'
								indicatorColor='white'
							>
								<Tab
									label={MESSAGE_STRINGS['MapWrapper.drawer.plants.subHeading']}
									data-value={0}
									data-testid={DRAWERTESTIDS.PLANTBUTTON}
									sx={{ textTransform: 'none' }}
								/>
								<Tab
									label={MESSAGE_STRINGS['MapWrapper.drawer.bu.subHeading']}
									data-value={1}
									data-testid={DRAWERTESTIDS.BUSINESSBUTTON}
									sx={{ textTransform: 'none' }}
								/>
							</StyledTabs>
						</Grid>
						{type && (
							<Grid item xs={3} align='right' m='1rem 0 0 0'>
								<Box
									display='flex'
									sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
								>
									<StyledCollapseButton
										data-value={1}
										onClick={(e) => handleExpandAndCollapse(e.target.dataset.value)}
										data-testid={DRAWERTESTIDS.EXPANDBUTTON}
										disableRipple
										sx={{
											color: getExpandedState?.expanded ? 'gray' : 'white',
										}}
										className={getExpandedState?.expanded ? 'selected' : ''}
									>
										{MESSAGE_STRINGS['MapWrapper.drawer.expand.Button']}
									</StyledCollapseButton>
									<Divider
										orientation='vertical'
										sx={{ height: '1.25rem', width: '.0625rem' }}
									/>
									<StyledCollapseButton
										data-value={0}
										onClick={(e) => handleExpandAndCollapse(e.target.dataset.value)}
										data-testid={DRAWERTESTIDS.COLLAPSEBUTTON}
										disableRipple
										sx={{
											color: getExpandedState?.collapsed ? 'gray' : 'white',
										}}
										className={getExpandedState?.collapsed ? 'selected' : ''}
									>
										{MESSAGE_STRINGS['MapWrapper.drawer.collapse.Button']}
									</StyledCollapseButton>
								</Box>
							</Grid>
						)}
					</Grid>
					<GlobeTableComponent
						globeData={type ? updatedBUData : globeData.data}
						collapse={type}
						expand={{ expand: expand, setExpand: setExpand }}
						factoryConfigPermission={addFactoryConfig}
						onPlantClick={onPlantClick}
						initSort={getInitSortState()}
						columns={columns}
						childrenCols={type ? childrenCols : []}
					/>
				</Box>
			</StyledDrawer>
			{showAddEditBU && (
				<BusinessUnitsList
					open={showAddEditBU}
					handleClose={addEditBUCloseHandler}
					refetchAllBusinessUnits={refetchAllBusinessUnits}
				/>
			)}
		</>
	);
};

InitialModalSetup.propTypes = {};

export default InitialModalSetup;
