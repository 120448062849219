import { styled } from '@mui/material';

export const UploadedFileRoot = styled('div')(({ theme }) => ({
	fontSize: '0.87rem',
	cursor: 'unset',
	color: theme.customColors.nobelGrey,
	width: '28.25rem',
	height: '3.56rem',
	boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
	backgroundColor: theme.palette.background.elevationGrey,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0.87rem 1rem',
	marginTop: '0.7rem',
}));

export const UploadedFileContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
}));

export const UploadedImage = styled('img')(({ theme }) => ({
	marginRight: '1.1rem',
	width: '2.33rem',
	height: '1.85rem',
}));
