export const COMPONENT_ID = {
    STEPPER: 'step-',
    TABLE_HEADER_ROW: 'table-header-row',
    TABLE_CELL_ROW: 'table-cell-row',
    MODAL_BODY: 'modal-body',
    MODAL_BUTTON_PRIMARY: 'button-modalPrimary',
    MODAL_BUTTON_SECONDARY: 'button-modalSecondary',
    TABS: 'tabs',
    TAB_ITEM: 'tab-item',
    PAGE_SIDEBAR_ITEM: 'sidebar-menu-item',
    MAIN_SIDEBAR_ITEM: 'mainSidebar-menu-item',
    MAIN_SIDEBAR_LIST: 'mainSidebar-list',
    CUSTOM_CHECKBOX: 'custom-checkbox',
    ICON_BUTTON: 'icon-button',
};

