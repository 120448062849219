import * as yup from 'yup';
import { ACCEPTED_UPLOAD_FILE_TYPES } from '../components/CreatePlantStep/components/UploadFiles/constants';
import {
	MAXIMUM_FIELD_LENGTH,
	MAXIMUM_FILE_SIZE,
	MINIMUM_FIELD_LENGTH,
	ZIP_CODE_FIELD_EXACT_LENGTH,
	Errors,
} from '../constants';
import { isStringNumericOnly } from '../utils';

const testDuplicateValues = (businessUnits) => (value) => {
	let count = 0;
	for (let key in businessUnits) {
		if (businessUnits[key] === value) count += 1;
	}
	const isDuplicate = count > 1 && Object.keys(businessUnits).length > 1;
	return !isDuplicate;
};

const getDynamicBusinessUnitSchema = (businessUnits) => {
	// businessUnits: object with Input values
	const schema = {};
	Object.keys(businessUnits).forEach((key) => {
		schema[key] = yup
			.string()
			.required(Errors.REQUIRED)
			.test(
				'check-for-duplicates',
				Errors.BU_DUPLICATE,
				testDuplicateValues(businessUnits)
			)
			.min(MINIMUM_FIELD_LENGTH, Errors.MIN_LENGTH)
			.max(MAXIMUM_FIELD_LENGTH, Errors.MAX_LENGTH);
	});
	return yup.object(schema);
};

const testFileSize = (fileList) => {
	const file = fileList?.[0];
	const isFileExist = fileList?.length > 0;

	if (!isFileExist) {
		// Allow a form without uploading a file
		return true;
	}

	if (file) {
		return file.size <= MAXIMUM_FILE_SIZE;
	}

	return false;
};

const testFileType = (fileList) => {
	const file = fileList?.[0];
	const isFileExist = fileList?.length > 0;

	if (!isFileExist) {
		// Allow a form without uploading a file
		return true;
	}

	if (file) {
		const [, fileType] = file.type.split('/');

		return ACCEPTED_UPLOAD_FILE_TYPES.includes(fileType);
	}
	return false;
};

const textFieldValidationSchema = yup
	.string()
	.required(Errors.REQUIRED)
	.min(MINIMUM_FIELD_LENGTH, Errors.MIN_LENGTH)
	.max(MAXIMUM_FIELD_LENGTH, Errors.MAX_LENGTH);

const zipCodeValidationSchema = yup
	.string()
	.required(Errors.REQUIRED)
	.min(ZIP_CODE_FIELD_EXACT_LENGTH, Errors.ZIP_CODE)
	.max(ZIP_CODE_FIELD_EXACT_LENGTH, Errors.ZIP_CODE)
	.matches(isStringNumericOnly, Errors.ZIP_CODE);

export const addCompanyValidationSchema = yup.lazy((formValues) =>
	yup
		.object({
			createCompanyStep: yup.object({
				companyName: textFieldValidationSchema,
			}),
			createBusinessUnitStep: getDynamicBusinessUnitSchema(
				formValues.createBusinessUnitStep || {}
			).required(Errors.REQUIRED),
			createPlantStep: yup.object({
				plantName: textFieldValidationSchema,
				businessUnit: yup
					.string()
					.oneOf(Object.values(formValues.createBusinessUnitStep))
					.required(Errors.REQUIRED),
				street: textFieldValidationSchema,
				city: textFieldValidationSchema,
				state: textFieldValidationSchema,
				stateCode: textFieldValidationSchema,
				zipCode: zipCodeValidationSchema,
				file: yup
					.mixed()
					.test('check-for-file-type', Errors.FILE_TYPE, testFileType)
					.test('check-for-file-size', Errors.FILE_SIZE, testFileSize),
			}),
		})
		.required()
);
