import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import OptionalFormWrapper from './components/OptionalFormWrapper';
import * as S from './style';

/**
 * @author Dima Borodin
 *
 * @param isOpen Required - boolean
 * @param onClose () => void
 * @param title React.Node | React.Element
 * @param headerContent React.Node | React.Element
 * @param content React.Node | React.Element
 * @param propsClasses MUI classes object for overrides
 * @param formConfiguration Use if creating a form inside the dialog. Accepts an object that will be spread onto the <form> tag: { onSubmit: () => void, ...rest }
 * This exists since MUI is using portals to implement the dialog component.
 *
 * @return A controlled Dialog component
 */

const Dialog = (props) => {
	const {
		isOpen,
		onClose,
		title,
		headerContent,
		content,
		primaryButtonLabel,
		primaryButtonOnClick,
		primaryButtonProps,
		secondaryButtonLabel,
		secondaryButtonOnClick,
		secondaryButtonProps,
		leftButtonLabel,
		leftButtonOnClick,
		leftButtonProps,
		width,
		height,
		propsClasses,
		formConfiguration,
		...rest
	} = props;

	const hasActions = !!primaryButtonOnClick || !!secondaryButtonOnClick;

	return (
		<S.DialogRootView
			open={isOpen}
			data-isopen={isOpen}
			id='dialog-container--root'
			data-testid='dialog-container--root'
			aria-role='dialog-container--root'
			aria-modal='true'
			{...{ onClose, propsClasses }}
			{...rest}
		>
			<OptionalFormWrapper formConfiguration={formConfiguration}>
				<S.ComponentContainer {...{ propsClasses, width, height }}>
					{headerContent && (
						<S.HeaderContent
							aria-role='dialog-header'
							data-testid='dialog-header'
							{...{ propsClasses }}
						>
							{headerContent}
						</S.HeaderContent>
					)}

					{title && (
						<S.DialogTitleView
							aria-role='dialog-title'
							data-testid='dialog-title'
							aria-readonly='true'
							{...{ propsClasses }}
						>
							{title}
						</S.DialogTitleView>
					)}

					{content && (
						<S.DialogContentView
							data-testid='dialog-content'
							{...{ propsClasses }}
						>
							{content}
						</S.DialogContentView>
					)}

					{hasActions && (
						<S.DialogActionView
							aria-role='dialog-actions--container'
							data-testid='dialog-actions--container'
							{...{ propsClasses }}
						>
							{leftButtonOnClick && (
								<Button
									text={leftButtonLabel || ''}
									onClick={leftButtonOnClick}
									className={propsClasses?.leftButton}
									buttonType='secondary'
									aria-role='dialog-action--left'
									data-testid='dialog-action--left'
									{...(leftButtonProps || {})}
								/>
							)}

							<span>
								{secondaryButtonOnClick && (
									<Button
										text={secondaryButtonLabel || ''}
										onClick={secondaryButtonOnClick}
										className={propsClasses?.secondaryButton}
										buttonType='secondary'
										aria-role='dialog-action--secondary'
										data-testid='dialog-action--secondary'
										{...(secondaryButtonProps || {})}
									/>
								)}
								{primaryButtonOnClick && (
									<Button
										text={primaryButtonLabel || ''}
										onClick={primaryButtonOnClick}
										className={propsClasses?.primaryButton}
										aria-role='dialog-action--primary'
										data-testid='dialog-action--primary'
										{...(primaryButtonProps || {})}
									/>
								)}
							</span>
						</S.DialogActionView>
					)}
				</S.ComponentContainer>
			</OptionalFormWrapper>
		</S.DialogRootView>
	);
};

Dialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	title: PropTypes.node,
	headerContent: PropTypes.node,
	content: PropTypes.node,
	primaryButtonLabel: PropTypes.string,
	primaryButtonOnClick: PropTypes.func,
	primaryButtonProps: PropTypes.object,
	secondaryButtonLabel: PropTypes.string,
	secondaryButtonOnClick: PropTypes.func,
	secondaryButtonProps: PropTypes.object,
	leftButtonLabel: PropTypes.string,
	leftButtonOnClick: PropTypes.func,
	leftButtonProps: PropTypes.object,
	width: PropTypes.string,
	height: PropTypes.string,
	propsClasses: PropTypes.object,
	formConfiguration: PropTypes.object,
};

export default Dialog;
