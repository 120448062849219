import { styled, TextField as MuiTextField } from '@mui/material';

export const TextFieldRoot = styled(MuiTextField,  {
	shouldForwardProp: (prop) => prop !== 'disabledGrey',
})(({ theme, disabledGrey }) => ({
	'.Mui-disabled': {
		color: disabledGrey
			? theme.palette.background.shuttleGray
			: theme.palette.text.primary,
		cursor: 'not-allowed',
		fontFamily: 'Open Sans',
	},
	// minHeight: '5rem', // UI jumps to show error msg
	'& label.Mui-focused': {
		color: theme.palette.text.primary,
	},
	'& .MuiOutlinedInput-input': {
		padding: '0.8rem 0.8rem 0.8rem 0.8rem',
		color: theme.palette.text.primary,
		'&[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'&::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'&::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
	'& .MuiTypography-colorTextSecondary': {
		color: theme.palette.text.primary,
	},
	'& .MuiFormHelperText-root.Mui-error': {
		color: theme.palette.text.errorText,
		fontSize: '0.75rem',
		marginLeft: '0.2rem',
		minWidth: '100%',
	},
	'& .MuiOutlinedInput-root': {
		backgroundColor: theme.palette.background.blackGrey,
		height: '2rem',
		'& fieldset': {
			borderColor: theme.palette.text.lightWhiteGrey,
		},
		'&:hover fieldset': {
			borderColor: theme.palette.text.primary,
		},
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.text.greenText,
		},
		'&.Mui-disabled fieldset': {
			borderColor: theme.palette.text.lightWhiteGrey,
		},
	},
	'& .MuiFormHelperText-root': {
		color: theme.palette.text.primary,
		marginLeft: '0.2rem',
	},
}));
