import { IconButton as IconButtonComponent, styled } from '@mui/material';

export const IconButtonRoot = styled(IconButtonComponent)(({}) => ({
	padding: '0',
}));

export const IconButtonView = styled('img')(({ width, height }) => ({
	height: height || '0.865rem',
	width: width || '0.865rem',
}));
