import React from 'react';
import GlobePage from '../GlobePage';
import { useRxjsState } from '../../utils/hooks/useRxjsState';

const App = () => {
    const { rxjsState } = useRxjsState();
    return (<>
        {
            rxjsState.isLoggedInUser && <GlobePage />
        }
    </>);
};

export default App;
