import React, { useState, useRef, useLayoutEffect, useEffect, useMemo } from 'react';
import { styled, Typography, Box, IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';

import GeneralButton from '../Button';
import CollapsibleRow from './CollapsibleRow';
import { StyledToolTip } from '../Tooltip';

import sortingImgAsc from '../../assets/img/GlobeTableSortAsc.svg';
import sortingImgDesc from '../../assets/img/GlobeTableSortDesc.svg';
import { handleSort, getTableCellData } from './utils';
import { updateRxjsState, useRxjsState } from '../../utils/hooks/useRxjsState';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
	'.MuiTableCell-stickyHeader': {
		backgroundColor: theme.palette.background.darkishBlackBlue,
		borderColor: theme.palette.border.darkGrey,
		border: `.1em solid ${theme.palette.border.darkGrey} 0`,
		padding: '0.5rem 1rem',
		fontSize: '.8rem',
		color: theme.palette.text.lightUnitGrey,
	},
}));

const TableFont = styled(Typography)({
	fontSize: '.8rem',
	cursor: 'default',
});

export const StyledButton = styled(GeneralButton)({
	height: '1.5rem',
	width: '5.625rem',
	'& .MuiTypography-root': {
		fontWeight: 600,
		fontSize: '.75rem',
		lineHeight: '1rem',
	},
});

const StyledTableContainer = styled(TableContainer)(() => ({
	maxHeight: 'calc(100vh - 13.5rem)',
	overflowY: 'scroll',
	borderRadius: '.25rem',
	'*::-webkit-scrollbar-track': {
		backgroundColor: 'rgba(83, 85, 90, .6)',
	},
	'*::-webkit-scrollbar': {
		width: '0.875em;',
	},
}));

export function WrappedFont(props) {
	const containerRef = useRef();
	return (
		<Box ref={containerRef} sx={{ height: '1.2rem' }}>
			<StyledToolTip
				title={
					containerRef?.current?.lastChild?.clientWidth <
						containerRef?.current?.lastChild?.scrollWidth && props.children
				}
			>
				<TableFont {...props} sx={{ maxWidth: '10rem', ...props.sx }} noWrap={true}>
					{props.children}
				</TableFont>
			</StyledToolTip>
		</Box>
	);
}

export default function GlobeTableComponent({
	childEntities = 'childEntities',
	collapse = true,
	expand = { expand: {}, setExpand: () => {} },
	globeData,
	initSort,
	columns,
	childrenCols,
	factoryConfigPermission,
	onPlantClick,
	...other
}) {
	const [order, setOrder] = useState({
		by: '',
		order: 'desc',
		correspondingChild: 'entityName',
	});
	const [resize, setResize] = useState(null);
	const bodyRef = useRef('');
	const { rxjsState } = useRxjsState();
	const { globeDrawerState } = rxjsState;

	useLayoutEffect(() => {
		setOrder(initSort);
	}, [initSort]);

	const rows = globeData;

	const debouncedResize = function (func) {
		let endTimeout = null;

		return function () {
			clearTimeout(endTimeout);
			endTimeout = setTimeout(() => func(window), 2000);
		};
	};

	const caller = debouncedResize(resizeHandler);

	useEffect(() => {
		const bodyHeight =
			window.innerHeight - bodyRef?.current?.getBoundingClientRect().top - 10;
		setResize(bodyHeight > 0 ? bodyHeight : window.innerHeight);
		window.addEventListener('resize', caller);
		return () => removeEventListener('resize', caller);
	}, []);

	function resizeHandler(window) {
		const { innerHeight } = window;
		if (innerHeight != resize) {
			const bodyHeight =
				window.innerHeight - bodyRef?.current?.getBoundingClientRect().top - 10;
			setResize(bodyHeight > 0 ? bodyHeight : window.innerHeight);
		}
	}

	const handleSortClick = (colName, childCol, defaultGlobeDrawerState) => {
		let globeDrawerState;
		if (order.by === colName) {
			if (order.order === 'asc') {
				if (collapse) {
					globeDrawerState = {
						...defaultGlobeDrawerState,
						mapViewTableInitSortBU: {
							order: 'desc',
							by: order.by,
							correspondingChild: childCol,
						},
					};
				} else {
					globeDrawerState = {
						...defaultGlobeDrawerState,
						mapViewTableInitSort: {
							order: 'desc',
							by: order.by,
						},
					};
				}
				setOrder((prev) => ({ ...prev, order: 'desc' }));
			} else {
				if (collapse) {
					globeDrawerState = {
						...defaultGlobeDrawerState,
						mapViewTableInitSortBU: {
							order: 'asc',
							by: order.by,
							correspondingChild: childCol,
						},
					};
				} else {
					globeDrawerState = {
						...defaultGlobeDrawerState,
						mapViewTableInitSort: {
							order: 'asc',
							by: order.by,
						},
					};
				}
				setOrder((prev) => ({ ...prev, order: 'asc' }));
			}
		} else {
			if (collapse) {
				globeDrawerState = {
					...defaultGlobeDrawerState,
					mapViewTableInitSortBU: {
						order: 'asc',
						by: colName,
						correspondingChild: childCol,
					},
				};
				setOrder({ by: colName, order: 'asc', correspondingChild: childCol });
			} else {
				globeDrawerState = {
					...defaultGlobeDrawerState,
					mapViewTableInitSort: {
						order: 'asc',
						by: colName,
					},
				};
			}
			setOrder((prev) => ({ ...prev, by: colName, order: 'asc' }));
		}
		updateRxjsState({ globeDrawerState });
	};

	const sortedArray = useMemo(() => {
		if (collapse) {
			return handleSort(order?.by, order?.order, rows, 'businessUnit');
		} else return handleSort(order?.by, order?.order, rows, 'entityName');
	}, [order.by, order.order, rows, collapse, globeData]);

	return (
		<Paper {...other}>
			<StyledTableContainer sx={{ overflowY: collapse && 'hidden' }}>
				<Table stickyHeader aria-label='sticky table' data-testid='GlobeTable'>
					<StyledTableHead>
						<TableRow data-testid='GlobeTable-HeaderRow'>
							{columns.map((column) => (
								<TableCell
									data-testid='GlobeTable-headerCell'
									sx={{
										[`&:hover .changeVisibility${column.id.key}`]: {
											visibility: 'visible',
										},
										'&:hover': {
											backgroundColor: column.label.isSortReq && '#2D3338',
										},
										width: column.id?.width || 'auto',
									}}
									key={column.id.key}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<span
											style={{
												whiteSpace: 'nowrap',
												fontWeight: column.label?.embellishment === 'bold' && 'bold',
											}}
										>
											{column.label?.name}
										</span>
										{column.label?.isSortReq && (
											<IconButton
												className={`changeVisibility${column.id.key}`}
												data-testid='sort-icon'
												onClick={() =>
													handleSortClick(
														column.id.key,
														column.id?.correspondingChild,
														globeDrawerState
													)
												}
												sx={{
													visibility: column?.id?.key !== order.by && 'hidden',
												}}
											>
												{column.id.key === order.by ? (
													<img
														src={order.order === 'asc' ? sortingImgAsc : sortingImgDesc}
														alt=''
													/>
												) : (
													<img src={sortingImgDesc} alt='' />
												)}
											</IconButton>
										)}
									</Box>
								</TableCell>
							))}
						</TableRow>
					</StyledTableHead>
					<TableBody ref={bodyRef}>
						{collapse === false ? (
							sortedArray.map((row) => (
								<TableRow
									data-testid='GlobeTable-bodyRow'
									sx={(theme) => ({ background: theme.palette.background.blackGrey })}
									key={`${row.entityName}`}
								>
									{columns.map((column) => (
										<TableCell
											data-testid='GlobeTable-bodyCell'
											sx={{
												fontSize: '.8rem',
												height: '3rem',
												padding: '.5rem 1rem',
											}}
											key={column.label.name}
										>
											{getTableCellData(
												column.id,
												row[column.id.key],
												row,
												column,
												factoryConfigPermission,
												onPlantClick
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableCell
								colSpan={columns.length}
								sx={{ padding: 0 }}
								data-testid='GlobeTable-BU-body'
							>
								<StyledTableContainer sx={{ maxHeight: 'calc(100vh - 18.5rem)' }}>
									{sortedArray.map((row, index) => (
										<CollapsibleRow
											row={row}
											column={columns}
											childEntities={row[childEntities]}
											expand={expand}
											rowIndex={index}
											childrenCols={childrenCols}
											onPlantClick={onPlantClick}
											factoryConfigPermission={factoryConfigPermission}
											sortOrder={order}
											key={`row${index}`}
											bodyHeight={resize}
										/>
									))}
								</StyledTableContainer>
							</TableCell>
						)}
					</TableBody>
				</Table>
			</StyledTableContainer>
		</Paper>
	);
}

GlobeTableComponent.propTypes = {
	childEntities: PropTypes.string,
	collapse: PropTypes.bool,
	expand: PropTypes.object,
	factoryConfigPermission: PropTypes.bool,
	onPlantClick: PropTypes.func,
	globeData: PropTypes.array,
	childrenCols: PropTypes.array,
	initSort: PropTypes.object,
	columns: PropTypes.array,
};
