import { config } from '@smf/ui-util-app';

export function APQWebSocket(sessionToken) {
	const WSS_ENDPOINT_APQ_DATA = `wss://${config.BASE_WS_API_URL}/globe`;
	return new Promise((resolve, reject) => {
		const ws = new WebSocket(WSS_ENDPOINT_APQ_DATA, sessionToken?.replace('Bearer ', ''));
		ws.onopen = () => {
			ws.send(
				JSON.stringify({
					action: 'plantsAPQandLossEarnings',
					// eslint-disable-next-line no-underscore-dangle
					authorizationToken: sessionToken,
				})
			);
		};
		ws.onmessage = ({ data }) => {
			const recvData = JSON.parse(data);
			ws.close();
			resolve(recvData);
		};
		ws.onerror = (err) => {
			ws.close();
			reject(err);
		};
	});
}

export default {};
