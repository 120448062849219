import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useFormContext } from 'react-hook-form';

import Input from '../../../../../../components/Input';
import Select from '../../../../../../components/Select';
import EditPlantFooter from './components/EditPlantFooter';
import LoadingIndicator from '../../../../../../components/LoadingIndicator';

import { Texts } from '../../../../constants';
import * as S from './style';
import { getPlantData, queryConstants } from '../../../../../../utils/apiHelper';
import { populateForm } from './helpers/populateForm';
import { TOAST_REDUCER_CONSTANTS } from '../../../../../../constants';
import UploadFiles from '../../../CreatePlantStep/components/UploadFiles';
import { useToastContext } from '../../../../../../context/toastContext';

const EditPlantForm = ({
	allBusinessUnits,
	statesList,
	isCreatePlantMutationLoading,
	isUpdatePlantMutationLoading,
	onClose,
}) => {
	const {
		watch,
		register,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useFormContext();
	const { toastDispatch } = useToastContext();
	const selectedPlantId = watch('metadata.selectedPlant');

	const plantErrors = errors?.plant;

	const plantNameError = plantErrors?.plantName?.message;
	const streetError = plantErrors?.street?.message;
	const cityError = plantErrors?.city?.message;
	const zipCodeError = plantErrors?.zipCode?.message;
	const businessUnitError = plantErrors?.businessUnitName?.message;
	const stateCodeError = plantErrors?.stateCode?.message;
	const fileError = plantErrors?.file?.message;

	useEffect(() => {
		// Don't run this on mount/when the user is navigating to create a new plant
		if (selectedPlantId) {
			refetchPlantById();
		}
	}, [selectedPlantId]);

	const { isFetching: isGetPlantDataFetching, refetch: refetchPlantById } = useQuery(
		[queryConstants.GET_PLANT_DATA],
		async () => {
			const result = await getPlantData(watch('metadata.selectedPlant'));

			return result;
		},
		{
			// Same as above
			enabled: !!selectedPlantId,
			refetchOnWindowFocus: false,
			onError: () => {
				toastDispatch({
					type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
				});
			},
			onSuccess: (fetchedPlantData) => {
				populateForm({ fetchedPlantData, setValue, statesList, allBusinessUnits });
				clearErrors();
			},
		}
	);

	const handleBusinessUnitChange = (selectedBusinessUnitName) => {
		const businessUnitId = allBusinessUnits.find(
			({ businessUnitName }) => businessUnitName === selectedBusinessUnitName
		)?.businessUnitId;

		setValue('plant.businessUnitId', businessUnitId);
		setValue('plant.businessUnitName', selectedBusinessUnitName);
		setValue('metadata.selectedBusinessUnitName', selectedBusinessUnitName);
		clearErrors('plant.businessUnitName');
	};

	const handleStateChange = (selectedState) => {
		const selectedStateCode = statesList.find(
			({ stateName }) => selectedState === stateName
		)?.stateCode;

		setValue('plant.stateCode', selectedStateCode);
		setValue('metadata.selectedStateName', selectedState);
		clearErrors('plant.stateCode');
	};

	const secondaryButtonOnClick = () => {
		onClose();
	};

	const isLoading =
		isGetPlantDataFetching ||
		!statesList?.length ||
		!allBusinessUnits?.length ||
		isCreatePlantMutationLoading ||
		isUpdatePlantMutationLoading;

	const isUpdateMode = watch('metadata.isUpdateMode');

	return (
		<S.EditPlantContainer>
			<span>
				{isLoading ? (
					<LoadingIndicator />
				) : (
					<div>
						<S.EditPlantTitle>
							{isUpdateMode ? Texts.EDIT_PLANT : Texts.CREATE_PLANT}
						</S.EditPlantTitle>
						<S.FlexContainerPlantName>
							<S.EditPlantInputContainer>
								<Input
									name='plant.plantName'
									label={Texts.ENTER_PLANT_NAME}
									placeholder={Texts.ENTER_PLANT_NAME}
									error={plantNameError}
									{...{ register }}
								/>
							</S.EditPlantInputContainer>
							<S.EditPlantInputContainer>
								<Select
									label={Texts.ENTER_BUSINESS_UNIT}
									placeholder={Texts.SELECT_BUSINESS_UNIT}
									options={allBusinessUnits?.map(
										({ businessUnitName }) => businessUnitName
									)}
									currentValue={watch('metadata.selectedBusinessUnitName')}
									onChange={handleBusinessUnitChange}
									error={businessUnitError}
								/>
							</S.EditPlantInputContainer>
						</S.FlexContainerPlantName>
						<S.FlexContainerStreet>
							<S.EditPlantInputContainer>
								<Input
									name='plant.street'
									placeholder={Texts.ENTER_STREET}
									label={Texts.STREET}
									error={streetError}
									width='31.875rem'
									{...{ register }}
								/>
							</S.EditPlantInputContainer>
						</S.FlexContainerStreet>
						<S.FlexContainerCity>
							<S.EditPlantInputContainer>
								<Input
									name='plant.city'
									placeholder={Texts.ENTER_CITY}
									label={Texts.CITY}
									error={cityError}
									{...{ register }}
								/>
							</S.EditPlantInputContainer>
							<S.EditPlantInputContainer>
								<Select
									label={Texts.STATE}
									placeholder={Texts.SELECT_STATE}
									options={statesList?.map(({ stateName }) => stateName)}
									currentValue={watch('metadata.selectedStateName')}
									onChange={handleStateChange}
									error={stateCodeError}
								/>
							</S.EditPlantInputContainer>
							<span>
								<Input
									name='plant.zipCode'
									placeholder={Texts.ENTER_ZIP_CODE}
									label={Texts.ZIP_CODE}
									error={zipCodeError}
									{...{ register }}
								/>
							</span>
						</S.FlexContainerCity>
						<div>
							<UploadFiles
								name='plant.file'
								error={fileError}
								register={register}
								setValue={setValue}
								watch={watch}
								onRemove={() => {
									setValue('metadata.plantImage.isUserClickOnRemoveOnce', true);
								}}
							/>
						</div>
					</div>
				)}
			</span>

			<EditPlantFooter {...{ secondaryButtonOnClick }} />
		</S.EditPlantContainer>
	);
};

export default EditPlantForm;
