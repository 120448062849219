import React from 'react';
import Button from '../../../../../../../../components/Button';

import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Texts } from '../../../../../../constants';
import * as S from './style';

const EditPlantFooter = ({ secondaryButtonOnClick }) => {
	const { watch } = useFormContext();
	const plantValues = watch('plant');
	const plantSnapshot = watch('plantSnapshot');
	const isUpdateValuesNotModified = _.isEqual(plantValues, plantSnapshot);

	return (
		<S.EditPlantFooter>
			<span>
				<Button
					text={Texts.CANCEL}
					onClick={secondaryButtonOnClick}
					buttonType='secondary'
					data-testid='dialog-action--cancel'
				/>

				<Button
					text={Texts.SAVE}
					data-testid='dialog-action--save'
					type='submit'
					disable={isUpdateValuesNotModified}
				/>
			</span>
		</S.EditPlantFooter>
	);
};

export default EditPlantFooter;
