import { styled } from '@mui/material';

/*export const SideBarContainer = styled('div')(({ isNoPlantSelected }) => ({
	display: 'flex',
	flexDirection: 'column',

	'&>div:nth-child(2)': {
		borderRight: isNoPlantSelected && 'none',
	},
}));*/

export const SideBarContainer = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isNoPlantSelected',
})(({ theme, isNoPlantSelected }) => ({
	display: 'flex',
	flexDirection: 'column',
	...(isNoPlantSelected && {
		'&>div:type-of(2)': {
			borderRight: isNoPlantSelected && 'none',
		},
	}),
}));

export const SideBarSearchField = styled('div')(({}) => ({
	width: '15.62rem',
	minHeight: '4.69rem',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',

	'>div': {
		width: '14.31rem',
	}
}));

export const SideBarScrollable = styled('div')(({}) => ({
	overflowY: 'auto',
	overflowX: 'hidden',
}));
