import { initialHeaderStepsData } from '../constants';
import { FormSteps } from '../constants';

export const setHeaderStep =
	({ dialogPropsData, setDialogProps, setHeaderStepsData }) =>
	(formStepName) => {
		const activeNotCompleted = { isActive: true, isCompleted: false };
		const completedNotActive = { isCompleted: true, isActive: false };

		const mapper = {
			[FormSteps.CREATE_COMPANY]: () => {
				setDialogProps(dialogPropsData.createCompanyStep);
				setHeaderStepsData(initialHeaderStepsData);
			},
			[FormSteps.CREATE_BUSINESS_UNIT]: () => {
				setDialogProps(dialogPropsData.createBusinessUnitStep);
				setHeaderStepsData((prevSteps) => {
					const [prevFirstStep, prevSecondStep, prevThirdStep] = prevSteps;
					const firstStepDone = { ...prevFirstStep, ...completedNotActive };
					const secondStepActive = { ...prevSecondStep, ...activeNotCompleted };
					const [, , thirdStepInitial] = initialHeaderStepsData;

					return [firstStepDone, secondStepActive, thirdStepInitial];
				});
			},
			[FormSteps.CREATE_PLANT]: () => {
				setDialogProps(dialogPropsData.createPlantStep);
				setHeaderStepsData((prevSteps) => {
					const [prevFirstStep, prevSecondStep, prevThirdStep] = prevSteps;
					const secondStepDone = { ...prevSecondStep, ...completedNotActive };
					const thirdStepActive = { ...prevThirdStep, ...activeNotCompleted };

					return [prevFirstStep, secondStepDone, thirdStepActive];
				});
			},
		};

		return mapper[formStepName]();
	};

export const isNextStepButtonDisabled = ({
	dirtyFields,
	errors,
	currentStep,
	formValues,
}) => {
	const NUMBER_OF_PLANT_FIELDS_EXCLUDING_FILES = 6;

	const isPrimaryButtonAvailableMapper = {
		[FormSteps.CREATE_COMPANY]:
			// Uncomment or remove when requirements are finalized
			// !!dirtyFields?.createCompanyStep && !errors?.createCompanyStep,
			true,
		[FormSteps.CREATE_BUSINESS_UNIT]:
			// Same as above
			// !!dirtyFields?.createBusinessUnitStep &&
			// !errors?.createBusinessUnitStep &&
			// !Object.values(formValues.createBusinessUnitStep).includes(''),
			true,
		[FormSteps.CREATE_PLANT]:
			// !!dirtyFields?.createPlantStep &&
			// !errors?.createPlantStep &&
			// Object.keys(dirtyFields.createPlantStep).length >=
			// 	NUMBER_OF_PLANT_FIELDS_EXCLUDING_FILES,
			// Same as above
			true,
	};

	return !isPrimaryButtonAvailableMapper[currentStep] ?? false;
};
