export const parseAddEditPlantFormToBackendFormat = (formValues, companyId) => {
	const { plant, metadata } = formValues;
	const file = plant?.file?.[0];

	const { isFileExistsOnFetch, isUserClickOnRemoveOnce } = metadata.plantImage;
	const isFileExistInForm = !!file;

	const isUpdateMode = formValues.metadata.isUpdateMode;
	const isCreateMode = !isUpdateMode;

	// Did a user add a file to update a plant that had no file originally
	const isFileCreated = !isFileExistsOnFetch && isFileExistInForm;
	//
	const isImageUpdated =
		(isFileExistsOnFetch && isUserClickOnRemoveOnce && isFileExistInForm) ||
		(isFileCreated && isUpdateMode);
	const isImageDeleted = isFileExistsOnFetch && !isFileExistInForm;

	const updateModeFileNotUpdated =
		isUpdateMode && isFileExistsOnFetch && !isUserClickOnRemoveOnce;

	const plantImage = file
		? {
				plantImage: {
					fileName: file.name,
					fileType: file.type.split('/')[1],
					fileSize: file.size,
				},
		  }
		: {};

	const {
		plantId,
		businessUnitId,
		city,
		createdAt,
		entityType,
		plantName,
		stateCode,
		street,
		zipCode,
		imgSrcPath,
	} = plant;

	const payload = {
		companyId,
		plantId: isUpdateMode ? plantId : null,
		businessUnitId,
		city,
		createdAt: isCreateMode ? new Date().toISOString() : createdAt || null,
		updatedAt: new Date().toISOString(),
		entityType: entityType || 'Plant',
		plantName,
		stateCode,
		street,
		zipCode,
		isImageUpdated,
		isImageDeleted,
		imgSrcPath: imgSrcPath || '',

		...plantImage,
	};

	// Edge case to avoid updating a synthetic file object (populateForm.js) which has no buffer
	if (updateModeFileNotUpdated) {
		payload.isImageUpdated = false;
		payload.plantImage = null;
	}

	return payload;
};
