import React from 'react';
import Step from '../../../../components/Step';
import * as S from './style';

const HeaderSteps = ({ headerStepsData }) => {
	return (
		<S.HeaderStep>
			{headerStepsData.map(({ label, stepNumber, isCompleted, isActive }) => {
				return (
					<S.HeaderStepContainer key={label}>
						<Step {...{ label, stepNumber, isCompleted, isActive }} />
					</S.HeaderStepContainer>
				);
			})}
		</S.HeaderStep>
	);
};

export default HeaderSteps;
