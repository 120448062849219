import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

import successGreen from '../../assets/img/successGreen.svg';
import successGrey from '../../assets/img/successGrey.svg';

/**
 * @author Dima Borodin
 *
 * @param label Required - string
 * @param isActive Required - boolean: Highlights the component and draws an appropriate indication element
 * @param isCompleted Required - boolean: Changes the icon status to complete and returns to default state
 *
 * @returns A controlled Step/Tab display-only component
 */

const Step = ({
	label,
	isActive,
	isCompleted,
	stepNumber,
	height,
	width,
	dataTestId,
	...rest
}) => {
	const iconSrc = isCompleted ? successGreen : successGrey;

	return (
		<S.ViewContainer
			data-testid={`step-container--${dataTestId}`}
			aria-role={`step-navigation--indicator`}
			aria-readonly='true'
			{...rest}
		>
			<S.ContentContainer isCompleted={isCompleted} {...{ isActive }}>
				<S.FlexContainer>
					<S.TextContainer>
						{stepNumber && <S.StepNumber>{stepNumber}</S.StepNumber>}
						<S.TextView>{label}</S.TextView>
					</S.TextContainer>
					<S.StepIcon alt='icon-success' src={iconSrc} />
				</S.FlexContainer>
			</S.ContentContainer>
			<S.StepUnderline {...{ isActive }}>
				<S.StepUnderlineCompleted
					data-completed={isCompleted}
					aria-hidden={!isCompleted}
					aria-role='completed-line--graphic'
					{...{ isActive }}
				/>
			</S.StepUnderline>
		</S.ViewContainer>
	);
};

Step.propTypes = {
	label: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	isCompleted: PropTypes.bool.isRequired,
	stepNumber: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
	dataTestId: PropTypes.string,
};

export default Step;
