import Dialog from '../../components/Dialog';
import { styled } from '@mui/material';

export const DialogContent = styled(Dialog)(() => ({
	'& .MuiDialogContent-root': {
		padding: '1.5rem',
		'>section': {
			paddingTop: '1.25rem'
		},
	}
}));
