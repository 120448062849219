import { Backdrop, Box, styled } from '@mui/material';

export const BackDrop = styled(Backdrop)(({ theme }) => ({
	zIndex: theme.zIndex.drawer - 1,
}));

export const DangerText = styled(Box)(({ theme }) => ({
	color: theme.palette.background.default,
	textAlign: 'center',
}));
