import MESSAGE_STRINGS from './en-us';

export const TAB_ITEMS = ['Actual', 'Target', 'Deviation Threshold'];

export const TIME_FORMATS = {
	SECONDS: ['sec', 'secs'],
	MINUTES: ['min', 'mins', 'minute', 'minutes'],
	HOURS: ['hour', 'hours'],
	DAYS: ['day', 'days'],
};

export const MSG_TYPES = {
	ERROR: 'error',
	WARNING: 'warning',
	SUCCESS: 'success',
	CONFIRM: 'confirm',
	INFO: 'info',
};

export const MSG_TITLE = {
	ERROR: 'Error!',
	WARNING: 'Warning!',
	SUCCESS: 'Success',
	CONFIRM: 'Save Changes!',
};

export const DUPLICATE_ERROR_MASSAGE =
	'Business Unit already exists. Value must be unique.';

export const FINAL_STEP = 6;
export const MSG_BODY = {
	ERROR:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
	WARNING:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
	SUCCESS:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
	CONFIRM:
		'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
};

export const ACTUALS = [{ label: 'Parameters' }, { label: 'Value' }, { label: 'UoM' }];
export const AccordionHeader = {
	actuals: 'Actuals',
	deviationThreshold: 'Deviation Threshold',
};

export const DEVIATIONS = [
	{ label: 'Value' },
	{ label: 'Direction' },
	{ label: 'Type' },
	{ label: 'Lower Bound' },
	{ label: 'Upper Bound' },
];

export const FACTORYHEALTH = [
	{ value: 'THROUGHPUT', label: 'Throughput' },
	{ value: 'WIP', label: 'WIP' },
];

export const FACTORYHEALTH_CONSTANTS = {
	targetThroughput: 'Target Throughput',
	maxDemonstratedCapacity: 'Max Demonstrated Throughput Capacity',
	maxTheoreticalCapacity: 'Max Theoretical Throughput Capacity',
};

export const THROUGHTPUT = 'Throughput';
export const WIP = 'WIP';
export const OEE = 'OEE';
export const AVAILABILITY = 'Availability';
export const PERFORMANCE = 'Performance';
export const QUALITY = 'Quality';

export const ERROR_MSG = 'Please enter whole numbers only';

export const THROUGHPUT_TARGET = [
	{ value: 'plants', label: 'Plants' },
	{ value: 'lines', label: 'Lines' },
];
export const TARGET_LABELS = {
	PLANTS: 'Plants',
	LINES: 'Lines',
	CELLS: 'Cells',
	ASSETS: 'Assets',
};
export const TARGET_PLANT = [{ label: 'Plant' }, { label: 'Value' }, { label: 'UOM' }];
export const TARGET_LINE = [{ label: 'Lines' }, { label: 'Value' }, { label: 'UOM' }];

// WiP Targets
export const TABLE_TYPE = {
	PLANTS: 'plants',
	LINES: 'lines',
	CELLS: 'cells',
	ASSETS: 'assets',
};

export const ENTITY_TYPE = {
	PLANT: 'Plant',
	SEPERATOR: '|',
	LINE: 'Line',
	CELL: 'Cell',
	ASSET: 'Asset',
};

export const ENTITY_PARAMETER_LABELS = {
	INTRA_CELL: 'Intracell',
};

export const ENTITY_MAP = {
	[TABLE_TYPE.PLANTS]: ENTITY_TYPE.PLANT,
	[TABLE_TYPE.LINES]: ENTITY_TYPE.LINE,
	[TABLE_TYPE.CELLS]: ENTITY_TYPE.CELL,
	[TABLE_TYPE.ASSETS]: ENTITY_TYPE.ASSET,
};

export const TARGETS = [{ header: 'Targets' }];

export const LOGO_TEXT = 'Smart Factory Fabric';

export const FACTORY_HEALTH_TYPES = {
	ACTUALS: 'actuals',
	TARGETS: 'targets',
	DEVIATIONS: 'deviations',
};

export const THRESHOLD_TYPES_UPPER = {
	LESS_THAN_OR_EQUAL_TO: 'less than or equal',
	LESS_THAN: 'less than',
	EQUAL_TO: 'equal to',
	NOT_EQUAL_TO: 'NOT_EQUAL_TO',
};

export const THRESHOLD_TYPES_LOWER = {
	GREATER_THAN_OR_EQUAL_TO: 'greater than or equal to',
	GREATER_THAN: 'greater than',
};

export const MAX_INPUT_SIZE = 5;

export const TOAST_TYPE = {
	SUCCESS: 'SUCCESS',
	ERROR: 'ERROR',
	WARNING: 'WARNING',
	INFO: 'INFO',
};

export const SIDEBAR_SCREENS = {
	GLOBE: 'Global Home',
	PERFORMANCE_MANAGEMENT: 'Perfromance Management',
	ORDER_MANAGEMENT: 'Order Management',
	CONDITION_MONITORING: 'Condition Monitoring',
	GENERAL_CONFIGURATION: 'General Configuration',
	ACCESS_CONFIGURATION: 'Access Configuration',
	CONSTRAINT_MANAGEMENT: 'Constraint Management',
};

export const PROFILE_DROPDOWN_OPTIONS = ['Profile', 'Logout'];

export const HEADER_TABS = {
	HELP: 'Help',
	NOTIFICATIONS: 'Notifications',
	PROFILE: 'Profile',
};

export const SCREENS = {
	GENERAL_CONFIGURATION: 'General',
	FACTORY_HEALTH: 'Factory Health',
	SCHEDULE_PLANNING: 'Schedule Planning',
	OEE: 'OEE',
	CONDITION_MONITORING: 'Condition Based Monitoring',
	MANUFACTURING_EQUIPMENT: 'Manufacturing Equipment',
	CONSTRAINT_MANAGEMENT: 'Constraint Management',
	ORDER_MANAGEMENT: 'Order Management',
	NOTIFICATION_CONFIGURATION: 'Notifications',
};

export const GENERAL_CONFIGURATION_STEPS = {
	SCHEDULE: 'Schedule',
	CREWS: 'Crews',
	ROTATION: 'Rotation',
	WEEKLY_ROTATION: 'Weekly & Daily Rotation',
	ASSET_INTELLIGENCE: 'Asset Intelligence Parameters',
	PLANT_FINANCIAL_CALCULATIONS: 'Plant Financial Calculations',
	CONFIG_DONE: 'Config Done',
};

export const MIN_VAL_REFRESHRATE = 1;

export const ACTUALS_LABEL = { TIME_RANGE: 'Time Range', REFRESH_RATE: 'Refresh Rate' };

export const TARGETS_REDUCER_CONSTANTS = {
	CHANGE_TAB: 'CHANGE_TAB',
	SET_PLANT_MAPPINGS: 'SET_PLANT_MAPPINGS',
	SET_LINE_MAPPINGS: 'SET_LINE_MAPPINGS',
	SET_CELL_MAPPINGS: 'SET_CELL_MAPPINGS',
	SET_ASSET_MAPPINGS: 'SET_ASSET_MAPPINGS',
	SET_ROWS: 'SET_ROWS',
	UPDATE_ROWS: 'UPDATE_ROWS',
	SET_TABLE_READY_STATUS: 'SET_TABLE_READY_STATUS',
	UPDATE_ROW_ERROR: 'UPDATE_ROW_ERROR',
	SAVE_SUCCESS: 'SAVE_SUCCESS',
	SET_PLANT_ID: 'SET_PLANT_ID',
	SET_LINE_ID: 'SET_LINE_ID',
	SET_CELL_ID: 'SET_CELL_ID',
	SET_SEARCH_TERM: 'SET_SEARCH_TERM',
};

export const PREVENT_CONFIRM_MODAL_REDUCER_CONSTANTS = {
	ACTIVATE_MODAL: 'ACTIVATE_MODAL',
	DEACTIVATE_MODAL: 'DEACTIVATE_MODAL',
	OPEN_MODAL: 'OPEN_MODAL',
	CLOSE_MODAL: 'CLOSE_MODAL',
};

export const ROTATIONS_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	SET_CREWS: 'SET_CREWS',
	UPDATE_ROWS: 'UPDATE_ROWS',
	UPDATE_ROW_ERROR: 'UPDATE_ROW_ERROR',
	OPEN_CONFIRM_DELETE_MODAL: 'OPEN_CONFIRM_DELETE_MODAL',
	CLOSE_CONFIRM_DELETE_MODAL: 'CLOSE_CONFIRM_DELETE_MODAL',
	OPEN_CONFIRM_ADD_MODAL: 'OPEN_CONFIRM_ADD_MODAL',
	CLOSE_CONFIRM_ADD_MODAL: 'CLOSE_CONFIRM_ADD_MODAL',
};

export const CONFIG_HEADINGS = {
	CONFIG: 'Configuration',
	FACTORY_CONFIG: 'Factory Configuration',
};

export const CONFIG_CONTEXT = {
	SELECT_PLANT: 'SELECT_PLANT',
	STEPPER_CURRENT_STATE: 'STEPPER_CURRENT_STATE',
	STORE_CONSTANTS_API: 'STORE_CONSTANT_API',
};

export const D2D_CONTEXT = {
	MENU_STATE: 'MENU_STATE',
	HOME_STATE: 'HOME_STATE',
};

export const BUTTON_TYPE = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	SECONDARY_NARROW: 'secondaryNarrow',
};

export const ROTATIONS_CONSTANTS = {
	ACCESSORS: {
		SHIFT_NAME: 'shiftName',
		PRODUCTION_DAY_1: 'production_Day_1',
		PRODUCTION_DAY_2: 'production_Day_2',
		PRODUCTION_DAY_3: 'production_Day_3',
		PRODUCTION_DAY_4: 'production_Day_4',
		PRODUCTION_DAY_5: 'production_Day_5',
		PRODUCTION_DAY_6: 'production_Day_6',
		PRODUCTION_DAY_7: 'production_Day_7',
	},
	NONE_TYPE: { crewName: 'None', crewId: 'None' },
	SELECT_CREW: { crewName: 'Select Crew', crewId: 'sc' },
};

export const SCHEDULE = {
	START_TIME: 'Start Time',
	END_TIME: 'End Time',
	PRODUCTION_DAY: 'Production Day',
	SHIFT: 'Shift',
	DEFAULT_MIN_TIME: '00:00',
	TIME_FORMAT: 'HH:mm',
	SAME_DAY: 'SAME_DAY',
	NEXT_DAY: 'NEXT_DAY',
	SHIFT_NAME: 'SHIFT_NAME',
	SHIFT_TYPE: 'shiftType',
};

export const ENDS_ON = {
	SAME_DAY: 'sameDay',
	OVERLAPPING: 'overlapping',
	NEXT_DAY: 'nextDay',
};

export const ROTATION_TYPES = {
	WEEKLY_ROTATION: 'Weekly Rotation',
	DAILY_ROTATION: 'Daily Rotation',
};

export const CREWS_CONFIGURATION_REDUCER_CONSTANTS = {
	SET_CREWS: 'SET_CREWS',
	UPDATE_CREWS: 'UPDATE_CREWS',
	ADD_CREW: 'ADD_CREW',
	DELETE_CREW: 'DELETE_CREW',
	OPEN_CONFIRM_DELETE_MODAL: 'OPEN_CONFIRM_DELETE_MODAL',
	CLOSE_CONFIRM_DELETE_MODAL: 'CLOSE_CONFIRM_DELETE_MODAL',
	SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
};

export const CREWS_CONFIGURATION_CONSTANTS = {
	ACCESSORS: {
		CREW_NAME: 'crewName',
		IGNORE: 'ignore',
	},
};

export const ROLE_CONFIGURATION_CONSTANTS = {
	MANDATORY_FIELDS: 'Mandatory Fields',
	ASTERIX: '*',
	GLOBE: 'Globe',
	OPTIONS: 'Options',
	GLOBAL: 'Global',
	ADD_ROLE: 'Add Role',
	ERROR_MESSAGE_DROPDOWN: 'Please select an option',
	BUTTON_NAME: ['Save', 'Reset', 'Update', 'Cancel', 'Delete'],
	HEADER_TITLES: ['Edit Role', 'Create Role'],
	TEXT_BOX_CONSTANTS: [
		{
			GRID_SIZE: 4,
			LABEL: 'Role Name',
			TYPE: 'string',
			ID: 'roleName',
			REQUIRED: true,
			// NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
			// eslint-disable-next-line no-useless-escape
			PATTERN: /^[A-Z @~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i,
			MESSAGE_EMPTY: 'Please enter the role name',
			MESSAGE_INVALID: 'Invalid role name !',
			PLACEHOLDER: 'Enter Role Name',
		},
		{
			GRID_SIZE: 4,
			LABEL: 'Business Function',
			TYPE: 'string',
			ID: 'businessFunction',
			REQUIRED: true,
			PATTERN: null,
			MESSAGE_EMPTY: 'Please enter the business function',
			MESSAGE_INVALID: 'Invalid fusiness function !',
			PLACEHOLDER: 'Select Business Function',
		},
		{
			GRID_SIZE: 12,
			LABEL: 'Description',
			TYPE: 'string',
			ID: 'description',
			REQUIRED: false,
			// NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
			// eslint-disable-next-line no-useless-escape
			PATTERN: /[^]*/i,
			MESSAGE_EMPTY: 'Please enter the description',
			MESSAGE_INVALID: 'Invalid description !',
			PLACEHOLDER: 'Enter description here (Max char 250)',
		},
	],
	TABLE_HEADER_CONSTANTS: ['Level', 'Business Unit/Plant', 'Permissions', 'Landing Page'],
	TABLE_HEADER_KEYS: ['level', 'businessUnit', 'permission', 'landingPage'],
	TEXT_FIELD_CONSTANTS: ['Role Name', 'Business Function', 'Description'],
	TABLE_HEADER_PLACEHOLDERS: [
		'Select Level',
		'Select Business Unit/Plant',
		'Select Permissions',
		'Select Landing Page',
		'Select Business Function',
	],
	LOADER_CAPTIONS: ['Searching Role', 'Creating Role', 'Updating Role', 'Deleting Role'],
};
export const USER_CONFIGURATION_CONSTANTS = {
	DELETE_BUTTON_LABLES: {
		YES: 'Yes',
		NO: 'No',
	},
	MANDATORY_FIELDS: 'Mandatory Fields',
	ASTERIX: '*',
	MAIN_HEADING: 'User Access Configuration',
	ADD_ROLE: 'Add Role',
	ROW_ID: 'rowID',
	HEADER_TITLES: ['Edit User', 'Add User'],
	BUTTON_NAME: ['Save', 'Reset', 'Update', 'Cancel', 'Delete', 'Apply', 'No', 'Yes'],
	TAB_ITEMS: [
		{
			label: 'Role Configuration',
			value: 0,
			disabled: false,
		},
		{
			label: 'User Configuration',
			value: 1,
			disabled: false,
		},
	],
	TABLE_HEADER_CONSTANTS: [
		'Business Unit/Plant',
		'Role',
		'Role Assignment(Start Date)',
		'Role Assignment(End Date)',
		'Business Function',
		'Permission',
		'Landing Page',
		'Default Landing Page',
	],
	TABLE_HEADER_KEYS: [
		'plant',
		'role',
		'startDate',
		'endDate',
		'function',
		'permission',
		'landingPage',
		'default',
	],
	TABLE_HEADER_PLACEHOLDERS: ['Select Business Unit/Plant', 'Select Role'],
	ROLE_LIST: 'roleList',
	ROLE_ID: 'roleID',
	TEXT_FIELD_CONSTANTS: [
		'First Name',
		'Last Name',
		'User Access Start Date',
		'User Access End Date',
		'Email ID',
	],
	TEXT_BOX_CONSTANTS: [
		{
			GRID_SIZE: 4,
			LABEL: 'First Name',
			TYPE: 'string',
			ID: 'firstName',
			REQUIRED: true,
			PATTERN: /^([a-zA-Z])+$/i,
			MESSAGE_EMPTY: 'Please enter the first name',
			MESSAGE_INVALID: 'Invalid first name !',
			PLACEHOLDER: 'Enter First Name',
		},
		{
			GRID_SIZE: 6,
			LABEL: 'Last Name',
			TYPE: 'string',
			ID: 'lastName',
			REQUIRED: true,
			PATTERN: /^([a-zA-Z])+$/i,
			MESSAGE_EMPTY: 'Please enter the last name',
			MESSAGE_INVALID: 'Invalid last name !',
			PLACEHOLDER: 'Enter Last Name',
		},
		{
			GRID_SIZE: 4,
			LABEL: 'User Access Start Date',
			TYPE: 'date',
			ID: 'userAccessStartDate',
			REQUIRED: true,
			// NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
			// eslint-disable-next-line no-useless-escape
			PATTERN: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/i,
			MESSAGE_EMPTY: 'Please enter the start date',
			MESSAGE_INVALID: 'Invalid start date !',
		},
		{
			GRID_SIZE: 6,
			LABEL: 'User Access End Date',
			TYPE: 'date',
			ID: 'userAccessEndDate',
			REQUIRED: true,
			// NOTE: eslint thinks there is the \ in regex is an escape character but it isn't.
			// eslint-disable-next-line no-useless-escape
			PATTERN: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/i,
			MESSAGE_EMPTY: 'Please enter the end date',
			MESSAGE_INVALID: 'Invalid Date, end date lesser than start date!',
		},
		{
			GRID_SIZE: 4,
			LABEL: 'Email ID',
			TYPE: 'email',
			ID: 'emailID',
			REQUIRED: true,
			PATTERN: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
			MESSAGE_EMPTY: 'Please enter the email id',
			MESSAGE_INVALID: 'Invalid email id !',
			PLACEHOLDER: 'Enter Email Id',
		},
	],
	LOADER_CAPTIONS: ['Searching User', 'Creating User', 'Updating User', 'Deleting User'],
};

export const PERFORMANCE_MANAGEMENT_CONSTANTS = {
	NO_SHIFT: 'Current shift details are not available for ',
	INTRA_CELL: 'Intracell',
	TABLE_LABEL: 'collapsible table',
	FILTER_LABEL: 'Filter Selection',
	ENTITY_NAME: 'entityName',
	ACTUAL_THROUGHPUT: 'actualThroughput',
	ACTUAL_WIP: 'actualWIP',
	ACTUAL_OEE: 'actualOEE',
	ACTUAL_AVAILABILITY: 'actualAvailability',
	ACTUAL_PERFORMANCE: 'actualPerformance',
	ACTUAL_QUALITY: 'actualQuality',
	LINES: 'Lines',
	PLANT: 'Plant',
	PLANTS: 'Plants',
	WIP: 'WIP',
	THROUGHPUT: 'Throughput',
	EQUIPMENT_HIERARCHY: 'Equipment Hierarchy',
	UNITS: 'UNITS',
	PERFORMANCE_MANAGEMENT: 'Performance Management',
	HEADER_FAILED: 'Failed to load header data',
	TABLE_FAILED: 'Failed to load table data',
	LOADING_DATA: 'Loading data',
	REFRESH_RATE: 'Refresh Rate',
	TIME_RANGE: 'Time Range',
	ACTUALS: 'Actuals',
	TARGETS: 'Targets',
	HOURS: 'HOURS',
	MINUTES: 'MINUTES',
	SECONDS: 'SECONDS',
	THEORETICAL_CAPACITY: 'Max Theoretical Throughput Capacity',
	DEMONSTRATED_CAPACITY: 'Max Demonstrated Throughput Capacity',
	NOT_AVAILABLE: 'N/A',
	ASCENDING: 'asc',
	DESCENDING: 'desc',
	PERCENTAGE_SYMBOL: '(%)',
	UNITS_SYMBOL: '(units)',
	TOTAL_LOST_EARNINGS: 'Total Lost Earnings',
	OEE_INFO_COMPONENT: 'OEE_INFO_COMPONENT',
	KEY_VALUES: {
		ACTUAL: 'actual',
		PLANNED: 'planned',
		TARGET: 'target',
		LOSS: 'Loss',
		COLOR_CODE: 'colourCode',
		COLOR_CODE_PLANNED: 'colourCodePlanned',
	},
	SWITCH_VALUES: {
		MAX: 'Max',
		PLANNED: 'Planned',
	},
	TAB_ITEMS: [
		{
			label: 'Factory Health',
			value: 0,
			disabled: false,
		},
		{
			label: 'OEE',
			value: 1,
			disabled: false,
		},
	],
	OEE_KPIs: {
		OEE: { NAME: 'OEE', WIDTH: '17%' },
		AVAILABILITY: { NAME: 'Availability', WIDTH: '17.5%' },
		PERFORMANCE: { NAME: 'Performance', WIDTH: '17.5%' },
		QUALITY: { NAME: 'Quality', WIDTH: '18%' },
	},
	FH_KPIs: {
		WIP: { NAME: 'WIP', WIDTH: '15%' },
	},
	PM_NESTATION_LEVELS: [
		{
			NAME: 'FH_LEVEL',
			LEVEL: 2,
		},
		{
			NAME: 'OEE_LEVEL',
			LEVEL: 5,
		},
	],
};

export const GLOBE_COMPONENT_CONSTANTS = {
	POINT: 'point',
	POINT_3D: 'point-3d',
	OBJECT: 'object',
	ICON: 'icon',
	RELATIVE_ANCHOR: 'relative',
	GLOBE_URL: 'https://js.arcgis.com/4.13/',
	LINEAR_EASING: 'linear',
	NAVIGATE: 'navigate',
	SOURCE: 'esri',
	SOURCE_LAYER_LAND: 'Land',
	SOURCE_LAYER_MARINE: 'Marine area',
	SOURCE_LAYER_BOUNDARY: 'Boundary line',
	SOURCE_LAYER_TYPE: 'fill',
	SOURCE_LAYER_LAND_ID: 'Land/0',
	SOURCE_LAYER_MARINE_ID: 'Marine area/1',
	SOURCE_LAYER_BOUNDARY_ID: 'Boundary line/Admin0/0',
	ROUND: 'round',
	SOURCE_TYPE_LINE: 'line',
	VECTOR_TYPE: 'vector',
	ESRI_URL:
		'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer',
	CONTAINER_DIV: 'viewDiv',
	HIGH_QUALITY: 'high',
	TRIGGER_ACTION: 'trigger-action',
	CLICK_EVENT: 'click',
	CENTER: [2970.285846066311358, -920.19552181573599],
	ANIMATION_DURATION: 40000,
	DISTANCE_FROM_CAMERA: 4000000,
};

export const GLOBE_MENU_CONSTANTS = {
	EXPLORE: 'Explore',
	COMPLETE_CONFIGURATION: 'Complete Configuration',
	ENTITIES: {
		PLANT: 'Plant',
		BUSINESS_UNIT: 'Business Unit',
		OEE: 'OEE',
		NUMBER_OF_PLANTS: 'Number of Plants',
	},
	SORT_LABELS_LOCATION: {
		PLANT: 'plant',
		BUSINESS_UNIT: 'business_unit',
		OEE: 'oee',
	},
	SORT_LABELS_PROGRAM: {
		PLANT: 'plant',
		BUSINESS_UNIT: 'business_unit',
		OEE: 'oee',
		NUMBER_OF_PLANTS: 'plant_count',
	},
	DRAWER_VARIANT: 'persistent',
	TAB_ITEMS: [
		{
			label: 'Plants',
			value: 0,
			disabled: false,
		},
		{
			label: 'Business Unit',
			value: 1,
			disabled: false,
		},
	],
};

export const GLOBE_MODULES = [
	'esri/views/SceneView',
	'esri/Graphic',
	'esri/WebScene',
	'esri/layers/VectorTileLayer',
];

export const DELETE_BUTTON = 'deleteButton';

export const FILE_ACCEPT_TYPES = {
	CSV: '.csv, text/csv',
	IMAGE_PNG_JPEG: '.jpeg,.png,.jpg',
};
export const FILE_CONTENT_TYPES = {
	CSV: 'text/csv',
};

export const FILE_UPLOAD_STATUSES = {
	DONE: 'done',
	UPLOADING: 'uploading',
	ABORTED: 'aborted',
	RESTARTED: 'restarted',
	REMOVED: 'removed',
	STARTED: 'started',
	READY: 'ready',
	PREPARING: 'preparing',
	GETTING_UPLOAD_PARAMS: 'getting_upload_params',
	HEADERS_RECEIVED: 'headers_received',
	REJECTED_FILE: 'rejected_file_type',
};

export const CSV_FILE_UPLOAD_CATEGORIES = {
	SOC_RECIPE: 'recipe',
	ASSET_CRITICALITY: 'assetCriticality',
	ASSET_INTELLIGENCE: 'assetHierarchy',
	FAULT_CODES: 'faultCodes',
	CELL_CRITICALITY: 'cellCriticality',
	FH_THROUGHPUT: 'factoryHealthThroughput',
	FH_WIP: 'factoryHealthWip',
};

export const OEE_VIEWS = {
	OEE: 'OEE',
	AVAILABILITY: 'Availability',
	PERFORMANCE: 'Performance',
	QUALITY: 'Quality',
};

export const OEE_CONFIGURATION_REDUCER_CONSTANTS = {
	CHANGE_OPEN_ACCORDION: 'CHANGE_OPEN_ACCORDION',
	SET_DEVIATIONS_UNSAVED: 'SET_DEVIATIONS_UNSAVED',
	SET_CONFIG_STATES: 'SET_CONFIG_STATES',
	SET_TOAST_MESSAGE: 'SET_TOAST_MESSAGE',
	CLEAR_TOAST: 'CLEAR_TOAST',
};

export const OEE_ACTUALS_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	SET_TIME_RANGE_VALUE: 'SET_TIME_RANGE_VALUE',
	SET_REFRESH_VALUE: 'SET_REFRESH_VALUE',
	SET_REFRESH_UNIT: 'SET_REFRESH_UNIT',
};

export const OEE_ACTUALS_CONSTANTS = {
	ACCESSORS: {
		PARAMETER: 'label',
		VALUE: 'value',
		UOM: 'unit',
	},
	REFRESH_RATE: 'Refresh Rate',
	TIME_RANGE: 'Time Range',
};

export const OEE_DEVIATIONS_REDUCER_CONSTANTS = {
	SET_DEVIATIONS_DATA: 'SET_DEVIATIONS_DATA',
	SET_DEVIATION_TYPE: 'SET_DEVIATION_TYPE',
	SET_DEVIATION_DIRECTION: 'SET_DEVIATION_DIRECTION',
	SET_DEVIATION_BOUNDS: 'SET_DEVIATION_BOUNDS',
};

export const OEE_DEVIATIONS_CONSTANTS = {
	ACCESSORS: {
		LABEL: 'label',
		DIRECTION: 'direction',
		TYPE: 'type',
		LOWERBOUND: 'lowerBound',
		UPPERBOUND: 'upperBound',
	},
};

export const HTTP_METHODS = {
	PUT: 'PUT',
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE',
};

export const ROTATION_COLORS = [
	'rgba(78, 185, 248, 0.8)',
	'rgba(62, 250, 197, 0.8)',
	'rgb(149, 119, 234)',
	'rgba(230, 233, 109, 0.8)',
];

export const DAILY_WEEKLY_ROTATION_CONSTANTS = {
	ROTATIONS: { title: 'Rotation', name: 'rotation' },
	CREWS: { title: 'Crews', name: 'crew' },
	TIME_FORMAT: {
		hour: '2-digit',
		minute: '2-digit',
	},
};

export const ALLOWED_FILE_TYPES = {
	CSV: '.csv',
	IMAGE: 'image/*',
	DOC: '.doc',
	DOCX: '.docx',
	XLS: '.xls',
	XLXS: '.xlsx',
	TXT: '.txt',
};

export const WEEKLY_ROTATIONS_CSV_FILE_NAME = 'weekly-rotations-';
export const DAILY_ROTATIONS_CSV_FILE_NAME = 'daily-rotations-';
export const ASSET_INTELLIGENCE_CSV_FILE_NAME = 'asset-intelligence-parameters';
export const ASSET_CRITICALITY_CSV_FILE_NAME = 'asset-criticality';
export const CELL_CRITICALITY_CSV_FILE_NAME = 'cell-criticality';
export const FAULT_CODES_CSV_FILE_NAME = 'faultCode-template';

export const ERROR_CODES = {
	CSV_NOT_UPLOADED: 204,
};

export const TOAST_REDUCER_CONSTANTS = {
	SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
	SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
	SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
	SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
	HIDE_TOAST: 'HIDE_TOAST',
};

export const PRODUCTION_DAY_WEEKS = [
	'production_Day_1',
	'production_Day_2',
	'production_Day_3',
	'production_Day_4',
	'production_Day_5',
	'production_Day_6',
	'production_Day_7',
];

export const PFC_REDUCER_CONSTANTS = {
	SET_FINANCIAL_CALCULATION_DATA: 'SET_FINANCIAL_CALCULATION_DATA',
	CHANGE_VALUE: 'CHANGE_VALUE',
	CHANGE_UNIT: 'CHANGE_UNIT',
	CHANGE_SOLD_OUT: 'CHANGE_SOLD_OUT',
};

export const CBM_TYPES = {
	REFRESH_RATE: 'refreshRate',
	CRITICALITY_RATINGS: 'criteriaForCriticalityRatings',
	ASSET_CRITICALITY: 'assetCriticality',
	RISK_CATEGORY_THRESHOLDS: 'riskCategoryThresholds',
	STANDARD_OPERATING_CONDITIONS: 'standardOperatingConditions',
	CELL_CRITICALITY: 'CELL_CRITICALITY',
};

export const PARENT_FINANCIAL_CALCULATION_CONSTANTS = {
	ACCESSORS: {
		LABEL: 'label',
		VALUE: 'value',
		UOM: 'UoM',
	},
};

export const CONFIG_DONE_CONTAINER_ID = 'configDoneContainer';

export const RISK_CATEGORY_REDUCER_CONSTANTS = {
	UPDATE_BOUNDS: 'UPDATE_BOUNDS',
	VALIDATE_DATA: 'VALIDATE_DATA',
	OPEN_POPOVER: 'OPEN_POPOVER',
	CLOSE_POPOVER: 'CLOSE_POPOVER',
	RESET_SENTINEL: 'RESET_SENTINEL',
};

export const RISK_CATEGORY_CONSTANTS = {
	ACCESSORS: {
		LABEL: 'label',
		LOWERBOUND: 'lowerBound',
		UPPERBOUND: 'upperBound',
	},
};

// Temp sort array
export const TARGET_LABEL_TYPE = [
	'Max Theoretical Capacity / Production Day',
	'Max Demonstrated Capacity / Production Day',
	'Target Throughput/ Production Day',
];

// SOC View
export const SOC_TABS = {
	ALL_RECIPES: 'Add Recipes',
	STANDARD_OPERATING_CONDITIONS: 'Add Standard Operating Conditions',
};

export const SOC_REDUCER_CONSTANTS = {
	NEXT_STEP: 'NEXT_STEP',
	PREVIOUS_STEP: 'PREVIOUS_STEP',
	ADD_RECIPE: 'ADD_RECIPE',
	OPEN_ADD_RECIPE: 'OPEN_ADD_RECIPE',
	OPEN_MODAL: 'OPEN_MODAL',
	CLOSE_MODAL: 'CLOSE_MODAL',
	SET_SHOW_UPLOAD_BUTTON: 'SET_SHOW_UPLOAD_BUTTON',
	SET_IS_UPLOAD_URL_FETCHING: 'SET_IS_UPLOAD_URL_FETCHING',
	SET_PROCESS_CSV_ERROR_MESSAGE: 'SET_PROCESS_CSV_ERROR_MESSAGE',
	SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX: 'SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX',
	SET_CSV_PROCESSING: 'SET_CSV_PROCESSING',
	SET_CSV_PROCESSING_ERROR: 'SET_CSV_PROCESSING_ERROR',
	UPDATE_RECIPE_SELECTED: 'UPDATE_RECIPE_SELECTED',
	UPDATE_ALL_RECIPES_SELECTED: 'UPDATE_ALL_RECIPES_SELECTED',
	LOAD_ALL_RECIPES: 'LOAD_ALL_RECIPES',
	SET_TEMPLATE_DOWNLOAD_URL: 'SET_TEMPLATE_DOWNLOAD_URL',
	SET_RECIPE_LIMIT_DATA: 'SET_RECIPE_LIMIT_DATA',
	ON_MESSAGE: 'ON_MESSAGE',
	ON_SECONDARY_UPLOAD: 'ON_SECONDARY_UPLOAD',
	UPLOAD_URL_FETCHING_START: 'UPLOAD_URL_FETCHING_START',
	REMOVE_FILE_DATA: 'REMOVE_FILE_DATA',
	HANDLE_FILE_CHANGE_TRIGGER: 'HANDLE_FILE_CHANGE_TRIGGER',
	SET_FILE_DATA: 'SET_FILE_DATA',
	HANDLE_PROCESS_CSV_SUCCESS: 'HANDLE_PROCESS_CSV_SUCCESS',
	SET_GLOBAL_PROCESSING: 'SET_GLOBAL_PROCESSING',
	SET_THINGS: 'SET_THINGS',
	SET_GLOBAL_AND_CSV_PROCESSING: 'SET_GLOBAL_AND_CSV_PROCESSING',
	SET_INGESTION_FAILED: 'SET_INGESTION_FAILED',
};

export const SOC_CONSTANTS = {
	ADD_RECIPE: 'ADD_RECIPE',
	EDIT_RECIPE: 'EDIT_RECIPE',
	DELETE_RECIPE: 'DELETE_RECIPE',
	DOWNLOAD_CSV: 'DOWNLOAD_CSV',
};

const TIMEZONES = {
	WASHINGTON: 'Eastern Standard Time',
	LA: 'Pacific Standard Time',
	CHICAGO: 'Central Standard Time',
	PHOENIX: 'Mountain Standard Time',
	ALASKA: 'Alaska Standard Time',
	HONOLULU: 'Hawaii-Aleutian Standard Time',
};

const TZ_NAME = {
	CHICAGO: 'America/Chicago',
	LA: 'America/Los_Angeles',
	PHOENIX: 'America/Phoenix',
	WASHINGTON: 'America/New_York',
	ALASKA: 'America/Anchorage',
	HONOLULU: 'Pacific/Honolulu',
};

export const LABEL_ZONE_MAPPING = {
	[TZ_NAME.WASHINGTON]: TIMEZONES.WASHINGTON,
	[TZ_NAME.LA]: TIMEZONES.LA,
	[TZ_NAME.CHICAGO]: TIMEZONES.CHICAGO,
	[TZ_NAME.PHOENIX]: TIMEZONES.PHOENIX,
	[TZ_NAME.ALASKA]: TIMEZONES.ALASKA,
	[TZ_NAME.HONOLULU]: TIMEZONES.HONOLULU,
};

export const INTRA_CELL = 'Intra-cell';

export const CREATE_PLANT = {
	PLANT_NAME: 'plantName',
	BUSINESS_UNIT: 'businessUnit',
	CITY: 'city',
	STATE: 'stateCode',
	ZIPCODE: 'zipcode',
	STREET: 'street',
	DEFAULT_PLACEHOLDER_VALUE: 'emptyValue',
};

export const WEB_SOCKET = {
	UPDATE_ASSET_HIERARCHY_CSV: 'updateAssetHierarchyCsv',
	UPDATE_SOC: 'saveSetpointValues',
};

export const WINDOW_EVENTS = {
	BEFORE_UNLOAD: 'beforeunload',
};

export const UPLOAD_ERROR_CODE = {
	INVALID_TYPE: 'file-invalid-type',
	LARGE: 'file-too-large',
	MANY_FILES: 'too-many-files',
};

export const CREATE_PLANT_UPLOAD = {
	MAX_SIZE: 100000,
	MAX_FILE: 1,
};

export const RBAC_CONSTANTS = {
	TEMPORARY_EMAIL_ID: 'harshraj@deloitte.com',
	LOGOUT: 'Logout',
	LANDING_PAGES: {
		GLOBE: 'Globe',
		PERFORMANCE_MANAGEMENT: 'Performance Management',
		ASSET_MANAGEMENT: 'Asset Management',
		ORDER_MANAGEMENT: 'Order Management',
	},

	LEVELS: {
		PLANT: 'Plant',
		BUSINESS_UNIT: 'Business Unit',
		GLOBAL: 'Global',
	},

	PERMISSIONS: {
		// Arrange in heirachy
		VIEW_ONLY: 'View Only',
		EDIT_ONLY: 'Edit Only',
		EDIT_AND_CONFIGURE: 'Edit and Configure',
		SYSTEM_ADMIN: 'System Admin',
	},
};

// Constraint Management
// Constraint Actuals
export const CONSTRAINT_ACTUALS_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	SET_REFRESH_VALUE: 'SET_REFRESH_VALUE',
	SET_TIME_RANGE_VALUE: 'SET_TIME_RANGE_VALUE',
	SET_REFRESH_UNIT: 'SET_REFRESH_UNIT',
};

export const CONSTRAINT_ACTUALS_CONSTANTS = {
	ACCESSORS: {
		PARAMETER: 'label',
		VALUE: 'value',
		UOM: 'unit',
	},
	REFRESH_RATE: 'Refresh Rate',
	TIME_RANGE: 'Time Range',
};

export const CONSTRAINT_MANAGEMENT_CONTAINER_REDUCERS = {
	CHANGE_OPEN_ACCORDION: 'CHANGE_OPEN_ACCORDION',
};

export const CONSTRAINT_MANAGEMENT_ACCORDIONS = {
	ACTUALS: 'ACTUALS',
	TARGETS: 'TARGETS',
	MISC_SETTINGS: 'MISC_SETTINGS',
	FAULT_CODES: 'FAULT_CODES',
};

export const CONSTRAINT_TARGETS_CONSTANTS = {
	ACCESSORS: {
		METRICS: 'metrics',
		TARGET: 'target',
		MATERIAL_CONSTRAINT: 'material_constraint',
		EQUIPMENT_CONSTRAINT: 'equipment_constraint',
		ALL: 'all',
	},
};

export const CONSTRAINT_TARGETS_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	CHANGE_TARGETS_VALUE: 'CHANGE_TARGETS_VALUE',
	CHANGE_TIME_FIELD: 'CHANGE_TIME_FIELD',
	SHOW_ERROR: 'SHOW_ERROR',
};

export const CBM_CONSTANTS = {
	FILTER: 'Filter',
	LOADING_WATCHLIST: 'Loading Watch Lists',
	WATCH_LIST_TITLES: ['Watch List', 'Operating Conditions'],
	WATCH_LIST_TITLE_KEYS: ['Cell', 'Asset', 'Parameter'],
	TOP_HEADER: 'Equipment Management',
	TAB_ITEMS_FIRST_LEVEL: [
		{
			label: 'Manufacturing Equipment',
			value: 0,
			disabled: false,
		},
		{
			label: 'Building Equipment',
			value: 1,
			disabled: false,
		},
	],

	TAB_ITEMS_SECOND_LEVEL: [
		{
			label: 'Watch Lists',
			value: 0,
			disabled: false,
		},
		{
			label: 'Operating Conditions',
			value: 1,
			disabled: false,
		},
	],
	RISK_CATEGORY_LABELS: ['Catastrophic', 'Major', 'Moderate', 'Minor', 'Insignificant'],
	CELL_LIST_TABLE_HEADERS: [
		{ NAME: 'Priority', WIDTH: '8%' },
		{ NAME: 'Area', WIDTH: '12%' },
		{ NAME: 'Line', WIDTH: '12%' },
		{ NAME: 'Zone', WIDTH: '12%' },
		{ NAME: 'Cell', WIDTH: '40%' },
		{ NAME: 'Risk Category', WIDTH: '9%' },
		{ NAME: 'View Details', WIDTH: '7%' },
	],
	ASSET_LIST_TABLE_HEADERS: [
		{ NAME: 'Priority', WIDTH: '8%' },
		{ NAME: 'Area', WIDTH: '12%' },
		{ NAME: 'Line', WIDTH: '12%' },
		{ NAME: 'Zone', WIDTH: '12%' },
		{ NAME: 'Cell', WIDTH: '12%' },
		{ NAME: 'Asset', WIDTH: '26%' },
		{ NAME: 'Risk Category', WIDTH: '9%' },
		{ NAME: 'View Details', WIDTH: '7%' },
	],
	CELL_LIST_TABLE_HEADERS_KEYS: [
		'priority',
		'area',
		'line',
		'zone',
		'cell',
		'riskCategory',
	],
	ASSET_LIST_TABLE_HEADERS_KEYS: [
		'priority',
		'area',
		'line',
		'zone',
		'cell',
		'asset',
		'riskCategory',
	],
	PARAMETER_LIST_TABLE_HEADERS: [
		{ NAME: 'Priority', WIDTH: '7.5%' },
		{ NAME: 'Area', WIDTH: '8.5%' },
		{ NAME: 'Line', WIDTH: '10%' },
		{ NAME: 'Zone', WIDTH: '10%' },
		{ NAME: 'Cell', WIDTH: '10.5%' },
		{ NAME: 'Asset', WIDTH: '10.5%' },
		{ NAME: 'Parameter', WIDTH: '10.5%' },
		{ NAME: 'Setpoint Target', WIDTH: '8.5%' },
		{ NAME: 'Present Value', WIDTH: '8%' },
		{ NAME: 'Risk Category', WIDTH: '9%' },
		{ NAME: 'View Details', WIDTH: '' },
	],
	PARAMETER_LIST_TABLE_HEADERS_KEYS: [
		'priority',
		'area',
		'line',
		'zone',
		'cell',
		'asset',
		'parameter',
		'setpointTarget',
		'presentValue',
		'riskCategory',
	],
	CONSTRAINT_TYPE: 'Constraint Type',
	CONSTRAINT_DURATION: 'Constraint Duration',
	AREAS: 'Areas',
	LINES: 'Lines',
	ZONES: 'Zones',
	CELLS: 'Cells',
	ASSETS: 'Assets',
	AREA: 'Area',
	LINE: 'Line',
	ZONE: 'Zone',
	CELL: 'Cell',
	ASSET: 'Asset',
	PARAMETER_TYPE: 'Parameter Type',
	PARAMETER: 'Parameter',
	PART_NAME: 'Part Name',
	PART_NUMBER: 'Part No',
	UOM: 'UoM',
	RECIPE: 'Recipe',
	SETPOINT_LL: 'LL',
	SETPOINT_TARGET: 'Target',
	SETPOINT_UL: 'UL',
	SETPOINT_VALUE: 'SV',
	PRESENT_VALUE: 'PV',

	ROWS_PER_PAGE: 'Rows per page',
	PARAMETER_ENTITY_ID: 'parameterEntityId',
	PARAMETER_TYPE_ENTITY_ID: 'parameterTypeEntityId',
	ASSET_ENTITY_ID: 'assetEntityId',
	CELL_ENITY_ID: 'cellEntityId',
	ZONE_ENTITY_ID: 'zoneEntityId',
	LINE_ENTITY_ID: 'lineEntityId',
	AREA_ENTITY_ID: 'areaEntityId',

	TOOLTIPS: {
		LL: {
			title: 'Setpoint Lower Limit',
			description:
				'Setpoint lower bound threshold as defined in the Standard Operating Conditions configuration',
		},
		TARGET: {
			title: 'Setpoint Target',
			description:
				'Setpoint target as defined in the Standard Operating Conditions configuration',
		},
		UL: {
			title: 'Setpoint Upper Limit',
			description:
				'Setpoint upper bound threshold as defined in the Standard Operating Conditions configuration',
		},
		SV: {
			title: 'Setpoint Value',
			description:
				'The value that the control system is aiming to reach and maintain for a parameter on the equipment',
		},
		PV: {
			title: 'Present Value',
			description:
				'The value of a parameter at the current point in time as measured by a sensor',
		},
	},
};

export const CONSTRAINT_VIEWS = {
	DEFAULT: 'ConstraintManagement',
};

export const CONSTRAINT_MISC_SETTINGS_CONSTANTS = {
	ACCESSORS: {
		PARAMETER: 'parameter',
		VALUE: 'value',
	},
};

export const CONSTRAINT_MISC_SETTINGS_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	UPDATE_VALUE: 'UPDATE_VALUE',
	PUT_UPDATE_SUCCESSFUL: 'PUT_UPDATE_SUCCESSFUL',
};

export const ORDER_MANAGEMENT_CONSTANTS = {
	HEADER: 'Order Management',
	TAB_ITEMS: [
		{
			label: 'Constraint Mgmt.',
			value: 0,
			disabled: false,
		},
		{
			label: 'Dynamic Order Mgmt.',
			value: 1,
			disabled: false,
		},
	],
	ACTIONS_TAB_ITEMS: [
		{
			label: 'View Equipment Fault',
			value: 0,
			disabled: false,
		},
		{
			label: 'View Work Orders',
			value: 1,
			disabled: false,
		},
	],
};

export const CONSTRAINT_MANAGEMENT_CONSTANTS = {
	CONSTRAINT_MANAGEMENT_TITLE: 'Constraint Mgmt.',
	CONSTRAINT_STATUS: 'Constraint Status',
	OPEN_CONSTRAINTS: 'Open Constraints',
	SNOOZED_CONSTRAINTS: 'Snoozed Constraints',
	CLOSED_CONSTRAINTS: 'Closed Constraints',
	CONSTRAINT_DURATION_METRICS: 'Constraint Duration Metrics',
	HOUR: 'hr',
	MINS: 'mins',
	ZERO: '0',
	NA: 'N/A',
	CONFIRM: 'Confirm',
	CORRECTIVE_ACTIONS: 'Corrective Actions',
	WORK_ORDER_CREATED: 'Work Order Created',
	ACTIONS: 'Actions',
	START_TIME_OPEN_OR_SNOOZED: 'Maint. Start Time',
	START_TIME_CLOSED: 'Maint. Actual Start Time',
	END_TIME_OPEN_OR_SNOOZED: 'Maint. Planned End Time',
	END_TIME_CLOSED: 'Maint. Actual End Time',
	PRIORITY: 'Priority',
	TIME_STAMP: 'Time Stamp',
	CONSTRAINT_TYPE: 'Constraint Type',
	LOADING_DATA: 'Loading data',
	NO_SHIFT: 'No shift is currently working',
	TAB_TYPE_KEYS: ['allConstraints', 'equipmentConstraints', 'materialConstraints'],
	CARD_DATA: [
		{
			HEADING: 'openConstraintsDuration',
			CAPTION: 'targetOpenConstraintDuration',
			SUBTITLE_1: 'Total Duration',
			SUBTITLE_2: 'Open Constraints',
		},
		{
			HEADING: 'avgConstraintResolutionTime',
			CAPTION: 'targetAvgConstraintResolutionTime',
			SUBTITLE_1: 'Average Resolution Time',
			SUBTITLE_2: 'Closed Constraints',
		},
		{
			HEADING: 'forecastedLabourTime',
			CAPTION: 'targetForecastedLabourTime',
			SUBTITLE_1: 'Forecasted Labor To Resolution',
			SUBTITLE_2: 'Open Constraints',
		},
	],
	TAB_ITEMS: [
		{
			label: 'All Constraints',
			value: 0,
			disabled: false,
		},
		{
			label: 'Equipment Constraints',
			value: 1,
			disabled: false,
		},
		{
			label: 'Material Constraints',
			value: 2,
			disabled: false,
		},
	],
	HEADER_CONSTANTS: {
		REFRESH_RATE: 'Refresh Rate',
		TIME_RANGE: 'Time Range',
		ACTUALS: 'Actuals',
		TARGETS: 'Targets',
		ACTUAL_VALUE: 'N',
		TARGET_VALUE: '|N',
	},
	SNOOZE_POPUP: ['hr', 'min'],
	CONSTRAINTS_DROPDOWN_INIT_REFERENCE: [
		{
			entityId: '0-15',
			entityName: '<= 15 mins',
		},
		{
			entityId: '15-30',
			entityName: '15> - <=30 mins',
		},
		{
			entityId: '30-60',
			entityName: '30> - <=60 mins',
		},
		{
			entityId: '60-720',
			entityName: '1> - <=12 hrs',
		},
		{
			entityId: '720-1200',
			entityName: '>12 hrs',
		},
	],
	WORK_ORDER_INFO: [
		{ label: 'Work Order #', key: 'workOrder', size: 4 },
		{ label: 'Department', key: 'department', size: 4 },
		{ label: 'Assigned to', key: 'assignedTo', size: 4 },
		{ label: 'Equipment Code (#)', key: 'equipmentCode', size: 4 },
		{ label: 'Trade', key: 'trade', size: 4 },
		{ label: 'Work Order Type', key: 'workOrderType', size: 4 },
		{ label: 'Work Order Description', key: 'workOrderDescription', size: 12 },
		{ label: 'Activities', key: 'activities', size: 12 },
	],
	LOADING_WORK_ORDERS: 'Loading Work Orders',
	NO_DATA_AVAILABLE: 'No Data Available',
};
export const DYNAMIC_ORDER_MANAGEMENT_CONSTANTS = {
	HEADER_TITLE: 'Dynamic Order Management',
	HEADER_SUBTITLES: [
		{ label: 'Frozen Order Status Time', key: 'frozenOrderStatusTime', size: 2 },
		{ label: 'SFF Editable Time Window', key: 'editableTimeWindow', size: 4 },
		{ label: 'Approval Time', key: 'approvalTime', size: 2 },
		{ label: 'Plant Time', key: 'plantTime', size: 2 },
	],
	START_TIME: 'Start Time',
	DURATION: 'Duration',
	TAB_ITEMS: [
		{
			label: 'Production Orders',
			value: 0,
			disabled: false,
		},
		{
			label: 'Production Schedule',
			value: 1,
			disabled: false,
		},
	],
	DROPDOWN_LABELS: ['Scheduled Area', 'Scheduled Line', 'Scheduled Zone', 'Time Range'],
	SEQUENCE: 'Sequence',
	PRIORITY_RATING: 'Priority Rating',
	ORDER: 'Order',
	ORDER_DESCRIPTION: 'Order Description',
	CURRENT_LINE: 'Current Line',
	CURRENT_ZONE: 'Current Zone',
	CURRENT_CELL: 'Current Cell',
	ORDER_QUANITY: 'Order Quantity',
	ORDER_STATUS: 'Order Status',
	LOADING: 'Loading',
	SAVE: 'Save',
	EDIT: 'Edit',
	APPLY: 'Apply',
	SELECT_VALUE: 'Select Value',
	NEXT_7_DAYS: 'Next 7 days',
};

export const ADD_EDIT_TASK_MODE = {
	CREATE: 'CREATE',
	EDIT: 'EDIT',
};

export const ADD_EDIT_PLANT_MODAL_REDUCER_CONSTANTS = {
	CHANGE_SELECTED_PLANT: 'CHANGE_SELECTED_PLANT',
	CHANGE_SEARCH_OPTION: 'CHANGE_SEARCH_OPTION',
	CLICK_CREATE_NEW_PLANT: 'CLICK_CREATE_NEW_PLANT',
	EDIT_EXISTING_PLANT: 'EDIT_EXISTING_PLANT',
	UPDATE_PLANT_DATA: 'UPDATE_PLANT_DATA',
	UPDATE_PLANT_IMAGE: 'UPDATE_PLANT_IMAGE',
	SHOW_ERROR: 'SHOW_ERROR',
	CLEAR_FORM: 'CLEAR_FORM',
	LOAD_PLANTS_LIST: 'LOAD_PLANTS_LIST',
	IS_DEFAULT_PLANT_IMAGE: 'IS_DEFAULT_PLANT_IMAGE',
};

export const PLANT_IMAGE_META = {
	IMAGE_SOURCE_DIR: 'ui-globe-app/plants/',
	DEFAULT_PLANT_IMAGE_NAME: 'default-plant-image.png',
};

export const INITIAL_SETUP_TABS = {
	COMPANY: 'Create Company',
	BUSINESS_UNITS: 'Create Business Units',
	PLANTS: 'Create Plant',
};

export const INITIAL_COMPANY_SETUP_REDUCER_CONSTANTS = {
	NEXT_STEP: 'NEXT_STEP',
	PREVIOUS_STEP: 'PREVIOUS_STEP',
	OPEN_MODAL: 'OPEN_MODAL',
	UPDATE_COMPANY_DATA: 'UPDATE_COMPANY_DATA',
	UPDATE_PLANT_DATA: 'UPDATE_PLANT_DATA',
	ADD_NEW_BUSINESS_UNITS: 'ADD_NEW_BUSINESS_UNITS',
	UPDATE_BUSINESS_UNITS: 'UPDATE_BUSINESS_UNITS',
	UPDATE_PLANT_IMAGE: 'UPDATE_PLANT_IMAGE',
	VALIDATE_PLANT_DATA: 'VALIDATE_PLANT_DATA',
	VALIDATE_COMPANY_DATA: 'VALIDATE_COMPANY_DATA',
	COMAPNY_DATA_SHOW_ERROR: 'COMAPNY_DATA_SHOW_ERROR',
	BUSINESS_UNITS_DATA_SHOW_ERROR: 'BUSINESS_UNITS_DATA_SHOW_ERROR',
	PLANTS_DATA_SHOW_ERROR: 'PLANTS_DATA_SHOW_ERROR',
	SET_COMPANY_DATA: 'SET_COMPANY_DATA',
	SET_BUSINESS_UNITS: 'SET_BUSINESS_UNITS',
	CLEAR_PLANT_FORM_AND_DISPLAY_ANOTHER: 'CLEAR_PLANT_FORM_AND_DISPLAY_ANOTHER',
	DISPLAY_MODAL_CREATE_PLANT: 'DISPLAY_MODAL_CREATE_PLANT',
	CLOSE_ALL_MODAL: 'CLOSE_ALL_MODAL',
};

export const INITIAL_COMPANY_SETUP = {
	COMPANY_INITIAL_VALUES: {
		value: '',
		error: MESSAGE_STRINGS['InitialCompanySetup.company.errorMessage.empty'],
		showError: false,
		isUpdated: false,
	},
	BUSINESS_UNIT_INITIAL_VALUES: [
		{
			value: '',
			error: MESSAGE_STRINGS['InitialCompanySetup.businessUnit.errorMessage.empty'],
			showError: false,
			isUpdated: false,
			id: '1',
		},
	],
	PLANTS_INITIAL_VALUES: {
		plantName: {
			value: '',
			error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
			showError: false,
		},
		businessUnit: {
			value: 'emptyValue',
			error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.businessUnit'],
			showError: false,
		},
		street: {
			value: '',
			error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
			showError: false,
		},
		city: {
			value: '',
			error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
			showError: false,
		},
		zipcode: {
			value: '',
			error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
			showError: false,
		},
		stateCode: {
			value: 'emptyValue',
			error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.state'],
			showError: false,
		},
		imageFile: [],
	},
};

export const ADD_EDIT_BU_REDUCER_CONSTANTS = {
	ADD_NEW_BUSINESS_UNITS: 'ADD_NEW_BUSINESS_UNITS',
	UPDATE_BUSINESS_UNITS: 'UPDATE_BUSINESS_UNITS',
	LOAD_BUSINESS_UNITS: 'LOAD_BUSINESS_UNITS',
	SHOW_ERRORS: 'SHOW_ERRORS',
};

export const ADD_EDIT_PLANT_PRISTINE_STATE = {
	plantName: {
		value: '',
		showError: false,
		error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
		isUpdated: false,
	},
	city: {
		value: '',
		showError: false,
		error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
		isUpdated: false,
	},
	street: {
		value: '',
		showError: false,
		error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.emptyField'],
		isUpdated: false,
	},
	businessUnit: {
		value: CREATE_PLANT.DEFAULT_PLACEHOLDER_VALUE,
		showError: false,
		error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.businessUnit'],
		isUpdated: false,
	},
	stateCode: {
		value: CREATE_PLANT.DEFAULT_PLACEHOLDER_VALUE,
		showError: false,
		error: MESSAGE_STRINGS['InitialCompanySetup.plant.errorMessage.state'],
		isUpdated: false,
	},
	zipcode: {
		value: '',
		showError: false,
		error: MESSAGE_STRINGS['CreatePlant.validationMessage.zipcode'],
		isUpdated: false,
	},
	imageFile: [],
	imageFileUpdated: false,
	plantId: '',
};

export const APP_CONTEXT = {
	COMPANY_DATA: 'COMPANY_DATA',
	UPDATE_COMPANY_NAME: 'UPDATE_COMPANY_NAME',
};

export const USER_PROGRESS_INITIAL_SETUP = {
	COMPANY_NOT_CREATED: 0,
	BUSINESS_UNITS_NOT_CREATED: 1,
	PLANTS_NOT_CREATED: 2,
	DONE: 3,
};

export const CONSTRAINT_FAULT_CODES_REDUCER_CONSTANTS = {
	SET_SHOW_UPLOAD_BUTTON: 'SET_SHOW_UPLOAD_BUTTON',
	SET_IS_UPLOAD_URL_FETCHING: 'SET_IS_UPLOAD_URL_FETCHING',
	SET_PROCESS_CSV_ERROR_MESSAGE: 'SET_PROCESS_CSV_ERROR_MESSAGE',
	SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX: 'SET_SHOW_UPLOAD_SUCCESS_DIALOG_BOX',
	SET_CSV_PROCESSING: 'SET_CSV_PROCESSING',
	SET_CSV_PROCESSING_ERROR: 'SET_CSV_PROCESSING_ERROR',
	SET_TEMPLATE_DOWNLOAD_URL: 'SET_TEMPLATE_DOWNLOAD_URL',
	ON_MESSAGE: 'ON_MESSAGE',
	ON_SECONDARY_UPLOAD: 'ON_SECONDARY_UPLOAD',
	UPLOAD_URL_FETCHING_START: 'UPLOAD_URL_FETCHING_START',
	SET_META_DATA: 'SET_META_DATA',
};

export const CONFIG_ORDER_MANAGEMENT_ACCORDIONS = {
	MISC_SETTINGS: 'MISC_SETTINGS',
	SYSTEM_INTEGRATIONS: 'SYSTEM_INTEGRATIONS',
	CHANGEOVER_TIME: 'CHANGEOVER_TIME',
};

export const CONFIG_ORDER_MANAGEMENT = {
	MISC_SETTINGS: {
		ACCESSORS: {
			PARAMETER: 'label',
			VALUE: 'value',
			TIME: 'unit',
		},
		REFRESH_RATE: 'Refresh Rate',
		FROZEN_ORDER: 'Configured Time for Frozen Orders',
		EDITABLE_START_TIME: 'Editable Time Window Start Time',
		EDITABLE_DURATION: 'Editable Time Window Duration',
		APPROVAL_TIME: 'Approval Time',
		CHANGE_OPEN_ACCORDION: 'CHANGE_OPEN_ACCORDION',
	},
	SYSTEM_INTEGRATIONS: {
		ACCESSORS: { LABEL: 'label', VALUE: 'value' },
		VIEW_BY: 'VIEW_BY',
		DEFAULT_VIEW_BY_VALUE: 'emptyValue',
	},
	CHANGEOVER_TIME: {
		ACCESSORS: { LABEL: 'zoneName', VALUE: 'value' },
	},
};

export const ORDER_MANAGEMENT_MISC_SETTINGS_REDUCER_CONSTANTS = {
	UPDATE_DATA: 'UPDATE_DATA',
	UPDATE_REFRESH_RATE_VALUE: 'UPDATE_REFRESH_RATE_VALUE',
	UPDATE_REFRESH_RATE_UNITS: 'UPDATE_REFRESH_RATE_UNITS',
	SET_DATA: 'SET_DATA',
	SHOW_ERROR: 'SHOW_ERROR',
};

export const ORDER_MANAGEMENT_SYSTEM_INTEGRATIONS_REDUCER_CONSTANTS = {
	UPDATE_DATA: 'UPDATE_DATA',
	SET_DATA: 'SET_DATA',
};
export const OEE_IDEAL_RUN_RATE_CONSTANTS = {
	ACCESSORS: {
		LABEL: 'lineName',
		VALUE: 'idealRunRate',
		UOM: 'uom',
	},
	DEFAULT_PLACEHOLDER_VALUE: 'SELECT_UOM',
};

export const OEE_IDEAL_RUN_RATE_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	UPDATE_IDEAL_RUN_RATE: 'UPDATE_IDEAL_RUN_RATE',
	UPDATE_UOM_DATA: 'UPDATE_UOM_DATA',
};

export const RISK_SELECTION_GRADIENTS = {
	DEFAULT: '#95CA3C 0%, #FF9900 50%, #DA291C 100%',
	PARAMETER_RISK_FACTOR_THRESHOLD:
		'#33F0FF 8.68%, #95CA3C 29.51%, #FFCD00 49.83%, #ED8B00 70.14%, #DA291C 90.97%',
};

export const NOTIFICATION_ADD_REMOVE_REDUCER_CONSTANTS = {
	UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
	RESET_STATE: 'RESET_STATE',
	SET_FILTER_META: 'SET_FILTER_META',
	TOGGLE_FILTER_STATE: 'TOGGLE_FILTER_STATE',
	APPLY_FILTER_STATE: 'APPLY_FILTER_STATE',
	RESET_FILTER_STATE: 'RESET_FILTER_STATE',
	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
	HANDLE_FILTER_CLOSE: 'HANDLE_FILTER_CLOSE',
	CANCEL_ACTION: 'CANCEL_ACTION',
	RESET_GLOBAL_FILTERS: 'RESET_GLOBAL_FILTERS',
};

export const NOTIFICATION_CONFIGURATION_REDUCER = {
	SET_DURATION_VALUE: 'SET_DURATION_VALUE',
	SET_DURATION_ERROR: 'SET_DURATION_ERROR',

	SET_SNOOZE_TIME_VALUE: 'SET_SNOOZE_TIME_VALUE',
	SET_SNOOZE_TIME_UOM: 'SET_SNOOZE_TIME_UOM',
	SET_SNOOZE_TIME_ERROR: 'SET_SNOOZE_TIME_ERROR',

	SET_PERSIST_TIME_VALUE: 'SET_PERSIST_TIME_VALUE',
	SET_PERSIST_TIME_ERROR: 'SET_PERSIST_TIME_ERROR',

	SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
	RESET_NOTIFICATION_SETTINGS: 'RESET_NOTIFICATION_SETTINGS',
	UPDATE_OLD_NOTIFICATION_SETTINGS: 'UPDATE_OLD_NOTIFICATION_SETTINGS',

	SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
	UPDATE_NOTIFICATION_TYPE: 'UPDATE_NOTIFICATION_TYPE',
	UPDATE_MUTE_VALUE: 'UPDATE_MUTE_VALUE',
	UPDATE_RECURRENCE_SWITCH: 'UPDATE_RECURRENCE_SWITCH',
	UPDATE_RECURRENCE_VALUE: 'UPDATE_RECURRENCE_VALUE',
	UPDATE_RECURRENCE_UOM: 'UPDATE_RECURRENCE_UOM',
	UPDATE_NOTIFICATON_ROLES_ASSIGNED: 'UPDATE_NOTIFICATON_ROLES_ASSIGNED',
	SET_ACTIVE_NOTIFICATIONS_ERRORS: 'SET_ACTIVE_NOTIFICATIONS_ERRORS',

	TOGGLE_ADD_REMOVE_MODAL_VISIBILITY: 'TOGGLE_ADD_REMOVE_MODAL_VISIBILITY',
	SET_EDITING_MODE: 'SET_EDITING_MODE',
	SET_ADDITIONAL_SETTINGS_CONTEXT: 'SET_ADDITIONAL_SETTINGS_CONTEXT',
	HANDLE_OPERATIONS_AREA_FILTER_TOGGLE: 'HANDLE_OPERATIONS_AREA_FILTER_TOGGLE',
	HANDLE_OPERATIONS_AREA_FILTER_APPLY: 'HANDLE_OPERATIONS_AREA_FILTER_APPLY',
	HANDLE_OPERATIONS_AREA_FILTER_RESET: 'HANDLE_OPERATIONS_AREA_FILTER_RESET',
	HANDLE_FILTER_CLOSE: 'HANDLE_FILTER_CLOSE',
	HANDLE_OPERATIONAL_FILTER_CLOSE: 'HANDLE_OPERATIONAL_FILTER_CLOSE',
	HANDLE_OPERATIONS_AREA_SELECTED_REMOVE: 'HANDLE_OPERATIONS_AREA_SELECTED_REMOVE',
	HANDLE_OPERATIONS_AREA_SEARCH_TERM_CHANGE: 'HANDLE_OPERATIONS_AREA_SEARCH_TERM_CHANGE',
	HANDLE_OPERATIONAL_FILTER_OPEN: 'HANDLE_OPERATIONAL_FILTER_OPEN',
	ADD_REMOVE_SAVE_ACTION: 'ADD_REMOVE_SAVE_ACTION',
	TOGGLE_FILTER_STATE: 'TOGGLE_FILTER_STATE',
	APPLY_FILTER_STATE: 'APPLY_FILTER_STATE',
	RESET_FILTER_STATE: 'RESET_FILTER_STATE',
	SET_FILTER_META: 'SET_FILTER_META',
	RESET_ALL_FILTER: 'RESET_ALL_FILTER',
	RESET_ON_UPDATE: 'RESET_ON_UPDATE',
	RESET_GLOBAL_FILTER: 'RESET_GLOBAL_FILTER',

	OPEN_SETTING_ACCORDION: 'OPEN_SETTING_ACCORDION',
};

export const NOTIFICATION_CONFIGS = {
	NOTIFICATION_TIMING: 'NOTIFICATION_TIMING',
	NOTIFICATION_TRIGGER_SETTINGS: 'NOTIFICATION_TRIGGER_SETTINGS',
};

export const ACTIVE_NOTIFICATIONS_TABLE_HEADERS = {
	FUNCTIONAL_AREA: 'functionalArea',
	NOTIFICATION_TRIGGER: 'triggerName',
	ROLES_ASSIGNED: 'rolesAssigned',
	NOTIFICATION_TYPE: 'notificationType',
	RECURRENCE: 'recurrence',
	ADDITIONAL_SETTINGS: 'additionalSettings',
	MUTE: 'isMuted',
};

export const TIME_UOM = {
	DAYS: 'DAYS',
	HOURS: 'HOURS',
	MINUTES: 'MINUTES',
	SECONDS: 'SECONDS',
};

export const NOTIFICATION_TIMINGS_TYPES = {
	DURATION_FOR_BANNER: 'DURATION_VIEW',
	SNOOZE_TIME_FOR_ALERT: 'SNOOZE_TIME',
	NOTIFICATION_CENTRE_PERSIST: 'PERSIST_NOTIFICATION_DURATION',
};

export const NOTIFICATION_TYPE_DEFAULT_VALUES = {
	key: 'selectType',
	value: 'Select Type',
};

export const ADDITIONAL_NOTIFICATION_SETTINGS = {
	ASSET_CRITICALITY_THRESHOLD: 'ASSETCRITICALITYTHRESHOLD',
	ASSET_RISK_CATEGORY_TRESHOLD: 'ASSETRISKCATEGORYTHRESHOLD',
	PARAMETER_RISK_FACTOR_THRESHOLD: 'PARAMTERRISKCATEGORYTHRESHOLD',
	CELL_CRITICALITY_TRESHOLD: 'CELLCRITICALITYTHRESHOLD',
	CELL_RISK_CATEGORY_TRESHOLD: 'CELLRISKCATEGORYTHRESHOLD',
};

// mock
export const ASSET_CRIT_TRESHOLD = [
	{
		id: 1,
		name: 'Lowest Risk',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 2,
		name: '',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 3,
		name: '',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 4,
		name: '',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 5,
		name: '',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 6,
		name: '',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 7,
		name: '',
		tooltipMessage: 'Manual Workaround available',
	},
	{
		id: 8,
		name: 'Highest Risk',
		tooltipMessage: 'Medium Term shutdown with difficult fear',
	},
];

// mock
export const PARAMETER_RISK_THRESHOLD = [
	{
		id: 1,
		name: 'Insignificant',
		tooltipMessage: '',
	},
	{
		id: 2,
		name: 'Minor',
		tooltipMessage: '',
	},
	{
		id: 3,
		name: 'Moderate',
		tooltipMessage: '',
	},
	{
		id: 4,
		name: 'Major',
		tooltipMessage: '',
	},
	{
		id: 5,
		name: 'Catastrophic',
		tooltipMessage: '',
	},
];

export const ORDER_MANAGEMENT_CHANGEOVER_TIME_REDUCER_CONSTANTS = {
	UPDATE_DATA: 'UPDATE_DATA',
	SET_DATA: 'SET_DATA',
};

export const TIME_PICKER_FIELD = {
	MASK_VALUE: '__:__',
};

export const ADDITIONAL_SETTINGS_REDUCER_CONSTANTS = {
	SET_DATA: 'SET_DATA',
	ROLE_BASED_ACCESS_UPDATE: 'ROLE_BASED_ACCESS_UPDATE',
	ROLE_BASED_ACCESS_SHOW_VALIDATION: 'ROLE_BASED_ACCESS_SHOW_VALIDATION',
	OEE_DURATION_UPDATE: 'OEE_DURATION_UPDATE',
	OEE_UOM_UPDATE: 'OEE_UOM_UPDATE',
	CBM_SET_NEW_TRESHOLD: 'CBM_SET_NEW_TRESHOLD',
};

export const NOTIFICATION_CENTER_CONSTANTS = {
	TAB_ITEMS: [
		{
			label: 'Functional Area',
			value: 0,
			disabled: false,
		},
		{
			label: 'Notification Type',
			value: 1,
			disabled: false,
		},
	],
};

export const NOTIFICATION_UPDATE_TYPES = {
	DISMISS: 'dismissed',
	SNOOZE: 'snoozed',
	EXPLORE: 'explore',
	UNSNOOZE: 'unsnoozed',
};

export const NOTIFICATION_TYPES = {
	ALERT: 'Alert',
	BANNER: 'Banner',
	NC_ONLY: 'Notification Center Only',
};

export const CONFIGURATION_ALERT_CONSTANTS = {
	FUNCTIONAL_AREAS: {
		FACTORY_HEALTH: 'Factory Health',
		OEE: 'OEE',
		CONSTRAINT_MANAGEMENT: 'Constraint Mgmt.',
		DOM: 'Dynamic Order Mgmt.',
		MANUFACTURING_EQUIPMENT: 'Manufacturing Equipment',
		BUILDING_EQUIPMENT: 'Building Equipment',
	},
};

export const SOCKET_DATA_TYPES = {
	ALERT: 'Alert',
	BANNER: 'Banner',
	NC_ONLY: 'Notification Center Only',
	CONFIGURATION_STATUS: 'configurationStatus',
	CONFIGURATION_STATUS_UPDATE: 'configurationUpdate',
};

export const GLOBE_PLANTS_REFRESH_INTERVAL = 1000 * 60 * 10;
