export const editPlantsDialogDefaultFormValues = {
	metadata: {
		selectedPlant: '',
		selectedBusinessUnitName: '',
		selectedStateName: '',
		isUpdateMode: false,

		plantImage: {
			newFileName: null,
			initialFileName: null,
			isFileExistsOnFetch: null,
			isUserClickOnRemoveOnce: false,
		},
	},

	plant: {
		plantName: '',
		plantId: '',
		entityType: '',
		street: '',
		city: '',
		zipCode: '',
		stateCode: '',
		fileName: '',
		imgSrcPath: '',
		fileSize: '',
		businessUnitId: '',
		businessUnitName: '',
		hasAssetHierarchyChanged: null,
		entityNumber: null,
		hierarchyRowCount: null,
		createdAt: '',
		updatedAt: '',
		file: [],
	},

	plantSnapshot: {},
};
