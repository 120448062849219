import { styled } from '@mui/material';
import theme from '../../theme';

export const ViewContainer = styled('div')(({ theme }) => {
	const transitionAnimation = 'all 0.225s ease-out';
	const boxShadow = '0 2px 6px rgba(0,0,0,0.16)';
	const raiseAnimation = 'scale(1.025)';
	const filter = 'brightness(125%)';

	return {
		border: `1px solid ${theme.customColors.targetLightGrey}`,
		height: 'fit-content',
		borderRadius: '4px 4px 0 0',
		fontFamily: 'Open Sans',
		transition: transitionAnimation,
		MozTransition: transitionAnimation,
		WebkitTransition: transitionAnimation,

		'&:hover': {
			filter: filter,
			transition: transitionAnimation,
			boxShadow: boxShadow,
			transform: raiseAnimation,
		},
	};
});

export const ContentContainer = styled('div')(({ theme, width, height, isActive }) => {
	const backgroundActive = '#2D3338';
	const transitionAnimation = 'all 0.225s ease-out';
	const { background } = theme?.palette || {};

	return {
		height: height || '3.75rem',
		width: width || '15rem',
		padding: '0 0.625rem 0 0.625rem',
		display: 'flex',
		flexDirection: 'column',
		transition: transitionAnimation,
		MozTransition: transitionAnimation,
		WebkitTransition: transitionAnimation,
		backgroundColor: isActive ? backgroundActive : background.darkishBlackBlue,
	};
});

export const FlexContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const TextContainer = styled('figcaption')(({ theme }) => ({
	display: 'flex',
	alignItems: 'start',
}));

export const StepNumber = styled('div')(({ theme }) => ({
	fontSize: '1.5rem',
	marginRight: '0.75rem',
	marginTop: '0.2rem',
	height: 'fit-content',
}));

export const TextView = styled('span')(({ theme }) => ({
	marginTop: '0.4rem',
	textTransform: 'capitalize',
}));

export const StepIcon = styled('img')(({ theme }) => ({
	width: '1rem',
	height: '1rem',
	marginTop: '0.625rem',
	transition: 'all 0.5s ease-out',
}));

export const StepUnderline = styled('div')(({ isActive }) => {
	const backgroundActive = '#2D3338';
	const { background } = theme?.palette || {};
	const completeLineHeight = '0.25rem';

	return {
		height: completeLineHeight,
		width: '100%',
		backgroundColor: isActive ? backgroundActive : background.darkishBlackBlue,
		marginTop: 'auto',
	};
});

export const StepUnderlineCompleted = styled('div')(({ isActive }) => {
	const slowTransitionAnimation = 'all 0.5s ease-out';
	const { background } = theme?.palette || {};

	return {
		position: 'relative',
		height: 'inherit',
		backgroundColor: background.saveGreen,
		opacity: isActive ? 1 : 0,
		transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
		transformOrigin: 'left center',
		transition: slowTransitionAnimation,
	};
});
