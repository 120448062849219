import React from 'react';
import * as S from './style';
import UploadedFile from './components/UploadedFile';
import { ACCEPTED_UPLOAD_FILE_TYPES, Texts } from './constants';
import ErrorIcon from '../../../../../../assets/img/smallErrorIcon.svg';

const UploadFiles = ({
	name,
	error,
	register,
	setValue,
	watch,
	errorTextWidth,
	onRemove = () => null,
	...rest
}) => {
	const fileList = watch(name);
	const isFileExist = fileList?.length > 0;
	const isErrorState = !!error;

	const handleRemoveFile = () => {
		setValue(name, []);
		onRemove();
	};

	return (
		<div>
			<S.UploadFileRoot
				htmlFor='uploadImage'
				onClick={() => {
					onRemove();
				}}
				{...rest}
			>
				<S.SelectImageContainer>
					<S.UploadImagePrimaryText>{Texts.SELECT_IMAGE}</S.UploadImagePrimaryText>
					<S.UploadImageDisplayNone
						id='uploadImage'
						type='file'
						accept={ACCEPTED_UPLOAD_FILE_TYPES}
						name={name}
						{...register(name)}
					/>
					<S.UploadImageBlueText>{Texts.BROWSE}</S.UploadImageBlueText>
				</S.SelectImageContainer>
			</S.UploadFileRoot>

			{isFileExist ? (
				<UploadedFile label={fileList?.[0].name} onClick={handleRemoveFile} />
			) : (
				<S.UploadImageGreyText>
					{Texts.RECOMMENDED_UPLOAD_RESOLUTION}
				</S.UploadImageGreyText>
			)}

			{isErrorState && isFileExist && (
				<S.ErrorTextContainer aria-label='error-message' {...{ errorTextWidth }}>
					<S.UploadImageErrorIcon src={ErrorIcon} />
					<span>{error}</span>
				</S.ErrorTextContainer>
			)}
		</div>
	);
};

export default UploadFiles;
