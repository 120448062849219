import { styled } from '@mui/material';

export const FlexContainerPlantName = styled('div')(({ theme }) => ({
	display: 'flex',
	paddingBottom: '2rem',
}));

export const InputContainerPlantName = styled('span')(({ theme }) => ({
	marginRight: '6.875rem',
}));

export const FlexContainerStreet = styled('div')(({ theme }) => ({
	display: 'flex',
	paddingBottom: '1.3125rem',
}));

export const FlexContainerCity = styled('div')(({ theme }) => ({
	display: 'flex',
	paddingBottom: '3.25rem',
}));
