import { alpha, Grid, Modal, styled } from '@mui/material';

export const ViewModal = styled(Modal)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 4,
	borderColor: theme.palette.background.blackGrey,
	background: alpha(theme.palette.background.paper, 0.8),
}));

export const GridModal = styled(Grid,  {
	shouldForwardProp: (prop) => prop !== 'paperWidth' && prop !== 'paperBorderRadius',
})(({ theme, paperWidth, paperBorderRadius }) => ({
	width: paperWidth ? paperWidth : '36rem',
	minHeight: '12.5rem',
	backgroundColor: theme.palette.background.blackGrey,

	boxShadow: theme.shadows[5],
	padding: '1.75rem 1rem 1rem 1.5rem',
	color: theme.customColors.white,
	outline: 'none',
	borderRadius: paperBorderRadius || '0.25rem',
}));
