import React from 'react';
import Dialog from '../../../../components/Dialog';
import SuccessIcon from '../../../../assets/img/successGreen.svg';
import * as S from './style';

const SuccessDialog = ({
	isOpen,
	plantName,
	primaryButtonLabel,
	primaryButtonOnClick,
	secondaryButtonLabel,
	secondaryButtonOnClick,
}) => {
	const title = (
		<S.SuccessDialogContainer>
			<S.SuccessDialogIcon src={SuccessIcon} alt='success-icon' />

			<S.SuccessDialogTitle>
				<span>Success!</span>
				<S.SuccessDialogDescription>
					You have successfully created the plant `{plantName}`
				</S.SuccessDialogDescription>
			</S.SuccessDialogTitle>
		</S.SuccessDialogContainer>
	);

	return (
		<Dialog
			{...{
				isOpen,
				title,
				primaryButtonLabel,
				primaryButtonOnClick,
				secondaryButtonLabel,
				secondaryButtonOnClick,
			}}
			height='12rem'
			width='36rem'
		/>
	);
};

export default SuccessDialog;
