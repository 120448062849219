import { alpha, Snackbar, styled } from '@mui/material';
import { TOAST_TYPE } from '../../constants';

const colorType = {
	[TOAST_TYPE.ERROR]: 'cocoaToneRed',
	[TOAST_TYPE.WARNING]: 'bronzeTone',
	[TOAST_TYPE.SUCCESS]: 'blackOliveGreen',
	[TOAST_TYPE.INFO]: 'nileBlue',
};

const borderColors = {
	[TOAST_TYPE.ERROR]: 'lavaRed',
	[TOAST_TYPE.WARNING]: 'tangerineColor',
	[TOAST_TYPE.SUCCESS]: 'saveGreen',
	[TOAST_TYPE.INFO]: 'highlightBlue',
};

export const CustomSnackbar = styled(Snackbar, {
	shouldForwardProp: (prop) => prop !== 'key' && prop !== 'resultColor',
  })(({ resultColor, theme }) => {
	
	const ToastColor = colorType[resultColor] || colorType[TOAST_TYPE.ERROR];
	const ToastBorderColor = borderColors[resultColor] || borderColors[TOAST_TYPE.ERROR];

	return {
	  	zIndex: theme.zIndex.modal + 1,
	  	'.MuiPaper-root': {
			minWidth: '28.5rem',
			background: alpha(theme.palette.background[ToastColor], 0.95),
			border: `1px solid ${ theme.palette.border[ToastBorderColor]}`,
			borderRadius: '0.35rem',
			padding: 0
		},
	  	'.MuiSnackbarContent-message': {
			width: '100%',
			padding: 0
		},
	};
  });
