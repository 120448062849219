import React, { useState, useEffect, useRef, useMemo } from 'react';
import { styled, alpha, Box, TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';

import rightArrow from '../../../assets/img/rightArrow.svg';
import globeDownArrow from '../../../assets/img/globeDownArrow.svg';
import { addEmbellishments, getTableCellData, handleSort } from '../utils';
import { WrappedFont } from '..';
import theme, { globeDrawerToolTipColors } from '../../../theme/index';

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
	boxSizing: 'border-box',
}));

const StyledRowHeader = styled(TableRow)(({ theme }) => ({
	backgroundColor: `${theme.palette.background.darkishBlackBlue} !important`,
	'&:hover': {
		backgroundColor: `${globeDrawerToolTipColors.codGrey} !important`,
	},
	'& > .MuiTableCell-head': {
		backgroundColor: 'inherit',
	},
}));

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
	background: theme.palette.background.blackGrey,
	minHeight: '3.5em',
	border: `.1em solid ${alpha(theme.palette.border.darkGrey, 0.3)} 0`,
}));

function addOEEColor(row, column) {
	if (row[column.id.key] < 75) row.colourCodeOEE = theme.palette.background.errorColor;
	else if (row[column.id.key] >= 75 && row[column.id.key] < 90)
		row.colourCodeOEE = theme.palette.background.tangerineColor;
	else row.colourCodeOEE = '#43B02A';
}

function CollapsibleRow({
	row,
	column,
	childEntities,
	expand = { expand: [], setExpand: () => {} },
	childrenCols,
	onPlantClick,
	factoryConfigPermission,
	bodyHeight,
	sortOrder,
}) {
	const [open, setOpen] = useState(false);
	const { expand: rowExpand, setExpand } = expand;
	const ref = useRef('');

	useEffect(() => {
		setOpen(rowExpand[row?.businessUnitId]);
	}, [expand]);

	const sortedArray = useMemo(() => {
		return handleSort(sortOrder?.correspondingChild, sortOrder?.order, childEntities);
	}, [sortOrder.order, sortOrder.by, sortOrder.correspondingChild, childEntities]);

	function BUHeaderCells(row, col, index) {
		if (index === 0) {
			return (
				<Box sx={{ display: 'flex', alignItems: 'start' }}>
					<Box
						sx={{
							height: '1.75rem',
							width: '1.75rem',
						}}
					>
						<IconButton
							aria-label='expand row'
							size='small'
							data-testid='expand-collapse-button'
							onClick={() => {
								const newState = { ...rowExpand };
								newState[row?.businessUnitId] = !open;
								setExpand(newState);
							}}
						>
							{open ? <img src={globeDownArrow} /> : <img src={rightArrow} />}
						</IconButton>
					</Box>
					<WrappedFont
						data-testid={`globe-BusinessUnit-${col.id.key}`}
						sx={{ fontWeight: 700, fontSize: '1rem' }}
					>
						{row[col.id.key]}
					</WrappedFont>
				</Box>
			);
		} else if (col.id.key === 'plannedOEE') {
			addOEEColor(row, col);
		}
		return addEmbellishments(col?.id?.embellishment, row, col, false, () => {}, {
			fontWeight: 'bold',
		});
	}

	return (
		<Table
			stickyHeader={(childEntities.length + 1) * ref?.current?.clientHeight > bodyHeight}
		>
			<TableHead sx={{ background: 'unset' }}>
				<StyledRowHeader
					className='collapsible-row'
					hover
					data-testid={`globe-BusinessUnit-Row`}
				>
					{column.map((col, index) => (
						<StyledTableHeadCell
							key={col.id.key}
							data-testid={`globe-BusinessUnit-Cell`}
							sx={{
								minWidth: index != childrenCols.length - 1 ? col.id?.width : '8.25rem',
								maxWidth:
									(index != childrenCols.length - 1 ? col.id?.width : '8.25rem') ||
									'auto',
							}}
							ref={ref}
						>
							{BUHeaderCells(row, col, index)}
						</StyledTableHeadCell>
					))}
				</StyledRowHeader>
			</TableHead>
			<TableBody>
				<StyledTableBodyRow>
					<TableCell
						style={{ padding: 0 }}
						colSpan={column.length}
						data-testid={`globe-Collapsible-Row`}
					>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Table>
								<TableBody>
									{sortedArray.map((child, index) => (
										<StyledTableBodyRow
											data-testid={`globe-Collapsed-Row`}
											key={`child${index}`}
										>
											{childrenCols.length > 0
												? childrenCols.map((col, index) => (
														<TableCell
															key={col.id.key}
															data-testid={`globe-Collapsed-Row-cell`}
															sx={{
																width:
																	(index != childrenCols.length - 1
																		? col.id?.width
																		: '8.25rem') || 'auto',
																padding:
																	index === 0
																		? '.75rem 0 .75rem 2.75rem'
																		: '.75rem .875rem .75rem 1rem',
															}}
														>
															{getTableCellData(
																col.id,
																child[col.id.key],
																child,
																col,
																factoryConfigPermission,
																onPlantClick
															)}
														</TableCell>
												  ))
												: column.map((col) => (
														<TableCell
															key={col.id.key}
															data-testid={`globe-Collapsed-Row-cell`}
														>
															{child[col.id.key]}
														</TableCell>
												  ))}
										</StyledTableBodyRow>
									))}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</StyledTableBodyRow>
			</TableBody>
		</Table>
	);
}

CollapsibleRow.propTypes = {
	row: PropTypes.object.isRequired,
	column: PropTypes.array.isRequired,
	childEntities: PropTypes.array,
	expand: PropTypes.object,
	childrenCols: PropTypes.arrayOf(PropTypes.object),
	onPlantClick: PropTypes.func,
	factoryConfigPermission: PropTypes.bool,
	bodyHeight: PropTypes.number,
	sortOrder: PropTypes.object,
};

export default CollapsibleRow;
