import { kilobytesToBytes } from '../../../../../utils';

export const populateForm = ({
	fetchedPlantData,
	setValue,
	statesList,
	allBusinessUnits,
}) => {
	const {
		plantName,
		factoryId,
		entityType,
		street,
		city,
		zipCode,
		stateCode,
		fileName,
		imgSrcPath,
		fileSize,
		businessUnit,
		hasAssetHierarchyChanged,
		entityNumber,
		hierarchyRowCount,
		createdAt,
		updatedAt,
	} = fetchedPlantData;

	const fileType = fileName?.split?.('.')[1];
	const isFileExist = !!(fileName && fileSize && fileType);
	const plant = {
		plantName,
		plantId: factoryId,
		entityType,
		street,
		city,
		zipCode,
		stateCode,
		businessUnitId: businessUnit,
		hasAssetHierarchyChanged,
		entityNumber,
		hierarchyRowCount,
		createdAt,
		updatedAt,
		imgSrcPath,
		file: isFileExist
			? [
					{
						name: fileName,
						// fileSize is received as '12.3 KB' from the BE instead of actual bytes
						size: kilobytesToBytes(+fileSize.split(' ')[0]),
						type: `.${fileType}`,
					},
			  ]
			: [],
	};

	const fetchedPlantBusinessUnitName = allBusinessUnits.find(
		({ businessUnitId }) => businessUnitId === fetchedPlantData?.businessUnit
	)?.businessUnitName;
	plant.businessUnitName = fetchedPlantBusinessUnitName || '';

	const fetchedPlantStateName = statesList?.find(
		({ stateCode }) => stateCode === fetchedPlantData?.stateCode
	)?.stateName;
	
	setValue('plant', plant);
	setValue('plantSnapshot', plant);
	setValue('metadata.plantImage.initialFileName', fileName);
	setValue('metadata.plantImage.isFileExistsOnFetch', isFileExist);
	setValue('metadata.selectedBusinessUnitName', fetchedPlantBusinessUnitName || '');
	setValue('metadata.selectedStateName', fetchedPlantStateName || '');
};
