import React, { useEffect, useState } from 'react';
import { setDefaultOptions } from 'esri-loader';
import { useMutation, useQuery } from 'react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { globalstate$, RBAC_BUTTONS, RBACValidation } from '@smf/ui-util-app';
import HeaderSteps from './components/HeaderSteps';
import SuccessDialog from './components/SuccessDialog';
import EditPlantsDialog from './components/EditPlantsDialog';
import { APQWebSocket } from '../../utils/websocket';
import { merge, keyBy, isEmpty } from 'lodash';

import { useAddCompanyData } from './hooks/useAddCompanyData';
import { useRxjsState } from '../../utils/hooks/useRxjsState';

import { addCompanyValidationSchema } from './helpers/buildAddCompanyValidationSchema';
import { addCompanyDefaultFormValues } from './helpers/addCompanyDefaultFormValues';
import { parseAddCompanyFormToBackendFormat } from './helpers/parseAddCompanyFormToBackendFormat';
import { isNextStepButtonDisabled } from './hooks/utils';
import LoadingIndicator from '../../components/LoadingIndicator';
import {
	createCompany,
	getAllBusinessUnitList,
	getGlobeDrawerData,
	queryConstants,
} from '../../utils/apiHelper';
import {
	GLOBE_PLANTS_REFRESH_INTERVAL,
	RBAC_CONSTANTS,
	TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { FormSteps, Texts } from './constants';
import { useToastContext } from '../../context/toastContext';
import { toBase64 } from './utils';
import * as S from './style';
import InitialModalSetup from '../InitialModalSetup';

const GlobeContainer = () => {
	setDefaultOptions({ css: true });

	const [APQData, setAPQData] = useState(null);
	const [globeData, setGlobeData] = useState(null);
	const [tableData, setTableData] = useState({ data: {}, refreshRate: null, init: true });
	const [isFetchingCompany, setIsFetchingCompany] = useState(true);
	const [hasInitialSetupAccess, setHasInitialSetupAccess] = useState(false);
	const [isCompanyIdExist, setIsCompanyIdExist] = useState(true);
	const [isDisplayAddCompanySuccessDialog, setIsDisplayAddCompanySuccessDialog] =
		useState(false);
	const [showAddEditPlants, setShowAddEditPlants] = useState(false);
	const [companyId, setCompanyId] = useState(null);
	const [companyName, setCompanyName] = useState(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const { toastDispatch } = useToastContext();
	const { rxjsState } = useRxjsState();
	const methods = useForm({
		defaultValues: addCompanyDefaultFormValues,
		resolver: yupResolver(addCompanyValidationSchema),
		mode: 'all',
	});
	let sessionToken = rxjsState.sessionToken;

	const { userStatus } = rxjsState;
	const isBusinessUnitParam = false;

	const {
		handleSubmit,
		getValues,
		setValue,
		watch,
		formState: { errors, dirtyFields },
		trigger,
	} = methods;

	const {
		headerStepsData,
		dialogProps: {
			title,
			content,
			primaryButtonLabel,
			primaryButtonOnClick,
			secondaryButtonLabel,
			secondaryButtonOnClick,
			primaryButtonProps,
			currentStep,
		},
		isCreateStepNextButtonAvailable,
		isBusinessUnitStepNextButtonAvailable,
	} = useAddCompanyData({ trigger, errors, dirtyFields, formValues: watch(), setValue });

	const {
		isFetching,
		refetch: refetchEntitiesData,
		isRefetching,
	} = useQuery(
		[queryConstants.GET_GLOBE_DATA],
		async () => {
			const globeDataResponse = await getGlobeDrawerData(
				null,
				{
					entityType: RBAC_CONSTANTS.LEVELS.PLANT,
					globeCheck: true,
				},
				isBusinessUnitParam
			);
			setGlobeData(globeDataResponse);
			if (tableData.init)
				setTableData({ ...tableData, data: globeDataResponse, refreshRate: null });
			else {
				setTableData({ ...tableData, data: globeDataResponse });
				APQRefetch();
			}
		},
		{
			retry: false,
			refetchOnWindowFocus: false,
		}
	);

	useEffect(() => {
		let interval;
		if (!tableData.init) {
			interval = setInterval(() => {
				APQRefetch();
			}, GLOBE_PLANTS_REFRESH_INTERVAL);
		}
		return () => {
			clearInterval(interval);
		};
	}, [tableData.init, tableData.refreshRate]);

	const { refetch: APQRefetch } = useQuery(
		['APQDATA_WebSocket'],
		async () => {
			const data = await APQWebSocket(sessionToken);
			return data;
		},
		{
			onSuccess: (response) => {
				const { plantsAPQandOEEData: data, refreshRate, BUAPQandOEEData } = response;
				setAPQData(data);
				const spreadData = data.map((element) => {
					if (typeof element.OEEandAPQwithColor === 'string')
						return { ...element, ...element.lossEarnings };
					else if (typeof element.lossEarnings === 'string')
						return { ...element, ...element.OEEandAPQwithColor };
					else
						return { ...element, ...element.OEEandAPQwithColor, ...element.lossEarnings };
				});
				const keyedResponseData = keyBy(spreadData, 'factoryId');
				const formattedPlantData = Object.values(
					merge(keyBy(globeData?.data, 'factoryId'), keyedResponseData)
				);
				if (typeof BUAPQandOEEData === 'string') {
					setTableData((prev) => {
						return {
							...tableData,

							data: {
								...prev.data,
								data: formattedPlantData,
							},
							refreshRate: refreshRate,
							init: false,
						};
					});
				} else {
					const formattedBUData = Object.values(
						merge(
							keyBy(globeData?.businessData, 'businessUnit'),
							keyBy(BUAPQandOEEData, 'businessUnit')
						)
					);
					const FilteredBUData = formattedBUData.filter((BU) => {
						if (BU.hasOwnProperty('childEntities') && BU?.childEntities.length > 0)
							return BU;
					});
					setTableData((prev) => {
						return {
							...tableData,

							data: {
								...prev.data,
								data: formattedPlantData,
								businessData: FilteredBUData,
							},
							refreshRate: refreshRate,
							init: false,
						};
					});
				}
				console.log('Received WebSocket Data');
			},
			onError: (data) => {
				throw new Error(data);
			},
			retry: false,
			refetchOnWindowFocus: false,
			enabled: Boolean(sessionToken),
		}
	);

	const {
		isFetching: isGetAllBusinessUnitListFetching,
		data: allBusinessUnits,
		refetch: refetchAllBusinessUnits,
	} = useQuery(
		[queryConstants.GET_ALL_BUSINESS_UNIT_LIST],
		async () => {
			const result = await getAllBusinessUnitList();
			return result;
		},
		{
			refetchOnWindowFocus: false,
		}
	);

	const { isLoading: isAddCompanyMutationLoading, mutate: addCompanyMutate } =
		useMutation(
			async (requestDataInMutation) => {
				const data = await createCompany(requestDataInMutation, null);
				setCompanyId(data.companyId);
				return data;
			},
			{
				onSuccess: () => {
					setIsDisplayAddCompanySuccessDialog(true);
				},
				onError: (error) => {
					toastDispatch({
						type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
						payload: {
							message: error?.response?.data?.message,
						},
					});
				},
				refetchOnWindowFocus: false,
				retry: false,
			}
		);

	useEffect(() => {
		const companyId = rxjsState?.companyData?.companyId;

		// useRxjsState hook is async, an empty object on mount, the field will be undefined initially whether it exists or not
		if (typeof companyId !== 'undefined') {
			setIsCompanyIdExist(!!companyId);

			if (companyId !== null && companyId !== '') {
				setCompanyId(companyId);
			}
			setIsFetchingCompany(false);
		}
		setHasInitialSetupAccess(
			RBACValidation('Button', RBAC_BUTTONS['ADD_EDIT_COMPANY'], rxjsState.userStatus)
		);
	}, [rxjsState?.companyData?.companyId, rxjsState.userStatus]);

	const onSubmit = (submitValues) => {
		const file = submitValues.createPlantStep.file[0];

		if (file) {
			toBase64(file).then((res) => {
				const [, byteStream] = res.split(',');
				submitValues.createPlantStep.bytesStream = byteStream;
				addCompanyMutate(parseAddCompanyFormToBackendFormat(submitValues));
			});
		} else {
			addCompanyMutate(parseAddCompanyFormToBackendFormat(submitValues));
		}
		setCompanyName(submitValues.createCompanyStep.companyName);
	};

	const handleSuccessDialogOk = () => {
		window.location.reload();
	};
	const handleSuccessDialogAddAnotherPlant = () => {
		setIsDisplayAddCompanySuccessDialog(false);
		globalstate$.next({
			...globalstate$.value,
			companyData: {
				companyName: companyName,
				companyId: companyId,
			},
		});
		setShowAddEditPlants(true);
	};

	const fetching = isFetching || isRefetching || isFetchingCompany;

	return (
		<>
			{fetching && <LoadingIndicator />}
			{!!isCompanyIdExist && !fetching && (
				<InitialModalSetup
					globeData={tableData?.data}
					userStatus={userStatus}
					resetData={refetchEntitiesData}
					refetchAllBusinessUnits={refetchAllBusinessUnits}
					{...{
						setShowAddEditPlants,
						showAddEditPlants,
						setIsDrawerOpen,
						isDrawerOpen,
					}}
				/>
			)}

			{!isFetchingCompany && (
				<S.DialogContent
					isOpen={
						!isCompanyIdExist &&
						hasInitialSetupAccess &&
						!isDisplayAddCompanySuccessDialog &&
						!showAddEditPlants
					}
					title={title}
					headerContent={<HeaderSteps {...{ headerStepsData }} />}
					content={
						<FormProvider {...methods}>
							{isAddCompanyMutationLoading ? <LoadingIndicator /> : content}
						</FormProvider>
					}
					primaryButtonLabel={primaryButtonLabel}
					// Implements  the behavior of next never disabled, but enforcing validation and navigation block according
					// to updated requirements
					primaryButtonOnClick={
						!isCreateStepNextButtonAvailable && currentStep === FormSteps.CREATE_COMPANY
							? () => trigger('createCompanyStep.companyName')
							: !isBusinessUnitStepNextButtonAvailable &&
							  currentStep === FormSteps.CREATE_BUSINESS_UNIT
							? () => trigger('createBusinessUnitStep')
							: primaryButtonOnClick
					}
					primaryButtonProps={{
						...(primaryButtonProps || {}),
						disabled: isNextStepButtonDisabled({
							dirtyFields,
							errors,
							currentStep,
							formValues: getValues(),
						}),
					}}
					secondaryButtonLabel={secondaryButtonLabel}
					secondaryButtonOnClick={secondaryButtonOnClick}
					formConfiguration={{ onSubmit: handleSubmit(onSubmit) }}
				/>
			)}

			<SuccessDialog
				isOpen={isDisplayAddCompanySuccessDialog}
				plantName={getValues('createPlantStep.plantName')}
				primaryButtonLabel={Texts.OK}
				primaryButtonOnClick={handleSuccessDialogOk}
				secondaryButtonLabel={Texts.ADD_ANOTHER_PLANT}
				secondaryButtonOnClick={handleSuccessDialogAddAnotherPlant}
			/>

			{!!isCompanyIdExist && (
				<EditPlantsDialog
					onClose={() => {
						setShowAddEditPlants(false);
						refetchEntitiesData();
						setIsDrawerOpen(false);
					}}
					companyId={companyId}
					isOpen={showAddEditPlants}
					isGetAllBusinessUnitListFetching={isGetAllBusinessUnitListFetching}
					allBusinessUnits={allBusinessUnits}
				/>
			)}
		</>
	);
};

export default GlobeContainer;
