const URL = {
	NOT_FOUND: '/404',
	OTHER: '*',
	INDEX: '/',
	CONFIG: '/config',
	DAY_TO_DAY: '/d2d',
	DASHBOARD: '/dashboard',
	FACTORY_HEALTH: '/factory_health',
	OEE_CONFIG: '/oee_configuration',
	CONSTRAINT_MANAGEMENT: '/constraint_management',
	GENERAL_CONFIG: '/general_config',
	ACCESS_CONFIG: '/access_configuration',
	ACCESS: '/access',
	SCHEDULE_PLANNING: '/schedule_planning',
	CONDITION_MONITORING: '/condition_monitoring',
	MANUFACTURING_EQUIPMENT: '/manufacturing_equipment',
	PROFILE: '/profile',
	HELP: '/help',
	NOTIFICATION_CONFIGURATION: '/notifications',
	MENU: '/menu',
	PERFORMANCE_MANAGEMENT: '/performance_management',
	CBM: '/cbm',
	FACTORY_SELECTION: '/factory_selection',
	ORDER_MANAGEMENT: '/order_management',
	CONNECTIVITY_MDU: '/mdu_connectivity',
};

export default URL;
