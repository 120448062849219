/* eslint-disable no-case-declarations */
import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { APP_CONTEXT, CONFIG_CONTEXT, D2D_CONTEXT } from '../constants';
import appContextTypes from './appContext.types';

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

const initialState = {
    plantId: '',
    plantName: '',
    stepperCurrentState: null,
    stepperIsEditing: false,
    generalConfigTotalSteps: 6,
    constantsAPI: {},
    context: null,
    menuDisabledState: true,
    userInformation: null,
    companyData: {},
    businessUnit: '',
    generalConfigSentinel: 0,
    isLoggedOut: false,
    socketPlantId: '',
    configData: [],
};

export const configReducer = (state, action) => {
    switch (action.type) {
        case CONFIG_CONTEXT.SELECT_PLANT: {
            return {
                ...state,
                plantId: action.payload.plantId,
                plantName: action.payload.plantName,
            };
        }
        case CONFIG_CONTEXT.STEPPER_CURRENT_STATE: {
            return {
                ...state,
                stepperCurrentState: action.payload.stepperCurrentState,
                stepperIsEditing: action.payload.stepperIsEditing,
                generalConfigTotalSteps: action.payload.generalConfigTotalSteps,
                // generalConfigSentinel: state.generalConfigSentinel + 1,
            };
        }
        case CONFIG_CONTEXT.STORE_CONSTANTS_API: {
            return {
                ...state,
                constantsAPI: action.payload.response,
            };
        }
        case D2D_CONTEXT.MENU_STATE: {
            return {
                ...state,
                plantId: action.payload.plantId,
                plantName: action.payload.plantName,
                menuDisabledState: false,
                businessUnit: action.payload.businessUnit,
            };
        }
        case D2D_CONTEXT.HOME_STATE: {
            return {
                ...state,
                plantId: action.payload.plantId,
                plantName: action.payload.plantName,
                menuDisabledState: action.payload.menuDisabledState,
                businessUnit: action.payload.businessUnit,
            };
        }
        case appContextTypes.SET_USER_INFORMATION:
            return {
                ...state,
                userInformation: action.payload,
            };
        case APP_CONTEXT.COMPANY_DATA: {
            const { companyData } = action.payload;
            return {
                ...state,
                companyData: { ...state.companyData, ...companyData },
            };
        }
        case APP_CONTEXT.UPDATE_COMPANY_NAME: {
            const { updatedCompanyName } = action.payload;
            return {
                ...state,
                companyData: { ...state.companyData, companyName: updatedCompanyName },
            };
        }
        case appContextTypes.SET_LOGGED_OUT:
            return { ...state, isLoggedOut: action.payload };

        case appContextTypes.SET_SOCKET_PLANT_ID:
            return { ...state, socketPlantId: action.payload };

        case appContextTypes.CHANGE_MAIN_SIDE_MENU_STATE: {
            const { disableSideMenu } = action.payload;
            return {
                ...state,
                menuDisabledState: disableSideMenu,
            };
        }

        case appContextTypes.SET_CONFIG_STATUS_DATA:
            return { ...state, configData: action.payload };

        case appContextTypes.UPDATE_CONFIG_STATUS_DATA:
            let modifiedConfigData = state.configData;
            const itemsToReplace = action.payload;
            modifiedConfigData = modifiedConfigData.map((item) => {
                const itemFound = itemsToReplace.find(
                    (itemToReplace) => itemToReplace.factoryId === item.factoryId
                );
                if (itemFound) {
                    return itemFound;
                }
                return item;
            });

            return { ...state, configData: modifiedConfigData };

        default:
            return state;
    }
};

export function enableMainSidebar(dispatch) {
    dispatch({
        type: appContextTypes.CHANGE_MAIN_SIDE_MENU_STATE,
        payload: {
            disableSideMenu: false,
        },
    });
}

export function disableMainSidebar(dispatch) {
    dispatch({
        type: appContextTypes.CHANGE_MAIN_SIDE_MENU_STATE,
        payload: {
            disableSideMenu: true,
        },
    });
}

export function AppProvider({ children }) {
    const [appState, appDispatch] = useReducer(configReducer, initialState);
    const appData = { appState, appDispatch };
    return <AppContext.Provider value={appData}>{children}</AppContext.Provider>;
}

AppProvider.propTypes = {
    children: PropTypes.node,
};
