import { Box } from '@mui/material';
import ErrorIcon from '../../assets/img/smallErrorIcon.svg';
import PropTypes from 'prop-types';
import * as S from './style';

const InlineErrorMessage = ({
	message = '',
	width = '20em',
	fontSize = '1rem',
	alignRight = false,
}) => {
	return (
		<Box
			width={width}
			display='flex'
			paddingTop={1}
			component='span'
			alignItems='flex-start'
		>
			<S.IconView component='span'>
				<img src={ErrorIcon} alt='' />
			</S.IconView>
			<S.MessageView
				fontSize={fontSize}
				variant='subtitle1'
				component='span'
				align={alignRight ? 'right' : 'left'}
				{...{ fontSize }}
			>
				{message}
			</S.MessageView>
		</Box>
	);
};

InlineErrorMessage.prototype = {
	width: PropTypes.string,
	message: PropTypes.string,
	fontSize: PropTypes.string,
	alignRight: PropTypes.bool,
};

export default InlineErrorMessage;
