import SelectComponent from '@mui/material/Select';
import { MenuItem, styled } from '@mui/material';

export const SelectView = styled(SelectComponent, {
	shouldForwardProp: (prop) =>
		prop !== 'MenuProps' &&
		prop !== 'error',
})(({ theme, MenuProps, error }) => {
	const transitionAnimation = 'all 0.225s ease-out';
	const defaultHeight = '2rem';
	const defaultWidth = '11.43rem';
	const isErrorState = !!error;
	return {
		'& .MuiSelect-select': {
			width: defaultWidth,
			'&:hover, &:active, &:focus': {
				background: 'transparent'
			}
		},
		'& .MuiSelect-icon': {
			color: theme.palette.text.primary,
			cursor: 'pointer',
			marginRight: '0.79rem',
			position: 'absolute',
			pointerEvents: 'none',
			top: '0.75em',
			right: '0',
		},
		'& .MuiPaper-root': {
			...(MenuProps && {
				marginTop: '0.5%',
				border: 'none',
				width: defaultWidth,
				borderRadius: '2px',

				'&>$ul': {
					padding: 0,
				},
			})},
		border: `1px solid ${
			isErrorState
				? theme.palette.background.errorColor
				: theme.customColors.targetLightGrey
		}`,
		fontSize: '0.875rem',
		padding: '0.37rem 0 0.43rem 1.14rem',
		color: theme.palette.text.primary,
		width: defaultWidth,
		height: defaultHeight,
		borderRadius: '20px',
		transition: transitionAnimation,

		'&::before, &::after': {
			content: 'unset',
		},

		'&:hover, &:active, &:focus': {
			border: `1px solid ${
				isErrorState
					? theme.palette.background.errorColor
					: theme.palette.background.saveGreen
			}`,
			transition: transitionAnimation,
		},
	};
});

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
	height: '2rem',
	'&:hover, &.Mui-selected': {
		backgroundColor: theme.palette.background.gunmetalGrey,
	}
}));

export const ContainerView = styled('div')(({}) => ({
	height: 'fit-content',
	display: 'flex',
	flexDirection: 'column',
}));

export const LabelView = styled('label')(({}) => ({
	marginBottom: '0.5rem',
	fontSize: '0.87rem',
	textTransform: 'capitalize',
}));

export const RequiredStar = styled('span')(({ theme }) => ({
	color: theme.palette.text.errorText,
	marginLeft: '0.25rem',
}));

export const ErrorTextContainer = styled('div')(({ errorTextWidth }) => ({
	fontSize: '1rem',
	marginTop: '0.4375rem',
	display: 'flex',
	alignItems: 'center',
	maxWidth: errorTextWidth || 'unset',
}));

export const InputErrorIcon = styled('img')(({ theme }) => ({
	marginRight: '0.5625rem',
}));
