import { styled } from '@mui/material';

export const UploadFileRoot = styled('label')(({ theme }) => ({
	fontSize: '0.875rem',
	cursor: 'pointer',
	color: theme.palette.text.primary,
}));

export const SelectImageContainer = styled('span')(({ theme }) => ({
	marginBottom: '0.5rem',
}));

export const UploadImagePrimaryText = styled('span')(({ theme }) => ({
	paddingRight: '0.75rem',
}));

export const UploadImageDisplayNone = styled('input')(({ theme }) => ({
	display: 'none',
}));

export const UploadImageBlueText = styled('span')(({ theme }) => ({
	color: theme.palette.background.infoColor,
}));

export const UploadImageGreyText = styled('div')(({ theme }) => ({
	color: theme.customColors.nobelGrey,
	marginTop: '0.5rem',
	fontSize: '0.875rem',
}));

export const ErrorTextContainer = styled('div')(({ errorTextWidth }) => ({
	fontSize: '1rem',
	marginTop: '0.4375rem',
	display: 'flex',
	alignItems: 'center',
	maxWidth: errorTextWidth || 'unset',
}));

export const UploadImageErrorIcon = styled('img')(({ theme }) => ({
	marginRight: '0.5625rem',
}));
