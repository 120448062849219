export const parseAddCompanyFormToBackendFormat = (formValues) => {
	const { createCompanyStep, createBusinessUnitStep, createPlantStep } = formValues;
	const file = createPlantStep.file[0];
	const plantDetails = file
		? {
				plantImage: {
					bytesStream: createPlantStep.bytesStream,
					fileName: file.name,
					fileType: file.type.split('/')[1],
					fileSize: file.size,
				},
		  }
		: {};

	const businessUnits = Object.values(createBusinessUnitStep).map((value) => ({
		businessUnitName: value,
	}));

	const payload = {
		companyDetails: { companyName: createCompanyStep.companyName },
		buDetails: businessUnits,
		plantDetails: [
			{
				plantName: createPlantStep.plantName,
				businessUnitName: createPlantStep.businessUnit,
				plantAddress: {
					street: createPlantStep.street,
					city: createPlantStep.city,
					stateCode: createPlantStep.stateCode,
					zipCode: createPlantStep.zipCode,
					imgSrcPath: file?.name || '',
				},
				...plantDetails,
			},
		],
	};

	return payload;
};
