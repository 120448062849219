import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

/**
 * @author Dima Borodin
 *
 * @param name Required - string: a value from the IconNames enum located in this folder
 * @param className string: a value from the IconNames enum located in this folder
 * @param onClick () => void
 *
 * @param width
 * @param height
 * @param rest
 * @returns An IconButton (aka ghost button) component
 */

const IconButton = ({ name, onClick, className, width, height, ...rest }) => {
	return (
		<S.IconButtonRoot onClick={onClick} {...rest}>
			<S.IconButtonView src={name} width={width} height={height} alt={`alt-${name}`} />
		</S.IconButtonRoot>
	);
};

IconButton.propTypes = {
	name: PropTypes.string.isRequired,
	onClick: PropTypes.func,
};

export default IconButton;
