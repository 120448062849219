import React from 'react';

import IconButton from '../../../../../../../../components/IconButton';

import { IconNames } from '../../../../../../../../components/IconButton/IconNames';
import ImageIcon from '../../../../../../../../assets/img/ImageIcon.svg';

import * as S from './style';

const UploadedFile = ({ label, onClick }) => {
	return (
		<S.UploadedFileRoot>
			<S.UploadedFileContainer>
				<S.UploadedImage src={ImageIcon} alt='image-icon' />
				<span>{label}</span>
			</S.UploadedFileContainer>

			<IconButton
				name={IconNames.CLOSE}
				onClick={onClick}
				height='1.37rem'
				width='1.37rem'
				size='large'
			/>
		</S.UploadedFileRoot>
	);
};

export default UploadedFile;
