import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// eslint-disable-next-line no-unused-vars
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

// eslint-disable-next-line react/prop-types
function ReactQueryProvider({ children }) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
    );
}

export default ReactQueryProvider;
